import { Component, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';
import { TableSettings } from 'sc-common/shared/table/models/table-settings';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Component({
    templateUrl: 'radio-button-renderer.component.html'
})
export class RadioButtonRendererComponent extends RendererBaseComponent implements OnDestroy {

    private readonly _destroy$ = new Subject<void>();

    private readonly _rowSelectionChange$ = new Subject<{ rowData: any; cellData: any; keyValue: number; }>();

    private _rows: any[];

    private _rowSelection = 0;

    public readonly keyFieldName: string;

    public get rowSelection(): number {
        return this._rowSelection;
    }

    public readonly isCustomEditor: boolean = true;

    constructor(
        private readonly _tableStateService: TableStateService,
        private readonly _tableSettings: TableSettings<any>) {

        super();

        this.keyFieldName = this._tableSettings.keyFieldName;

        this._tableStateService.pageUpdate$
            .pipe(
                filter(p => !!p),
                map(p => p.records),
                takeUntil(this._destroy$))
            .subscribe(rows => {

                this._rows = rows;

                if (rows.length) {

                    const selectedRow = rows.find(x => x[this.tableColumn.field]);

                    if (selectedRow) {

                        this._rowSelection = selectedRow[this._tableSettings.keyFieldName];
                    }
                }
            });

        this._rowSelectionChange$
            .pipe(
                switchMap(selection => this.tableColumn.settings.editable.callback(selection.cellData, selection.rowData)
                    .pipe(map(_ => selection.keyValue))),
                takeUntil(this._destroy$))
            .subscribe(selectedKeyValue => {

                const prevSelectionKey = this._rowSelection;

                if (prevSelectionKey) {

                    const prevSelectedRow = this._rows.find(r => r[this._tableSettings.keyFieldName] === prevSelectionKey);

                    if (prevSelectedRow) {

                        prevSelectedRow[this.tableColumn.field] = false;
                    }
                }

                const nextSelectedRow = this._rows.find(r => r[this._tableSettings.keyFieldName] === selectedKeyValue);

                if (nextSelectedRow) {

                    nextSelectedRow[this.tableColumn.field] = true;
                }

                this._rowSelection = selectedKeyValue;
            });
    }

    public onRowSelectionChange(rowData: any, keyValue: number): void {
        this._rowSelectionChange$.next({ rowData, cellData: rowData[this.tableColumn.field], keyValue });
    }

    public ngOnDestroy(): void {

        this._destroy$.next();
        this._destroy$.complete();
    }
}
