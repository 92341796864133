import { FactoryProvider, InjectionToken } from '@angular/core';

export class EnumMap extends Map<any, { label: string; styleClass?: string; }>{
}

const enumTokens = new Map<any, InjectionToken<any>>();

export function buildEnumMapProvider(enumObj: any,
    valueLocalizeFunc: (val: any) => string,
    styleClassFunc?: (val: any) => string): FactoryProvider {

    return {
        provide: enumToken(enumObj),
        useFactory: () => new EnumMap(
            Object.keys(enumObj)
                .filter(key => isNaN(Number.parseInt(key)))
                .map(key => [key, valueLocalizeFunc(enumObj[key])])
                .filter(([, label]) => !!label)
                .map(([key, label]) => [key, {
                    label: label,
                    styleClass: styleClassFunc ? styleClassFunc(enumObj[key]) : null
                }]))
    };
}

export function enumToken(e: any): InjectionToken<any> {

    if (enumTokens.has(e)) {
        return enumTokens.get(e);
    }

    const token = new InjectionToken<any>('');
    enumTokens.set(e, token);

    return token;
}

