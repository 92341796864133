<ng-container *ngIf="(isReadonlyWrapper$ | async) as wrapper">
    <div *ngIf="!wrapper.isReadonly"
         class="mt-3 flex">
        <sc-search-member [members]="filteredMembers$ | async"
                          (filter)="filterMembers($event)"
                          (selectItem)="addContentEditor($event)"
                          class="flex-grow-1"
                          emptyMessage="No members found"
                          i18n-emptyMessage>
        </sc-search-member>
    </div>

    <p-table [scTableSettings]="tableSettings"
             class="mt-3">
        <ng-template *ngIf="!wrapper.isReadonly"
                     pTemplate="row-actions"
                     let-rowData>
            <i class="fas fa-trash"
               (click)="deleteContentEditor(rowData.memberId)"
               pTooltip="Click to delete content editor"
               tooltipPosition="top"
               i18n-pTooltip></i>
        </ng-template>
    </p-table>
</ng-container>