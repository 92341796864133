import { isPlatformServer } from '@angular/common';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Observable, switchMap, first } from 'rxjs';

import { ENV_TOKEN, IEnvironment } from 'sc-common/core/models/environment';
import { IdentityService } from 'sc-common/core/services/identity/identity.service';

@Injectable()
export class IdentityInterceptor implements HttpInterceptor {

    private readonly _isServer: boolean;

    constructor(
        private readonly _identityService: IdentityService,
        @Inject(ENV_TOKEN) private readonly _env: IEnvironment,
        @Inject(PLATFORM_ID) platformId: object) {

        this._isServer = isPlatformServer(platformId);
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this._isServer || req.url.startsWith(this._env.identityProviderUrl)) {
            return next.handle(req);
        }

        return this._identityService.accessToken$
            .pipe(
                first(),
                switchMap(accessToken => next.handle(this._getRequest(req, accessToken))));
    }

    private _getRequest(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {

        return accessToken
            ? request.clone({
                setHeaders: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            : request;
    }
}
