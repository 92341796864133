import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { intersection } from 'lodash-es';

import { map, Observable, tap } from 'rxjs';

import { PermissionService } from 'sc-common/core/services/identity/permission.service';
import { RouteData } from 'sc-common/core/utils/route-data';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

    constructor(private readonly _permissionService: PermissionService, private _router: Router) {
    }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const routeData = next.data as RouteData;

        let result$: Observable<boolean>;

        if (routeData?.hasPermission) {

            const hasPermission = routeData?.hasPermission;

            const permissions = Array.isArray(hasPermission)
                ? hasPermission
                : [hasPermission];

            result$ = this._permissionService.memberPermissions$
                .pipe(
                    map(memberPermissions => intersection(memberPermissions, permissions).length > 0));
        }
        else if (routeData?.hasAnyChildModulePermission) {

            const routePermissionList = this._getChildModulePermissions(state.root.firstChild.routeConfig);

            result$ = this._permissionService.memberPermissions$
                .pipe(
                    map(memberPermissions => intersection(memberPermissions, routePermissionList).length > 0));

        } else {

            return true;
        }

        return result$.pipe(tap(r => r || this._router.navigateByUrl('/forbidden', { skipLocationChange: true })));
    }

    private _getChildModulePermissions(routeConfig: Route): number[] {

        let result = routeConfig.children?.map(c => this._getChildModulePermissions(c)).flat() ?? [];

        const routePermissions = (routeConfig.data as RouteData)?.hasPermission;

        if (routePermissions) {

            const permissions = Array.isArray(routePermissions)
                ? routePermissions
                : [routePermissions];

            result = [...result, ...permissions];
        }

        return result;
    }
}
