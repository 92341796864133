import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostBinding, Inject, NgZone, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';

import { PrimeNGConfig } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';

@Directive({ selector: '[scOverflowTooltip]' })
export class OverflowTooltipDirective extends Tooltip implements OnInit, AfterViewInit, OnDestroy {

    @HostBinding('class')
    public styleClass: any;

    constructor(@Inject(PLATFORM_ID) platformId: any, el: ElementRef, zone: NgZone, config: PrimeNGConfig, renderer: Renderer2, changeDetector: ChangeDetectorRef) {

        super(platformId, el, zone, config, renderer, changeDetector);

        this.setOption({ showDelay: 300 });
    }

    public ngOnInit(): void {

        this.styleClass = this.disabled ? '' : 'sc-overflow-ellipsis sc-span-overflow-fixer';
    }

    public ngAfterViewInit(): void {

        this.mouseEnterListener = this.onMouseEnter.bind(this);
        this.mouseLeaveListener = this.onMouseLeave.bind(this);

        this.el.nativeElement.addEventListener('mouseenter', this.mouseEnterListener);
        this.el.nativeElement.addEventListener('mouseleave', this.mouseLeaveListener);
    }

    public ngOnDestroy(): void {

        this.el.nativeElement.removeEventListener('mouseenter', this.mouseEnterListener);
        this.el.nativeElement.removeEventListener('mouseleave', this.mouseLeaveListener);

        super.ngOnDestroy();
    }

    public onMouseEnter(e: Event): void {

        if (this.disabled) {

            super.onMouseEnter(e);

            return;
        }

        const targetEl = e.target as HTMLElement;

        if (targetEl && targetEl.offsetWidth < targetEl.scrollWidth) {

            this.setOption({ tooltipLabel: targetEl.textContent });
            super.onMouseEnter(e);
        }
    }
}
