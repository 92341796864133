import { Directive, HostBinding, Input } from '@angular/core';

@Directive(
    {
        selector: '[scIndeterminateCheckbox]'
    })
export class IndeterminateCheckboxDirective {

    @HostBinding('class.sc-indeterminate-checkbox')
    public middleStateClass: any;

    @Input()
    public set scIndeterminateCheckbox(value: boolean) {
        this.middleStateClass = value;
    }
}
