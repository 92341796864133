import { Component } from '@angular/core';

import { Observable } from 'rxjs';

import { FileResponse, RendererBaseComponent } from 'sc-common';
@Component({
    templateUrl: './file-download-renderer.component.html'
})

export class FileDownloadRendererComponent extends RendererBaseComponent {

    public downloadFileExpr: (rowModel: any) => Observable<FileResponse>;

    constructor() {
        super();
    }
}
