import { Component, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';

import { DialogService } from 'primeng/dynamicdialog';

import { filter, map, Observable, Subject, takeUntil } from 'rxjs';

import { ApiChapter, ApiChapterListItem, ChaptersClient } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { INewChapterDialogData } from 'content-manager/src/app/projects/project/chapters/new-chapter-dialog/new-chapter-dialog-data';
import { NewChapterDialogComponent } from 'content-manager/src/app/projects/project/chapters/new-chapter-dialog/new-chapter-dialog.component';
import { ProjectStateService } from 'content-manager/src/app/projects/project/project-state.service';
import { Renderers, ScConfirmationService, TableSettings, ValidationConstants, ValidationService } from 'sc-common';

@Component({
    templateUrl: './project-chapters.component.html'
})
export class ProjectChaptersComponent implements OnDestroy {

    public readonly tableModel$: Observable<{ isReadonly: boolean; tableSettings: TableSettings<ApiChapterListItem>; }>;

    private readonly _refreshTable$ = new Subject<void>();

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _dialogService: DialogService,
        private readonly _chaptersClient: ChaptersClient,
        private readonly _confirmationService: ScConfirmationService,
        private readonly _projectStateService: ProjectStateService) {

        this.tableModel$ = _projectStateService.model$
            .pipe(map(project => ({ isReadonly: project.isReadonly, tableSettings: this._initTable(project.isReadonly) })));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._refreshTable$.complete();
    }

    public newChapterDialog(): void {

        const data: INewChapterDialogData = {
            createChapterExpr: (model: ApiChapter) => this._projectStateService.firstSwitchMap(project => this._chaptersClient.create(project.id, model))
        };

        const ref = this._dialogService.open(NewChapterDialogComponent, {
            header: $localize`Add new chapter`,
            data: data
        });

        ref.onClose
            .pipe(
                filter((result: boolean) => result),
                takeUntil(this._destroy$)
            ).subscribe(() => this._refreshTable$.next());
    }

    public deleteChapter(chapterId: number): void {
        this._confirmationService.confirm({
            width: '30vw',
            title: $localize`Delete Chapter`,
            message: $localize`Are you sure to delete this chapter?`,
            acceptLabel: $localize`Delete`,
            accept$: this._projectStateService.firstSwitchMap(project => this._chaptersClient.delete(project.id, chapterId)),
            acceptCallback: () => {
                this._refreshTable$.next();
            }
        });
    }

    private _initTable(isReadonly: boolean): TableSettings<ApiChapterListItem> {
        return TableSettings.create(ApiChapterListItem, {
            dataSource: oDataQuery => this._projectStateService.switchMap(project => this._chaptersClient.getList(project.id, oDataQuery)),
            actionsCount: 1,
            columnResizeMode: 'fit',
            paginator: false,
            hasFilters: false,
            emptyMessageText: $localize`No chapters`,
            reload$: this._refreshTable$.asObservable(),
            columns: {
                pos: {
                    width: '60px',
                    centered: true
                },
                name: {
                    header: $localize`Chapter`,
                    renderer: Renderers.text(null, null, true),
                    editable: isReadonly ? null : {
                        callback: (_, rowData) => this._projectStateService.firstSwitchMap(project =>
                            this._chaptersClient.update(project.id, rowData.id, new ApiChapter({ name: rowData.name }))),
                        showIcon: true,
                        showValidationTooltip: true,
                        reloadRequired: false,
                        validators: [
                            ValidationService.alphaNumSymbolValidator,
                            Validators.maxLength(ValidationConstants.Max250Length)
                        ]
                    }
                }
            },
            defaultSort: {
                field: x => x.pos,
                order: 'asc'
            },
            reordering: isReadonly ? null : {
                field: x => x.pos,
                acceptCallback: order => this._projectStateService.firstSwitchMap(project => this._chaptersClient.updateOrder(project.id, order)),
                separateColumn: true
            }
        });
    }
}
