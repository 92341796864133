import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

function getWindow(): any {
    return window;
}

@Injectable(
    {
        providedIn: 'root'
    })
export class WindowRefService {
    public get nativeWindow(): Window {
        return getWindow();
    }

    constructor(@Inject(DOCUMENT) private readonly _document: Document) {
    }

    public openPopupInMiddle(url: string, popupWidth: number, popupHeight: number): Window {

        const nativeWindow = this.nativeWindow;

        const dualScreenLeft = nativeWindow.screenLeft !== undefined ? nativeWindow.screenLeft : nativeWindow.screenX;
        const dualScreenTop = nativeWindow.screenTop !== undefined ? nativeWindow.screenTop : nativeWindow.screenY;

        const width = nativeWindow.innerWidth
            ? nativeWindow.innerWidth
            : this._document.documentElement.clientWidth
                ? this._document.documentElement.clientWidth
                : screen.width;

        const height = nativeWindow.innerHeight
            ? nativeWindow.innerHeight
            : this._document.documentElement.clientHeight
                ? this._document.documentElement.clientHeight
                : screen.height;

        const systemZoom = width / nativeWindow.screen.availWidth;
        const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft;
        const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop;
        const newWindow = nativeWindow.open(url, null,
            `
            scrollbars=yes,
            width=${ popupWidth / systemZoom },
            height=${ popupHeight / systemZoom },
            top=${ top },
            left=${ left }
            `
        );

        if (nativeWindow.focus) {
            newWindow.focus();
        }

        return newWindow;
    }
}
