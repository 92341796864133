import { Component } from '@angular/core';

import { DialogService } from 'primeng/dynamicdialog';

import { RendererBaseComponent } from 'sc-common';
import { ICommentsData } from 'sc-common/shared/components/comments-dialog/comments-dialog-data';
import { CommentsDialogComponent } from 'sc-common/shared/components/comments-dialog/comments-dialog.component';

@Component({
    templateUrl: 'comments-renderer.component.html'
})
export class CommentsRendererComponent extends RendererBaseComponent {

    public dialogDataInitializer: (rowData: any) => ICommentsData;

    public isReadonly: boolean;

    constructor(private _dialogService: DialogService) {
        super();
    }

    public openComments(rowData: any): void {

        this._dialogService.open(CommentsDialogComponent, {
            header: $localize`Comments`,
            width: '50vw',
            data: this.dialogDataInitializer(rowData)
        });
    }
}
