import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { GlobalErrorHandler } from 'sc-common/core/services/error-handler/error-handler.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private readonly _router: Router,
        private readonly _errorHandler: GlobalErrorHandler) { }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        return next.handle(request).pipe(
            tap({
                error: (httpError: HttpErrorResponse) => {

                    if (httpError.status === 403) {
                        this._handleForbiddenError();
                    }

                    if (httpError.status === 404) {
                        this._handleNotFoundError();
                    }

                    if (httpError.status >= 500) {
                        this._errorHandler.handleError(httpError);
                        this._handleServerError();
                    }
                }
            })
        );
    }

    private _handleForbiddenError(): void {
        this._router.navigate(['/forbidden']);
    }

    private _handleServerError(): void {
        this._router.navigate(['/server-error']);
    }

    private _handleNotFoundError(): void {
        this._router.navigate(['/not-found']);
    }
}
