import { Injectable } from '@angular/core';

import { CheckboxChangeEvent } from 'primeng/checkbox';

import { BehaviorSubject, filter, map, mergeWith, Observable, Subject } from 'rxjs';

import { ScFilter, ScFilterCollection } from 'sc-common/core/models/filter';
import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { ClientStorageService } from 'sc-common/core/services/client-storage/client-storage.service';
import { PageUpdateInfo } from 'sc-common/shared/table/models/page-update-info';
import { RowDragDropData } from 'sc-common/shared/table/models/row-drag-drop-data';
import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableSettings } from 'sc-common/shared/table/models/table-settings';

export type ColumnsInfo = { columns: TableColumn[]; hidden: string[]; };

@Injectable()
export class TableStateService {

    private readonly _resetSubject$ = new Subject<void>();

    private readonly _reloadSubject$ = new Subject<void>();

    private readonly _pageUpdate$ = new BehaviorSubject<PageUpdateInfo>(null);

    private readonly _columnsToggle$ = new BehaviorSubject<string[]>(null);

    private readonly _filter$ = new Subject<ScFilter>();

    private readonly _columnsSource$ = new BehaviorSubject<ColumnsInfo>(null);

    private readonly _selectionSource$ = new BehaviorSubject<any[]>([]);

    private readonly _pageSelectionChange$ = new Subject<CheckboxChangeEvent>();

    private readonly _submitRowsOrder$ = new Subject<boolean>();

    private readonly _rowDragDrop$ = new BehaviorSubject<RowDragDropData>(null);

    private readonly _requestInProgress$ = new Subject<boolean>();

    private readonly _actionInProgress$ = new BehaviorSubject<boolean>(false);

    public readonly reset$: Observable<any>;

    public readonly reload$: Observable<any>;

    public readonly pageUpdate$: Observable<PageUpdateInfo>;

    public readonly columnsToggle$: Observable<string[]>;

    public readonly filter$: Observable<ScFilter>;

    public readonly columnsSource$: Observable<ColumnsInfo>;

    public readonly selectionSource$: Observable<any[]>;

    public readonly pageSelectionChange$: Observable<CheckboxChangeEvent>;

    public readonly submitRowsOrder$: Observable<boolean>;

    public readonly rowDragDrop$: Observable<RowDragDropData>;

    public readonly requestInProgress$: Observable<boolean>;

    public readonly actionInProgress$: Observable<boolean>;

    constructor(
        private readonly _clientStorage: ClientStorageService) {

        this.reset$ = this._resetSubject$.asObservable();

        this.reload$ = this._reloadSubject$.asObservable();

        this.columnsToggle$ = this._columnsToggle$.asObservable();

        this.filter$ = this._filter$.asObservable();

        this.columnsSource$ = this._columnsSource$.asObservable();

        this.selectionSource$ = this._selectionSource$.asObservable();

        this.pageSelectionChange$ = this._pageSelectionChange$.asObservable();

        this.pageUpdate$ = this._pageUpdate$.asObservable();

        this.submitRowsOrder$ = this._submitRowsOrder$.asObservable();

        this.rowDragDrop$ = this._rowDragDrop$.asObservable();

        this.requestInProgress$ = this._requestInProgress$.asObservable();

        this.actionInProgress$ = this._actionInProgress$.asObservable()
            .pipe(mergeWith(this._pageUpdate$
                .pipe(filter(p => !!p), map(() => false))));
    }

    public submitRowsOrder(saveState: boolean): void {

        this._submitRowsOrder$.next(saveState);
    }

    public pageUpdate(pageInfo: PageUpdateInfo): void {

        this._pageUpdate$.next(pageInfo);
    }

    public reset(): void {

        this._resetSubject$.next();
    }

    public reload(): void {

        this._reloadSubject$.next();
    }

    public toggleColumnsByNames(columns: string[]): void {

        this._columnsToggle$.next(columns);
    }

    public setColumns(columns: TableColumn[], hidden: string[]): void {

        this._columnsSource$.next({ columns, hidden });
    }

    public filter(value: any, field: string, matchMode: MatchMode): void {

        this._filter$.next({ value, field, matchMode });
    }

    public togglePageSelection(event: CheckboxChangeEvent): void {

        this._pageSelectionChange$.next(event);
    }

    public onSelectionChanged(records: any): void {

        this._selectionSource$.next(records);
    }

    public getStateKey(settings: TableSettings<any>): string {
        return `table_${ settings.id }`;
    }

    public saveAdvancedFilter(state: ScFilterCollection, settings: TableSettings<any>): void {

        if (settings.useState) {
            if (settings.stateStorage === 'local') {
                this._clientStorage.saveToLocalStorage(this._getAdvancedFilterStateKey(settings), JSON.stringify(state));
            } else if (settings.stateStorage === 'session') {
                this._clientStorage.saveToSessionStorage(this._getAdvancedFilterStateKey(settings), JSON.stringify(state));
            }
        }
    }

    public getAdvancedFilterState(settings: TableSettings<any>): ScFilterCollection | null {
        let filters: string;

        if (!settings.useState) { return null; }

        if (settings.stateStorage === 'local') {
            filters = this._clientStorage.getFromLocalStorage(this._getAdvancedFilterStateKey(settings));
        } else if (settings.stateStorage === 'session') {
            filters = this._clientStorage.getFromSessionStorage(this._getAdvancedFilterStateKey(settings));
        }

        if (filters) {
            const state: ScFilterCollection = JSON.parse(filters);

            state?.filters.forEach(f => {
                f.value = this.getCastedFilterValue(f.value);
            });

            return state;
        }

        return null;
    }

    public getCastedFilterValue(value: any): any {

        let result = value;

        if (typeof value === 'boolean') {

            result = value as boolean;
        }
        else if (!isNaN(value)) {

            result = +value;
        }
        else if (Array.isArray(value)) {

            result = value.map(x => this.getCastedFilterValue(x));
        }
        else if (Date.parse(value)) {

            result = new Date(Date.parse(value));
        }

        return result;
    }

    public dragRow(data: RowDragDropData): void {

        this._rowDragDrop$.next({ ...data });
    }

    public setActionStarted(): void {

        this._actionInProgress$.next(true);
    }

    public setActionComplete(): void {

        this._actionInProgress$.next(false);
    }

    private _getAdvancedFilterStateKey(settings: TableSettings<any>): string {

        return this.getStateKey(settings) + '_AdvancedFilter';
    }
}
