import { ProjectPlagiarismCheckStatusEnum } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { buildEnumMapProvider } from 'sc-common';

export const plagiarismCheckStatusEnumMapProvider = buildEnumMapProvider(ProjectPlagiarismCheckStatusEnum, getEnumText, getStatusStyle);

export function getEnumText(value: ProjectPlagiarismCheckStatusEnum): string {

    switch (value) {

        case ProjectPlagiarismCheckStatusEnum.Unchecked:
            return $localize`Unchecked`;
        case ProjectPlagiarismCheckStatusEnum.Checked:
            return $localize`Checked`;
        case ProjectPlagiarismCheckStatusEnum.Failed:
            return $localize`Failed`;
        case ProjectPlagiarismCheckStatusEnum.FinishedWithErrors:
            return $localize`Finished with Errors`;
    }
}

export function getStatusStyle(status: ProjectPlagiarismCheckStatusEnum): string {
    switch (status) {
        case ProjectPlagiarismCheckStatusEnum.Unchecked:
            return 'success';
        case ProjectPlagiarismCheckStatusEnum.Checked:
            return 'success';
        case ProjectPlagiarismCheckStatusEnum.Failed:
            return 'danger';
        case ProjectPlagiarismCheckStatusEnum.FinishedWithErrors:
            return 'warning';

        default: return '';
    }
}
