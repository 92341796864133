import { Component, TemplateRef, ViewChild } from '@angular/core';

import { TableSettings } from 'sc-common/shared/table/models/table-settings';

@Component({
    templateUrl: 'colgroup.component.html'
})
export class ColGroupComponent {

    @ViewChild('templateRef')
    public readonly templateRef: TemplateRef<any>;

    public hasActionsColumn = (): boolean => false;

    constructor(public readonly tableSettings: TableSettings<any>) {
    }
}
