<div class="sc-upload-container">
    <div *ngIf="!file"
         class="sc-upload-empty"
         (click)="selectFile()"
         (dragover)="onDragOver($event)"
         (drop)="onDragDrop($event)">
        <div class="sc-upload-label">
            <i class="fas fa-cloud-upload-alt"></i>
            <div i18n>Upload a file</div>
            <input #fileInput
                   type="file"
                   [accept]="accept"
                   [multiple]="false"
                   [disabled]="disabled"
                   (change)="onFileSelect($event)">
        </div>

        <div class="sc-upload-format"
             i18n>
            {{ accept }} - FORMAT
        </div>
    </div>

    <div *ngIf="file"
         class="sc-upload-file-selected">
        <div class="sc-upload-clear-block">
            <p-button icon="pi pi-times"
                      styleClass="p-button-rounded p-button-text sc-upload-clear-button"
                      (onClick)="clearFile($event)"
                      [disabled]="uploading">
            </p-button>
        </div>
        <div class="sc-upload-selected-file-block">
            <div>
                <i *ngIf="!uploading"
                   [ngClass]="['sc-selected-file-icon', fileIconClass]">
                </i>
                <sc-progress-circle *ngIf="uploading"
                                    [progress]="progress$ | async">
                </sc-progress-circle>
            </div>
            <div class="sc-upload-selected-file-info">
                <div class="sc-file-name-label">{{ file.name }}</div>
                <div class="sc-file-size-label">{{ file.size | fileSize }}</div>
            </div>
        </div>
    </div>

    <p-messages *ngIf="errors?.length"
                [value]="errors"
                [closable]="false"></p-messages>
</div>