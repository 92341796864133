<ng-template #displayTemplateRef
             let-cellData>
    {{getCellValue(cellData)}}
</ng-template>

<ng-template #editableTemplateRef
             let-rowData="rowData">
    <p-inputNumber scValidatable
                   [scValidatableTooltip]="tableColumn.settings.editable.showValidationTooltip"
                   [scEditableCellValidation]="tableColumn"
                   [(ngModel)]="rowData[tableColumn.field]"
                   [step]="0"></p-inputNumber>
</ng-template>
