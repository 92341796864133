<ng-template #templateRef>
    <div class="p-inputgroup">
        <p-multiSelect #multiselectRef
                       scrollHeight="400px"
                       dataKey="id"
                       optionLabel="name"
                       appendTo="body"
                       [showToggleAll]="false"
                       [options]="itemsSource"
                       panelStyleClass="sc-table-group-multiselect-filter"
                       [filter]="multiselectRef.options?.length > 10"
                       [showHeader]="true"
                       [placeholder]="placeholder"
                       [(ngModel)]="multiselectFilterValues"
                       (onChange)="onSelectionChange()">
            <p-header>
                <span class="sc-groups-label font-bold">{{groupsLabel}}</span>

                <div class="field-checkbox mt-4 mb-4"
                     *ngFor="let groupItem of groupListItems">
                    <p-checkbox [label]="groupItem.label"
                                [(ngModel)]="selectedGroups"
                                [value]="groupItem.value"
                                (onChange)="onSelectedGroupsChange(multiselectRef, groupItem.value)"
                                [scIndeterminateCheckbox]="isNotAllGroupValuesSelected(groupItem.value)"></p-checkbox>
                </div>

                <span class="sc-items-label font-bold">{{itemsLabel}}</span>
            </p-header>
        </p-multiSelect>
        <button *ngIf="isFilterApplied"
                pButton
                type="button"
                icon="pi pi-times"
                (click)="cleanFilter()"></button>
    </div>
</ng-template>
