<ng-template #templateRef>
    <div class="p-inputgroup">
        <input type="number"
               pInputText
               class="text-center"
               [disabled]="hasSameLimits"
               [(ngModel)]="rangeFilterValue[0]"
               (change)="filter()" />
        <input type="number"
               pInputText
               class="text-center"
               [disabled]="hasSameLimits"
               [(ngModel)]="rangeFilterValue[1]"
               (change)="filter()" />
        <button *ngIf="isFilterApplied"
                pButton
                type="button"
                icon="pi pi-times"
                (click)="cleanFilter()"></button>
    </div>
</ng-template>
