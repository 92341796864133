import { Component, OnDestroy, TemplateRef, TrackByFunction, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableSettings } from 'sc-common/shared/table/models/table-settings';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Component({
    templateUrl: 'table-body.component.html'
})
export class TableBodyComponent implements OnDestroy {

    private readonly _destroy$ = new Subject<void>();

    private _cellSpanMeta: Map<number, number> = null;

    private readonly _groupingField = this.tableSettings.groupingField;

    public get canReorderRows(): boolean {

        return !!this.tableSettings.reordering;
    }

    public get canApplyGrouping(): boolean {

        return !!this.tableSettings.grouping;
    }

    @ViewChild('templateRef')
    public readonly templateRef: TemplateRef<any>;

    public actionsTemplate: TemplateRef<any>;

    public readonly trackByFunc: TrackByFunction<TableColumn> = (_: number, item: TableColumn): any => item.field;

    constructor(
        public readonly tableSettings: TableSettings<any>,
        private readonly _stateService: TableStateService,
        private readonly _router: Router) {

        this._initRowGrouping();
    }

    public ngOnDestroy(): void {

        this._destroy$.next();

        this._destroy$.complete();
    }

    public checkHasRowSpan(rowIndex: number): boolean {

        const spanCount = this._cellSpanMeta?.get(rowIndex);

        return spanCount && spanCount > 2;
    }

    public checkHasNoGrouping(isGroupable: boolean, rowIndex: number): boolean {

        const spanCount = this._cellSpanMeta?.get(rowIndex);

        return !isGroupable || spanCount === 2;
    }

    public getRowSpanCount(rowIndex: number): number {

        return this._cellSpanMeta?.get(rowIndex);
    }

    public getGroupId(rowData: any): any {

        return this._groupingField ? rowData[this._groupingField] : null;
    }

    public preventMouseEvent(event: MouseEvent): void {
        event.stopPropagation();
    }

    public navigate(path: any[]): void {
        this._router.navigate(path);
    }

    public getRowKeyValue(rowData: any): any {

        return rowData[this.tableSettings.keyFieldName];
    }

    private _initRowGrouping(): void {

        if (this.canApplyGrouping) {

            this._stateService.pageUpdate$
                .pipe(
                    // Apply grouping algorithm only if query sorted field corresponds to default sort field
                    filter(pageInfo => pageInfo?.queryParams.sortMeta.some(m => m.field === this._groupingField)),
                    takeUntil(this._destroy$))
                .subscribe(pageInfo => {

                    // Group by cell value
                    const reduced = pageInfo.records.reduce<{ [item: string]: number[]; }>((p, n, i) => {

                        // Span cells by field value.
                        const cellValue: string = n[this._groupingField] ?? '';

                        return { ...p, ...{ [cellValue]: [i, ...(p[cellValue] ?? [])] } };
                    }, {});

                    // Fill the two-dimensional array [index, counter][]
                    // where index is an index of first row in a group and count is a counter of rows in a group
                    const result = Object.entries(reduced)
                        .map(([_, v]) => v)
                        .filter(x => x.length)
                        .map(x => ([Math.min(...x), x.length + 1]) as [number, number]);

                    // Assign prev array to map
                    this._cellSpanMeta = new Map(result);
                });
        }
    }
}
