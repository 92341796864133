import { Type } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiListItemPosition, PageResult } from 'sc-common';
import { ScFilter } from 'sc-common/core/models/filter';
import { getExpressionField, getExpressionFieldPath } from 'sc-common/core/utils/expression-path';
import { hasColumnMap } from 'sc-common/shared/table/models/column-map.decorator';
import { ColumnSettings } from 'sc-common/shared/table/models/column-settings';
import { ExportSettings } from 'sc-common/shared/table/models/export-settings';
import { hasKeyMap } from 'sc-common/shared/table/models/key-map.decorator';

type SortModel<TModel extends { [item: string]: any; }> = {

    field: (m: TModel) => TModel[keyof TModel];

    order?: 'asc' | 'desc';
};

type DefaultSortModel<TModel extends { [item: string]: any; }> = {
    /**
     * If set, applies default sorting by specified field
     */
    sortBy: SortModel<TModel> | SortModel<TModel>[];
    /**
     * Disable sorting for columns whose name if different than field in expression or rowReorderOptions.field
     */
    restColumnsNonSortable?: boolean;
};

class TableSettingsBase<TModel extends { [item: string]: any; }> {

    public static readonly DefaultRowsCount = 10;

    protected constructor() { }

    public readonly id?: string;

    public readonly useState?: boolean = false;

    public readonly stateStorage?: 'session' | 'local' = 'session';

    public readonly paginator?: boolean = true;

    public readonly rowHover?: boolean = true;

    public readonly rows?: number;

    public readonly scrollable?: boolean = true;

    public readonly sortMode?: 'single' | 'multiple' = 'single';

    public readonly resizableColumns?: boolean = true;

    public readonly columnResizeMode?: 'fit' | 'expand' = 'expand';

    public readonly scrollHeight?: string = 'flex';

    public readonly reorderableColumns?: boolean = true;

    public readonly rowsPerPageOptions?: number[] = [10, 50, 150];

    public readonly alwaysShowPaginator?: boolean = true;

    public readonly selectionMode?: 'single' | 'multiple';

    public readonly hasFilters?: boolean = true;

    public readonly hasAdvancedFilters?: boolean = false;

    public readonly hasHeaders?: boolean = true;

    public readonly toggleColumns?: {
        canToggleColumns?: boolean;

        toggleDropdownAsLink?: boolean;
    };

    public readonly export?: ExportSettings;

    public readonly dataSource: (oDataQuery: string) => Observable<PageResult<TModel>>;

    /**
     * Explicitly set filters.
     */
    public readonly explicitFilterSource$?: Observable<ScFilter[]>;

    public readonly columns?: { [item in keyof TModel]?: Readonly<ColumnSettings<TModel[item], TModel>> } = {};

    /**
     * Specifies default column order, from left to right, columns listed in this array will have priority over other columns.
     */
    public readonly columnsOrder?: (keyof TModel | any)[];

    public readonly doubleClickRedirectUrl?: (m: TModel) => any[];

    public readonly defaultSort?: SortModel<TModel> | SortModel<TModel>[] | DefaultSortModel<TModel>;

    /**
     * Applies grouping to column
     * Also applies default primary sort
     */
    public readonly grouping?: Partial<SortModel<TModel>> & {

        /**
         * Column which span will be affected by grouping.
         */
        column: (m: TModel) => TModel[keyof TModel];
    };

    /**
     * Set of options for initializing row reordering
     */
    public readonly reordering?: SortModel<TModel> & {

        /**
         * Reordering changes accept callback
         */
        acceptCallback: (model: ApiListItemPosition[]) => Observable<void>;

        separateColumn?: boolean;
    };

    public readonly hideColumnsAfter?: (m: TModel) => TModel[keyof TModel];

    public readonly reload$?: Observable<{ skipLoadingIndicator: boolean; } | any>;

    public readonly emptyMessageText?: string;

    public readonly rowStyler?: (m: TModel) => {
        [klass: string]: any;
    } | null;

    public readonly actionsCount?: number = 1;
}

// Table settings class is a set of setting required for setting up the overridden behavior for p-table
export class TableSettings<TModel> extends TableSettingsBase<TModel>{

    private _modelFields: string[];

    private _columnNames: string[];

    private _keyFieldName: string;

    // Hidden constructor, instance should be created using static Create method
    private constructor(type: Type<TModel>, inst: TableSettingsBase<TModel>) {

        super();

        Object.assign(this, inst, { id: inst.id ?? type.name });

        this.modelType = type;
    }

    public readonly columnsToggle$: Observable<string[]>;

    // Model type which will be a basis for header and body columns
    public readonly modelType?: Type<TModel>;

    // Creates new instance of table settings for overriding default behavior of p-table
    public static create<T>(type: Type<T>, inst: TableSettingsBase<T>): TableSettings<T> {

        return new TableSettings<T>(type, inst);
    }

    public checkDefaultSortField(field: string): boolean {

        const defaultSortBy = this.defaultSortBy;

        return defaultSortBy
            ? (Array.isArray(defaultSortBy)
                ? defaultSortBy.some(x => field === getExpressionField(x.field))
                : getExpressionField(defaultSortBy.field) === field)
            : null;
    }

    public checkDefaultSortColumn(column: string): boolean {

        const defaultSortBy = this.defaultSortBy;

        return defaultSortBy
            ? (Array.isArray(defaultSortBy)
                ? defaultSortBy.some(x => getExpressionFieldPath(x.field)[0] === column)
                : getExpressionFieldPath(defaultSortBy.field)[0] === column)
            : null;
    }

    public get defaultSortBy(): SortModel<TModel> | SortModel<TModel>[] {

        return (this.defaultSort as DefaultSortModel<TModel>)?.sortBy
            ?? this.defaultSort as SortModel<TModel> | SortModel<TModel>[];
    }

    public get hasDefaultSortColumnsOnly(): boolean {

        return (this.defaultSort as DefaultSortModel<TModel>)?.restColumnsNonSortable;
    }

    public get reorderingField(): string {

        return this.reordering ? getExpressionField(this.reordering.field) : null;
    }

    public get groupingField(): string {

        return this.grouping ? getExpressionField(this.grouping.field ?? this.grouping.column) : null;
    }

    public get groupingColumn(): string {

        return this.grouping ? getExpressionField(this.grouping.column) : null;
    }

    public get modelPrototype(): any {

        return this.modelType.prototype;
    }

    // List for api model fields
    public get modelFields(): string[] {

        if (this._modelFields != null) {
            return this._modelFields;
        }

        let keys: string[] = [];

        keys.push(...Object.getOwnPropertyNames(this.modelType.prototype));

        if (Reflect.getPrototypeOf(this.modelType.prototype)) {
            keys.push(...this._getAllClassProperties(Reflect.getPrototypeOf(this.modelType.prototype)));
        }

        if (this.columnsOrder) {
            keys = keys.sort((x, y) => this.columnsOrder.indexOf(x as keyof TModel) - this.columnsOrder.indexOf(y as keyof TModel));
        }

        return this._modelFields ??= keys;
    }

    // List for api model fields which has column decorator
    public get columnNames(): string[] {

        return this._columnNames ??= this.modelFields.filter(modelField => hasColumnMap(this.modelPrototype, modelField));
    }

    // Api model field which is marked with key decorator
    public get keyFieldName(): string {

        return this._keyFieldName ??= this.modelFields.find(modelField => hasKeyMap(this.modelType.prototype, modelField));
    }

    private _getAllClassProperties(object: any): string[] {
        const keys: string[] = [];

        keys.push(...Object.getOwnPropertyNames(object));

        if (Reflect.getPrototypeOf(object)) {
            keys.push(...this._getAllClassProperties(Reflect.getPrototypeOf(object)));
        }

        return keys;
    }
}
