<header scHeader
        [mainMenu]="mainMenu">
</header>

<div class="flex flex-auto flex-column">
    <main class="h-full">
        <router-outlet></router-outlet>
    </main>
</div>
<footer scFooter></footer>

<sc-global-notification></sc-global-notification>