<div class="mt-3">
    <p-accordion *ngIf="(dialogData.topicRoots$ | async) as topicRoots"
                 [multiple]="true">
        <p-accordionTab *ngFor="let topicRoot of topicRoots"
                        [header]="topicRoot.name"
                        [selected]="isRootSelected(topicRoot)">
            <div class="flex flex-wrap gap-3">
                <p-checkbox *ngFor="let topic of topicRoot.topics"
                            name="topics"
                            [label]="topic.name"
                            [value]="topic.id"
                            [(ngModel)]="selectedTopicIds">
                </p-checkbox>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>

<div class="flex align-items-center justify-content-center w-full mt-5">
    <p-button (onClick)="save()"
              [disabled]="requestInProcess"
              label="Save"
              i18n-label>
    </p-button>
</div>