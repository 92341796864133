<ng-template #templateRef>
    <div class="p-inputgroup">
        <p-calendar #dateCmpRef
                    [selectionMode]="dateFilterType"
                    [(ngModel)]="filterValue"
                    [readonlyInput]="true"
                    [placeholder]="placeholder"
                    dateFormat="dd/mm/yy"
                    appendTo="body"
                    (onSelect)="filter()">
            <p-footer>
                <div class="flex align-items-center justify-content-between">

                    <div>
                        <p-button styleClass="p-button-primary"
                                  class="mr-3"
                                  label="Today"
                                  i18n-label
                                  (onClick)="onTodayClick($event, dateCmpRef)">
                        </p-button>

                        <p-button styleClass="p-button-outlined"
                                  label="Clear"
                                  i18n-label
                                  (onClick)="cleanFilter(); dateCmpRef.onClearButtonClick($event);">
                        </p-button>
                    </div>

                    <p-dropdown inputId="pageSelect"
                                [options]="dateSelectionModeList"
                                [(ngModel)]="dateFilterType"
                                (onChange)="onSelectModeChange($event)"
                                appendTo="body">
                    </p-dropdown>
                </div>
            </p-footer>
        </p-calendar>

        <button *ngIf="isFilterApplied"
                pButton
                type="button"
                icon="pi pi-times"
                (click)="cleanFilter()">
        </button>
    </div>
</ng-template>