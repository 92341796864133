import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { EditorModule } from '@tinymce/tinymce-angular';

import { GridAutocompleteModule } from 'sc-common';
import { CommentComponent } from 'sc-common/shared/components/comments-dialog/comment/comment.component';
import { CommentsDialogComponent } from 'sc-common/shared/components/comments-dialog/comments-dialog.component';
import { GlobalNotificationComponent } from 'sc-common/shared/components/global-notification/global-notification.component';
import { DialogMessageComponent } from 'sc-common/shared/dialog-message/dialog-message.component';
import { CalendarUtcDirective } from 'sc-common/shared/directives/calendar-utc.directive';
import { ScFormGroupDirective } from 'sc-common/shared/directives/form-group.directive';
import { HasPermissionToEditFormDirective } from 'sc-common/shared/directives/has-permission-to-edit-form.directive';
import { HasPermissionDirective } from 'sc-common/shared/directives/has-permission.directive';
import { IndeterminateCheckboxDirective } from 'sc-common/shared/directives/indeterminate-checkbox.directive';
import { InputTrimDirective } from 'sc-common/shared/directives/input-trim.directive';
import { SubmitButtonModule } from 'sc-common/shared/directives/submit-button/submit-button.module';
import { TinyMceEditorDirective } from 'sc-common/shared/directives/tinymce-editor.directive';
import { ScDatePipe } from 'sc-common/shared/pipes/date.pipe';
import { EnumLocalizePipe } from 'sc-common/shared/pipes/enum-localize.pipe';
import { EnumStylePipe } from 'sc-common/shared/pipes/enum-style.pipe';
import { FileHrefPipe } from 'sc-common/shared/pipes/file-href.pipe';
import { FileSizePipe } from 'sc-common/shared/pipes/file-size.pipe';
import { JoinPipe } from 'sc-common/shared/pipes/join.pipe';
import { SafeMessagePipe } from 'sc-common/shared/pipes/safe.pipe';
import { AdvancedFilterComponent } from 'sc-common/shared/table/advanced-filter/advanced-filter.component';
import { CellValidatorDirective } from 'sc-common/shared/table/body/cell-validator.directive';
import { ScEditableColumnDirective } from 'sc-common/shared/table/body/editable-column.directive';
import { ActionLinkRendererComponent } from 'sc-common/shared/table/body/renderers/action-link/action-link-renderer.component';
import { ActionsRendererComponent } from 'sc-common/shared/table/body/renderers/actions/actions-renderer.component';
import { BooleanRendererComponent } from 'sc-common/shared/table/body/renderers/boolean/boolean-renderer.component';
import { DateRendererComponent } from 'sc-common/shared/table/body/renderers/date/date-renderer.component';
import { ExpandableRendererComponent } from 'sc-common/shared/table/body/renderers/expandable/expandable-renderer.component';
import { IconActionRendererComponent } from 'sc-common/shared/table/body/renderers/icon-action/icon-action-renderer.component';
import { LinkRendererComponent } from 'sc-common/shared/table/body/renderers/link/link-renderer.component';
import { ListRendererComponent } from 'sc-common/shared/table/body/renderers/list/list-renderer.component';
import { NumberRendererComponent } from 'sc-common/shared/table/body/renderers/number/number-renderer.component';
import { RadioButtonRendererComponent } from 'sc-common/shared/table/body/renderers/radio-button/radio-button-renderer.component';
import { RawHtmlRendererComponent } from 'sc-common/shared/table/body/renderers/raw-html/raw-html-renderer.component';
import { TagRendererComponent } from 'sc-common/shared/table/body/renderers/tag/tag-renderer.component';
import { TextRendererComponent } from 'sc-common/shared/table/body/renderers/text/text-renderer.component';
import { ScReorderableRowDirective } from 'sc-common/shared/table/body/row-reorder.directive';
import { TableBodyComponent } from 'sc-common/shared/table/body/table-body.component';
import { TableCaptionComponent } from 'sc-common/shared/table/caption/table-caption.component';
import { ColGroupComponent } from 'sc-common/shared/table/colgroup/colgroup.component';
import { TableEmptyComponent } from 'sc-common/shared/table/empty/table-empty.component';
import { DateFilterComponent } from 'sc-common/shared/table/header/filters/date/date-filter.component';
import { DropdownFilterComponent } from 'sc-common/shared/table/header/filters/dropdown/dropdown-filter.component';
import { GroupMultiselectFilterComponent } from 'sc-common/shared/table/header/filters/group-multiselect/group-multiselect-filter.component';
import { IssnFilterComponent } from 'sc-common/shared/table/header/filters/issn-filter/issn-filter.component';
import { MultiselectFilterComponent } from 'sc-common/shared/table/header/filters/multiselect/multiselect-filter.component';
import { NumberFilterComponent } from 'sc-common/shared/table/header/filters/number/number-filter.component';
import { RangeFilterComponent } from 'sc-common/shared/table/header/filters/range/range-filter.component';
import { TextFilterComponent } from 'sc-common/shared/table/header/filters/text/text-filter.component';
import { TableHeaderComponent } from 'sc-common/shared/table/header/table-header.component';
import { PaginatorComponent } from 'sc-common/shared/table/paginator/paginator.component';
import { TableSettingsDirective } from 'sc-common/shared/table/table-settings.directive';
import { OverflowTooltipDirective } from 'sc-common/shared/tooltip/tooltip.directive';
import { ValidatableModule } from 'sc-common/shared/validatable/validatable.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        CalendarModule,
        DropdownModule,
        DialogModule,
        FileUploadModule,
        MultiSelectModule,
        InputTextModule,
        InputNumberModule,
        TableModule,
        OverlayPanelModule,
        ListboxModule,
        TooltipModule,
        InputMaskModule,
        SplitButtonModule,
        CheckboxModule,
        InputTextareaModule,
        ToastModule,
        TagModule,
        RadioButtonModule,
        ProgressBarModule,
        CardModule,
        BadgeModule,
        TieredMenuModule,
        SkeletonModule,
        AutoCompleteModule,
        EditorModule,
        SubmitButtonModule,
        DataViewModule,
        ValidatableModule,
        ScDatePipe,
        FileSizePipe,
        JoinPipe,
        GridAutocompleteModule,
        EnumStylePipe,
        EnumLocalizePipe,
        SafeMessagePipe
    ],
    declarations: [
        TableSettingsDirective,
        DateFilterComponent,
        DropdownFilterComponent,
        MultiselectFilterComponent,
        NumberFilterComponent,
        TextFilterComponent,
        DateRendererComponent,
        TextRendererComponent,
        LinkRendererComponent,
        ActionLinkRendererComponent,
        NumberRendererComponent,
        ListRendererComponent,
        TableHeaderComponent,
        TableCaptionComponent,
        TableBodyComponent,
        TableEmptyComponent,
        ColGroupComponent,
        AdvancedFilterComponent,
        RangeFilterComponent,
        TagRendererComponent,
        CalendarUtcDirective,
        ActionsRendererComponent,
        BooleanRendererComponent,
        GroupMultiselectFilterComponent,
        HasPermissionDirective,
        HasPermissionToEditFormDirective,
        PaginatorComponent,
        OverflowTooltipDirective,
        ExpandableRendererComponent,
        IndeterminateCheckboxDirective,
        IssnFilterComponent,
        ScReorderableRowDirective,
        InputTrimDirective,
        RawHtmlRendererComponent,
        ScEditableColumnDirective,
        CellValidatorDirective,
        RadioButtonRendererComponent,
        ScFormGroupDirective,
        GlobalNotificationComponent,
        FileHrefPipe,
        DialogMessageComponent,
        TinyMceEditorDirective,
        CommentComponent,
        CommentsDialogComponent,
        IconActionRendererComponent
    ],
    exports: [
        CommonModule,
        TableSettingsDirective,
        ButtonModule,
        InputTextModule,
        InputTextareaModule,
        TableModule,
        ReactiveFormsModule,
        FormsModule,
        DialogModule,
        MultiSelectModule,
        CalendarModule,
        InputNumberModule,
        ToastModule,
        ProgressBarModule,
        SplitButtonModule,
        FileUploadModule,
        DropdownModule,
        InputNumberModule,
        CheckboxModule,
        RadioButtonModule,
        TooltipModule,
        OverlayPanelModule,
        EditorModule,
        SubmitButtonModule,
        CalendarUtcDirective,
        HasPermissionDirective,
        HasPermissionToEditFormDirective,
        EnumLocalizePipe,
        OverflowTooltipDirective,
        IndeterminateCheckboxDirective,
        InputMaskModule,
        IssnFilterComponent,
        TagModule,
        InputTrimDirective,
        SafeMessagePipe,
        JoinPipe,
        ScFormGroupDirective,
        GlobalNotificationComponent,
        FileHrefPipe,
        DialogMessageComponent,
        BadgeModule,
        TieredMenuModule,
        EnumStylePipe,
        TinyMceEditorDirective,
        CardModule,
        AutoCompleteModule,
        CommentComponent,
        CommentsDialogComponent,
        DataViewModule,
        ValidatableModule,
        ScDatePipe,
        FileSizePipe,
        GridAutocompleteModule
    ],
    providers: [
        JoinPipe
    ]
})
export class CommonSharedModule { }
