import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UnsavedFormStateService implements OnDestroy {

    private _unsavedForms = new Set<UntypedFormGroup>();

    private readonly _destroy$ = new Subject<void>();

    constructor(router: Router) {

        router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                takeUntil(this._destroy$))
            .subscribe(() => this.clearUnsavedFormsCount());
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public get hasUnsavedForms(): boolean {

        for (const form of this._unsavedForms.values()) {
            if (form.dirty) {
                return true;
            }
        }
        return false;
    }

    public addUnsavedForm(form: UntypedFormGroup): void {

        if (this._unsavedForms.has(form)) {
            return;
        }

        this._unsavedForms.add(form);
    }

    public removeUnsavedForm(form: UntypedFormGroup): void {
        this._unsavedForms.delete(form);
    }

    public clearUnsavedFormsCount(): void {
        this._unsavedForms.clear();
    }
}
