<div class="sc-error-container">
    <div class="sc-status-code-text"
         i18n>
        {{ statusCode }}
    </div>

    <div class="sc-text-block">
        <h3>{{ title }}</h3>
        <p>{{ message }}</p>
        <p-button label="Return to the Site"
                  i18n-label
                  (onClick)="returnToSite()">
        </p-button>
    </div>
</div>