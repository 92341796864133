import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Subject, takeUntil } from 'rxjs';

import { ValidationService } from 'sc-common';
import { ICommentData } from 'sc-common/shared/components/comments-dialog/comment/comment-data';

@Component({
    selector: 'sc-comment',
    templateUrl: 'comment.component.html',
    styleUrls: ['comment.component.scss']
})
export class CommentComponent implements OnDestroy {

    @Input()
    public commentData: ICommentData;

    @Output()
    public readonly update = new EventEmitter<void>();

    @Output()
    public readonly delete = new EventEmitter<void>();

    public get isActionsButtonsVisible(): boolean {
        return this.commentData.comment.isEditable && !this.isEditMode;
    }

    public isEditMode = false;

    public form: UntypedFormGroup;

    public requestInProcess = false;

    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _formBuilder: UntypedFormBuilder) {
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public showEditForm(): void {
        this.isEditMode = true;

        this.form = this._formBuilder.group(
            {
                description: [this.commentData.comment.description, [Validators.required, ValidationService.alphaNumSymbolValidator]],
                type: this.commentData.comment.type
            });
    }

    public hideEditForm(): void {
        this.isEditMode = false;
    }

    public editComment(): void {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.requestInProcess = true;

        this.commentData.updateAction(this.commentData.comment.id, this.form.value)
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this.hideEditForm();
                this.form.reset();
                this.requestInProcess = false;
                this.update.emit();
            });
    }

    public deleteComment(): void {
        this.commentData.deleteAction(this.commentData.comment.id)
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this.delete.emit());
    }
}
