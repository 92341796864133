<ng-template #displayTemplateRef
             let-cellData
             let-rowData="rowData">
    <div class="flex flex-column flex-grow-1">
        <div *ngFor="let comment of cellData">
            <div class="caption1Font">{{ comment.date | date: 'y-m-d' }} {{ comment.commenter }}</div>
            <div class="white-space-normal">{{ comment.description }}</div>
        </div>
        <a *ngIf="!isReadonly"
           class="align-self-end"
           (click)="openComments(rowData)">
            <i class="fas fa-circle-plus"></i>
        </a>
    </div>
</ng-template>