import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'cm-welcome-block',
    templateUrl: './welcome-block.component.html'
})
export class WelcomeBlockComponent {

    @Output()
    public readonly welcomeBlockHide = new EventEmitter<void>();

    public hide(): void {
        this.welcomeBlockHide.emit();
    }
}
