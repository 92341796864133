<ng-template #displayTemplateRef
             let-cellData>
    <span [innerHTML]="cellData | safeMessage:'html'"
          scOverflowTooltip>
    </span>
</ng-template>

<ng-template #editableTemplateRef
             let-rowData="rowData">
    <textarea pInputTextarea
              [autoResize]="true"
              scValidatable
              [scValidatableTooltip]="tableColumn.settings.editable.showValidationTooltip"
              [scEditableCellValidation]="tableColumn"
              [(ngModel)]="rowData[tableColumn.field]"></textarea>
</ng-template>