<ng-template #templateRef>
    <div class="p-inputgroup">
        <p-multiSelect #multiselectRef
                       [options]="itemsSource"
                       [(ngModel)]="multiselectFilterValues"
                       [filter]="multiselectRef.options?.length > 5"
                       [showToggleAll]="false"
                       [showHeader]="false"
                       [placeholder]="placeholder"
                       (onChange)="filter()"
                       dataKey="id"
                       optionLabel="name"
                       appendTo="body"></p-multiSelect>
        <button *ngIf="isFilterApplied"
                pButton
                type="button"
                icon="pi pi-times"
                (click)="cleanFilter()"></button>
    </div>
</ng-template>
