import { Directive, OnDestroy } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ScConfirmationData } from 'sc-common/shared/confirmation/confirmation-data';

@Directive()
export abstract class BaseConfirmationDialogComponent implements OnDestroy {

    public dialogData: ScConfirmationData;

    public requestInProcess = false;

    private readonly _destroy$ = new Subject<void>();

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    constructor(dialogConfig: DynamicDialogConfig, private readonly _dialogRef: DynamicDialogRef) {
        this.dialogData = dialogConfig.data;
    }

    public accept(): void {

        if (this.dialogData.accept$) {
            this.requestInProcess = true;

            this.dialogData.accept$
                .pipe(takeUntil(this._destroy$))
                .subscribe(r => {
                    this.requestInProcess = false;

                    this.dialogData.acceptCallback?.(r);

                    this._dialogRef.close(true);
                });
        } else {

            if (this.dialogData.acceptCallback) {
                this.dialogData.acceptCallback();
            }

            this._dialogRef.close(true);
        }
    }

    public reject(): void {

        if (this.dialogData.rejectCallback) {
            this.dialogData.rejectCallback();
        }

        this._dialogRef.close(false);
    }
}
