import { ICMEnvironment } from 'content-manager/src/environments/environment.model';

import { environment as base } from './environment.base';

export const environment: ICMEnvironment = {
    ...base,
    production: true,
    apiUrl: 'https://api.theejournal.org',
    apiCmUrl: 'https://cm.theejournal.org',
    identityProviderUrl: 'https://idp.theejournal.org',
    publicBaseUrl: 'https://www.theejournal.org',
    internalBaseUrl: 'https://internal.theejournal.org',
    editingToolBaseUrl: 'https://et.theejournal.org',
    contentManagerBaseUrl: 'https://cm.theejournal.org',
    promotionBaseUrl: 'https://prm.theejournal.org'
};
