import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { MemoryStorage } from 'sc-common/core/services/client-storage/memory.storage';
import { WindowRefService } from 'sc-common/core/services/window-ref/window-ref.service';

@Injectable(
    {
        providedIn: 'root'
    })
export class ClientStorageService {

    private static readonly Prefix = 'sc_';

    private readonly _localStorage: Storage;

    private readonly _sessionStorage: Storage;

    constructor(@Inject(PLATFORM_ID) platformId: object, windowRef: WindowRefService) {
        if (isPlatformBrowser(platformId)) {
            this._localStorage = windowRef.nativeWindow.localStorage;
            this._sessionStorage = windowRef.nativeWindow.sessionStorage;
        } else {
            this._localStorage = new MemoryStorage();
            this._sessionStorage = new MemoryStorage();
        }
    }

    public getFromLocalStorage(key: string): string {
        return this._localStorage.getItem(this._getFullKey(key));
    }

    public saveToLocalStorage(key: string, value: string): void {
        this._localStorage.setItem(this._getFullKey(key), value);
    }

    public getFromSessionStorage(key: string): string {
        return this._sessionStorage.getItem(this._getFullKey(key));
    }

    public extractFromSessionStorage(key: string): string {

        const storageKey = this._getFullKey(key);

        const result = this._sessionStorage.getItem(storageKey);

        if (result !== null) {

            this._sessionStorage.removeItem(storageKey);
        }

        return result;
    }

    public saveToSessionStorage(key: string, value: string): void {
        this._sessionStorage.setItem(this._getFullKey(key), value);
    }

    public removeFromLocalStorage(key: string): void {
        this._localStorage.removeItem(this._getFullKey(key));
    }

    public clearLocalStorage(): void {
        this._localStorage.clear();
    }

    public clearSessionStorage(): void {
        this._localStorage.clear();
    }

    public removeFromSessionStorage(key: string): void {
        this._sessionStorage.removeItem(this._getFullKey(key));
    }

    private _getFullKey(key: string): string {

        return ClientStorageService.Prefix + key;
    }
}
