<div class="flex flex-column align-items-center">
    <span class="mt-3 mb-3"
          i18n>Are you sure you want to return this Project to Editors?</span>

    <div class="field field-checkbox py-3">
        <p-checkbox [binary]="true"
                    [(ngModel)]="applyChanges"
                    label="Apply Changes"
                    i18n-label>
        </p-checkbox>
    </div>

    <div class="flex align-items-center justify-content-center w-full">
        <p-button [disabled]="requestInProcess"
                  (onClick)="accept()"
                  label="Yes"
                  i18n-label
                  [loading]="requestInProcess"
                  class="mr-5">
        </p-button>
        <p-button (onClick)="reject()"
                  [disabled]="requestInProcess"
                  label="No"
                  i18n-label
                  styleClass="p-button-outlined">
        </p-button>
    </div>
</div>