import { Component } from '@angular/core';

import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';

@Component({
    templateUrl: './raw-html-renderer.component.html'
})
export class RawHtmlRendererComponent extends RendererBaseComponent {

    constructor() {
        super();
    }
}
