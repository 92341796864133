import { Component, OnDestroy } from '@angular/core';

import { Observable, shareReplay, Subject, takeUntil } from 'rxjs';

import { RendererBaseComponent, TableStateService } from 'sc-common';
import { TableColumn } from 'sc-common/shared/table/models/table-column';

@Component({
    templateUrl: 'dropdown-renderer.component.html'
})
export class DropdownRendererComponent<TModel> extends RendererBaseComponent implements OnDestroy {

    protected readonly _destroy$ = new Subject<void>();

    private _dataSource$: Observable<TModel[]>;

    public get dataSource$(): Observable<TModel[]> {

        return this._dataSource$;
    }
    public set dataSource$(value: Observable<TModel[]>) {

        this._dataSource$ = value.pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }

    public get tableColumn(): TableColumn {

        return super.tableColumn;
    }

    public set tableColumn(value: TableColumn) {

        super.tableColumn = value;

        value.bodyStyle = {
            ...value.bodyStyle,
            'padding-left': '0px',
            'padding-right': '0px'
        };
    }

    public displayExpr: (cellModel?: any) => string;

    public dataKey: string;

    public optionLabel: string;

    public optionValue: string;

    public showClear: boolean;

    public placeholder: string;

    public readonly isCustomEditor: boolean = true;

    constructor(private readonly _tableStateService: TableStateService) {
        super();
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public onSelectionChange({ value }: { value: any; }, rowData: any, initialValue: any): void {

        rowData[this.tableColumn.field] = value;

        if (this.tableColumn.settings.editable) {

            this._tableStateService.setActionStarted();

            this.tableColumn.settings.editable.callback(value, rowData)
                .pipe(takeUntil(this._destroy$))
                .subscribe({
                    next: () => {
                        this._tableStateService.setActionComplete();

                        if (this.tableColumn.settings.editable.reloadRequired) {

                            this._tableStateService.reload();
                        }
                    },
                    error: () => {

                        this._tableStateService.setActionComplete();

                        rowData[this.tableColumn.field] = initialValue;
                    },
                    complete: null
                });
        }
    }
}
