import { NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/button';

import { ErrorPageComponent } from 'sc-external/shared/components/error/error-page/error-page.component';
import { ForbiddenComponent } from 'sc-external/shared/components/error/forbidden/403-forbidden.component';
import { NotFoundComponent } from 'sc-external/shared/components/error/not-found/404-not-found.component';
import { ServerErrorComponent } from 'sc-external/shared/components/error/server-error/500-server-error.component';

@NgModule({
    declarations: [ErrorPageComponent, ForbiddenComponent, NotFoundComponent, ServerErrorComponent],
    exports: [ErrorPageComponent, ForbiddenComponent, NotFoundComponent, ServerErrorComponent],
    imports: [ButtonModule]
})
export class ErrorPagesModule { }
