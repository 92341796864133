import { Observable } from 'rxjs';

import { FileResponse } from 'sc-common'; import { RendererMetadata } from 'sc-common/shared/table/models/renderer-metadata';
import { DropdownRendererComponent } from 'sc-external/shared/table/renderers/dropdown/dropdown-renderer.component';
import { EnumTagRendererComponent } from 'sc-external/shared/table/renderers/enum-tag/enum-tag-renderer.component';
import { FileDownloadRendererComponent } from 'sc-external/shared/table/renderers/file-download/file-download-renderer.component';

export class ExternalRenderers {

    public static enumTag(enumType?: any): RendererMetadata<EnumTagRendererComponent> {
        return {
            componentType: EnumTagRendererComponent,
            initializer: inst => {
                inst.enumType = enumType;
            }
        };
    }

    public static dropdown<TModel>(
        dataSource$: Observable<TModel[]>,
        displayExpr: (cellModel?: any) => string,
        dataKey: keyof TModel,
        optionLabel: keyof TModel,
        optionValue?: keyof TModel,
        showClear?: boolean,
        placeholder?: string): RendererMetadata<DropdownRendererComponent<TModel>> {
        return {
            componentType: DropdownRendererComponent,
            initializer: inst => (
                inst.dataSource$ = dataSource$,
                inst.displayExpr = displayExpr,
                inst.dataKey = dataKey?.toString(),
                inst.optionLabel = optionLabel?.toString(),
                inst.optionValue = optionValue?.toString(),
                inst.showClear = showClear,
                inst.placeholder = placeholder)
        };
    }

    public static fileDownload(downloadFileExpr: (rowModel: any) => Observable<FileResponse>): RendererMetadata<FileDownloadRendererComponent> {

        return {
            componentType: FileDownloadRendererComponent,
            initializer: inst => {
                inst.downloadFileExpr = downloadFileExpr;
            }
        };
    }
}
