import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { SelectItem } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { SelectFilterItem } from 'sc-common/core/models/select-filter-item';
import { MultiselectFilterComponent } from 'sc-common/shared/table/header/filters/multiselect/multiselect-filter.component';
import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Component({
    templateUrl: 'group-multiselect-filter.component.html',
    styleUrls: ['group-multiselect-filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GroupMultiselectFilterComponent extends MultiselectFilterComponent implements OnInit, OnDestroy {

    public groupsLabel: string;

    public itemsLabel: string;

    public groupBy: (m: any) => any;

    public groupDisplay: (m: any) => any;

    public selectedGroups: any[] = [];

    public groupListItems: SelectItem[] = [];

    protected readonly _destroy$ = new Subject<void>();

    constructor(
        tableStateService: TableStateService,
        column: TableColumn) {

        super(tableStateService, column);

        this.matchMode = MatchMode.in;
    }

    public ngOnInit(): void {

        this.itemsSource$ = this.itemsSource$.pipe(
            tap(x => this.groupListItems = Array.from(new Set(x.map(o => this.groupBy(o))))
                .map(o => ({ label: (this.groupDisplay ? this.groupDisplay(o) : o), value: o } as SelectItem))),
            tap(() => this.selectedGroups = Array.from(new Set(this.multiselectFilterValues.map(x => this.groupBy(x))))));

        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public onSelectedGroupsChange(msRef: MultiSelect, groupId: number): void {

        const initialValues = this.multiselectFilterValues.filter(i => this.groupBy(i) !== groupId);

        const selectedValues = msRef.options
            .filter(i => this.groupBy(i) === groupId && this.selectedGroups.includes(groupId))
            .map(i => i as SelectFilterItem);

        this.multiselectFilterValues = initialValues.concat(selectedValues);

        this.filter();
    }

    public onSelectionChange(): void {

        this.selectedGroups = Array.from(new Set(this.multiselectFilterValues.map(x => this.groupBy(x))));

        this.filter();
    }

    public cleanFilter(): void {

        super.cleanFilter();

        this.selectedGroups = [];
    }

    public isNotAllGroupValuesSelected(groupItemId: number): boolean {

        const selectedItems = this.multiselectFilterValues.filter(x => this.groupBy(x) === groupItemId).length;

        return selectedItems > 0 &&
            selectedItems !== this.itemsSource.filter((x: any) => this.groupBy(x) === groupItemId).length;
    }
}
