<form [formGroup]="form">
    <div class="grid">
        <div class="col-12"
             *ngFor="let filter of filters.controls; let i = index">
            <div class="grid p-fluid formgrid">
                <div class="col-2 field"
                     *ngIf="i === 0 && filters.controls.length > 1"></div>

                <div class="col-2 field"
                     *ngIf="i === 1">
                    <span class="p-float-label">
                        <p-dropdown id="unionType"
                                    [options]="unionTypes"
                                    formControlName="unionType">
                        </p-dropdown>
                        <label for="unionType"
                               i18n>Union</label>
                    </span>
                </div>

                <div class="col-2 field"
                     *ngIf="i > 1">
                    <input pInputText
                           i18n
                           disabled="true"
                           value="{{unionTypeLabel}}" />
                </div>

                <ng-container formArrayName="filters">
                    <ng-container [formGroupName]="i">
                        <div class="col-4 field">
                            <span class="p-float-label">
                                <p-dropdown scValidatable
                                            scLabelText="Field Type"
                                            id="definition"
                                            [options]="filterDefinitions"
                                            [virtualScroll]="true"
                                            [autoDisplayFirst]="false"
                                            formControlName="definition"
                                            optionLabel="label">
                                </p-dropdown>
                                <label for="definition"
                                       class="sc-required-input"
                                       i18n>Field Type</label>
                            </span>
                        </div>
                        <div class="col-2 field">
                            <span class="p-float-label">
                                <p-dropdown scValidatable
                                            scLabelText="Filter"
                                            id="operator"
                                            [options]="getDefinition(filter)?.operators"
                                            [autoDisplayFirst]="false"
                                            formControlName="operator"
                                            optionLabel="name">
                                </p-dropdown>
                                <label for="operator"
                                       class="sc-required-input"
                                       i18n>Filter</label>
                            </span>
                        </div>

                        <div class="col-3 field"
                             [ngSwitch]="getOperator(filter)?.renderAs">

                            <ng-container *ngSwitchCase="renderAs.string">
                                <span class="p-float-label">
                                    <input scValidatable
                                           id="value"
                                           formControlName="value"
                                           pInputText
                                           type="text" />
                                    <label for="value"
                                           i18n
                                           class="sc-required-input">Value</label>
                                </span>
                            </ng-container>

                            <ng-container *ngSwitchCase="renderAs.number">
                                <span class="p-float-label">
                                    <p-inputNumber scValidatable
                                                   id="value"
                                                   formControlName="value"></p-inputNumber>
                                    <label for="value"
                                           i18n
                                           class="sc-required-input">Value</label>
                                </span>
                            </ng-container>

                            <ng-container *ngSwitchCase="renderAs.select">
                                <span class="p-float-label">
                                    <p-dropdown #dropdownRef
                                                scValidatable
                                                scLabelText="Value"
                                                id="value"
                                                [options]="getOperator(filter)?.values | async"
                                                [autoDisplayFirst]="false"
                                                [filter]="dropdownRef.options?.length > 5"
                                                formControlName="value">
                                    </p-dropdown>
                                    <label for="value"
                                           class="sc-required-input"
                                           i18n>Value</label>
                                </span>
                            </ng-container>

                            <ng-container *ngSwitchCase="renderAs.multiselect">
                                <span class="p-float-label">
                                    <p-multiSelect #multiselectRef
                                                   scValidatable
                                                   scLabelText="Value"
                                                   id="value"
                                                   [filter]="multiselectRef.options?.length > 5"
                                                   [showToggleAll]="false"
                                                   [showHeader]="false"
                                                   [options]="getOperator(filter)?.values | async"
                                                   formControlName="value">
                                    </p-multiSelect>
                                    <label for="value"
                                           class="sc-required-input"
                                           i18n>Value</label>
                                </span>
                            </ng-container>

                            <ng-container *ngSwitchCase="renderAs.dateRange">
                                <p-calendar scValidatable
                                            dateFormat="dd/mm/yy"
                                            [readonlyInput]="true"
                                            placeholder="Value"
                                            i18n-placeholder
                                            formControlName="value"
                                            selectionMode="range">
                                </p-calendar>
                            </ng-container>
                        </div>

                        <div class="col-1">
                            <p-button (onClick)="deleteFilter(i)"
                                      *ngIf="i > 0"
                                      icon="pi pi-times"
                                      styleClass="p-button-rounded">
                            </p-button>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</form>

<div class="grid grid-nogutter">
    <div class="col-12">
        <div class="grid grid-nogutter mb-4">
            <div class="col-2">
                <p-button (onClick)="addFilter()"
                          icon="pi pi-plus"
                          label="Add Filter"
                          i18n-label
                          styleClass="p-button-outlined p-button-secondary">
                </p-button>
            </div>
            <div class="col-2">
                <p-button (onClick)="clearFilters()"
                          label="Discard"
                          i18n-label
                          styleClass="p-button-text">
                </p-button>
            </div>
        </div>
    </div>
    <div class="col-12 sc-dialog-buttons">
        <p-button (onClick)="applyFilter()"
                  i18n-label
                  label="Apply Filter">
        </p-button>
        <p-button (onClick)="saveAsTemplate()"
                  [disabled]="!saveAsTemplateEnabled"
                  i18n-label
                  label="Save as Template"
                  icon="fas fa-bookmark"
                  styleClass="p-button-text">
        </p-button>
    </div>
</div>