import { Component, Inject, Input } from '@angular/core';

import { ENV_TOKEN, IEnvironment } from 'sc-common';

@Component({
    selector: 'sc-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {

    @Input()
    public statusCode: string;

    @Input()
    public title: string;

    @Input()
    public message: string;

    constructor(@Inject(ENV_TOKEN) private _env: IEnvironment) {
    }

    public returnToSite(): void {
        window.location.href = this._env.publicBaseUrl
    }
}
