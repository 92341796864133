import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ApiMemberInfo } from 'sc-common';

@Component({
    selector: 'sc-search-member',
    templateUrl: './search-member.component.html'
})
export class SearchMemberComponent {

    @Input()
    public members: ApiMemberInfo[];

    @Input()
    public emptyMessage: string;

    @Output()
    public readonly filter = new EventEmitter<string>();

    @Output()
    public readonly selectItem = new EventEmitter<ApiMemberInfo>();

    public completeMethod(event: { query: string; }): void {
        if (!event.query) {
            return;
        }

        this.filter.next(event.query);
    }

    public onSelect(member: ApiMemberInfo): void {
        this.selectItem.next(member);
    }
}
