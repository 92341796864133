import { Component } from '@angular/core';

import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';

@Component({
    templateUrl: 'expandable-renderer.component.html'
})
export class ExpandableRendererComponent extends RendererBaseComponent {

    public clickHandler: (model: any) => void;

    public isClickableExpr?: (rowModel: any) => boolean;

    constructor() {
        super();
    }

    public expandClicked(model: any): void {
        if (this.clickHandler) {
            this.clickHandler(model);
        }
    }

    public isClickable(rowModel: any): boolean {

        if (this.isClickableExpr) {
            return this.isClickableExpr(rowModel);
        }

        return true;
    }
}
