import { NgModule } from '@angular/core';

import { OverlayPanelModule } from 'primeng/overlaypanel';

import { ValidatableControlDirective } from 'sc-common/shared/validatable/validatable.directive';
import { ValidationErrorsLabelComponent } from 'sc-common/shared/validatable/validation-errors-label.component';
import { ValidationErrorsTooltipComponent } from 'sc-common/shared/validatable/validation-errors-tooltip.component';

@NgModule({
    imports: [
        OverlayPanelModule
    ],
    exports: [
        ValidatableControlDirective,
        ValidationErrorsLabelComponent,
        ValidationErrorsTooltipComponent
    ],
    declarations: [
        ValidatableControlDirective,
        ValidationErrorsLabelComponent,
        ValidationErrorsTooltipComponent
    ],
    providers: []
})
export class ValidatableModule { }
