<ng-template #templateRef>
    <div class="flex justify-content-between flex-wrap"
         *ngIf="hasContent || canAcceptRowsOrder">
        <div class="inline-flex align-items-center">

            <ng-container *ngTemplateOutlet="data.captionHeaderTemplate"></ng-container>

            <ng-container *ngTemplateOutlet="data.leftButtonsTemplate; context: buttonsContext">
            </ng-container>
            <button *ngIf="tableSettings.hasAdvancedFilters"
                    pButton
                    icon="pi pi-sliders-h"
                    class="p-button-outlined"
                    [label]="filterButtonLabel"
                    (click)="showAdvancedFilter()"></button>
            <button *ngIf="data.canClear()"
                    pButton
                    class="p-button-text"
                    label="Discard"
                    i18n-label
                    (click)="clearAll()"></button>
        </div>
        <div class="inline-flex align-items-center">
            <div *ngIf="selection.length"
                 class="font-normal"
                 i18n>Selected {{selection.length}} record{{selection.length > 1 ? 's': ''}}</div>

            <p-splitButton *ngIf="buttonsContext.pageInfo && tableSettings.export"
                           #exportBtn
                           (onClick)="exportBtn.onDropdownButtonClick($event)"
                           (onDropdownClick)="setExportButtonOptions()"
                           [model]="exportButtonOptions"
                           [disabled]="buttonsContext.pageInfo.count === 0 || buttonsContext.pageInfo.filteredCount === 0"
                           [icon]="exportInProcess ? 'pi pi-spin pi-spinner' : 'far fa-file-excel'"
                           label="Export"
                           i18n-label>
            </p-splitButton>

            <ng-container *ngIf="canAcceptRowsOrder">

                <p-button label="Save"
                          i18n-label
                          [disabled]="orderingInProcess"
                          [loading]="orderingInProcess"
                          (onClick)="onSubmitRowsOrder(true)">
                </p-button>

                <p-button label="Discard"
                          i18n-label
                          [disabled]="orderingInProcess"
                          styleClass="p-button-outlined"
                          (onClick)="onSubmitRowsOrder(false)">
                </p-button>
            </ng-container>


            <ng-container *ngTemplateOutlet="data.rightButtonsTemplate; context: buttonsContext">
            </ng-container>

            <span *ngIf="tableSettings.toggleColumns?.canToggleColumns"
                  [ngClass]="{ 'p-float-label': !toggleColumnsSettings.toggleDropdownAsLink }">
                <p-multiSelect inputId="columnsDropdown"
                               [showHeader]="!toggleColumnsSettings.toggleDropdownAsLink"
                               [options]="settingsColumns"
                               [(ngModel)]="selectedColumns"
                               (onChange)="onSelectedColumnsChanged($event)"
                               (onPanelHide)="onHideColumnMultiselect()"
                               dataKey="field"
                               optionLabel="header"
                               appendTo="body"
                               [selectedItemsLabel]="toggleColumnsSettings.selectedItemsLabel"
                               i18n-selectedItemsLabel
                               [defaultLabel]="toggleColumnsSettings.defaultLabel"
                               [styleClass]="toggleColumnsSettings.multiselectStyleClass"
                               [dropdownIcon]="toggleColumnsSettings.dropdownIcon"
                               [maxSelectedLabels]="toggleColumnsSettings.maxSelectedLabels"
                               i18n-defaultLabel>
                </p-multiSelect>
                <label *ngIf="!toggleColumnsSettings.toggleDropdownAsLink"
                       for="columnsDropdown"
                       i18n>Show/Hide columns</label>
            </span>

        </div>
    </div>
</ng-template>