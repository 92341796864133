import { Component, Type } from '@angular/core';

import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';

@Component({
    templateUrl: 'action-link-renderer.component.html'
})
export class ActionLinkRendererComponent extends RendererBaseComponent {

    public action: (rowModel: any, cellModel?: any) => void;

    // TODO implement more flexible pipe selecting mechanism
    public get isDate(): boolean {
        return (this.tableColumn.dataType as Type<any>)?.prototype === Date.prototype;
    }

    constructor() {
        super();
    }

    public invokeAction(rowModel: any, cellModel?: any): void {
        this.action(rowModel, cellModel);
    }
}
