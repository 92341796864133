import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, map, Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';

import { NotesClient } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { IProjectHeaderData } from 'content-manager/src/app/projects/project/project-layout/header/project-header-data';
import { ProjectStateService } from 'content-manager/src/app/projects/project/project-state.service';
import { DetailsLayoutBaseComponent, NoteTypeEnum, Permission, PermissionService } from 'sc-common';

@Component({
    templateUrl: './project-layout.component.html',
    providers: [ProjectStateService]
})
export class ProjectLayoutComponent extends DetailsLayoutBaseComponent implements OnDestroy {

    private readonly _destroy$ = new Subject<void>();

    public readonly headerData$: Observable<IProjectHeaderData>;

    constructor(
        public readonly route: ActivatedRoute,
        public router: Router,
        projectStateService: ProjectStateService,
        permissionService: PermissionService,
        notesClient: NotesClient) {
        super(route, router, [], true);

        permissionService.memberPermissions$
            .pipe(takeUntil(this._destroy$))
            .subscribe(permissions => {

                this.navigationTabs = [
                    {
                        label: $localize`Details`,
                        routerLink: 'details'
                    },
                    {
                        label: $localize`Chapters`,
                        routerLink: 'chapters'
                    },
                    {
                        label: $localize`Topics`,
                        routerLink: 'topics',
                        visible: permissions.includes(Permission.ContentManagerView)
                    },
                    {
                        label: $localize`Files`,
                        routerLink: 'files'
                    },
                    {
                        label: $localize`Papers`,
                        routerLink: 'papers'
                    },
                    {
                        label: $localize`Plagiarism Reports`,
                        routerLink: 'plagiarism-reports',
                        visible: permissions.includes(Permission.ContentManagerView)
                    },
                    {
                        label: $localize`Content Editors`,
                        routerLink: 'content-editors',
                        visible: permissions.includes(Permission.ContentManagerView)
                    }
                ];
            });

        this.headerData$ = projectStateService.model$
            .pipe(
                withLatestFrom(permissionService.memberPermissions$),
                map(([project, permissions]) => ({
                    projectName: project.name,
                    projectStatus: project.status,
                    commentData: permissions.includes(Permission.ContentManagerEdit)
                        ? {
                            addCommentAction: note => notesClient.create(project.id, note),
                            comments$: notesClient.list(project.id).pipe(
                                map(listItems => listItems.map(listItem => ({
                                    comment: listItem,
                                    updateAction: (commentId, model) => notesClient.update(project.id, commentId, model),
                                    deleteAction: commentId => notesClient.delete(project.id, commentId)
                                })))
                            ),
                            notesCountChanged$: new BehaviorSubject<number>(project.commentsCount),
                            noteType: NoteTypeEnum.Comment
                        }
                        : null
                } as IProjectHeaderData)));
    }

    public ngOnDestroy(): void {

        this._destroy$.next();
        this._destroy$.complete();
    }
}
