import { Component, Injector, OnDestroy, OnInit, Type } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { ScFilterCollection } from 'sc-common/core/models/filter';
import { FilterUnionType } from 'sc-common/core/models/filter-union-enum';
import { ApiNamedListItem } from 'sc-common/core/services/open-api/open-api-clients';
import { TableLocalizingService } from 'sc-common/core/services/table-localizing.service';
import { EnumMap, enumToken } from 'sc-common/core/utils/enum-map';
import { getExpressionPath } from 'sc-common/core/utils/expression-path';
import {
    AdvancedBooleanFilter,
    AdvancedDateRangeFilter,
    AdvancedFilter,
    AdvancedFilterDefinition,
    AdvancedFilterWithSourceEnum,
    AdvancedMultiSelectFilter,
    AdvancedNamedListFilter,
    AdvancedNumberFilter,
    AdvancedSelectFilter,
    AdvancedStringFilter,
    FilterOperator,
    FilterRenderAsEnum
} from 'sc-common/shared/table/advanced-filter/advanced-filter';
import { AdvancedFilterService } from 'sc-common/shared/table/advanced-filter/advanced-filter.service';
import { ColumnMetadataValue, getColumnMap, hasColumnMap } from 'sc-common/shared/table/models/column-map.decorator';
import { ColumnSettings } from 'sc-common/shared/table/models/column-settings';
import { TableSettings } from 'sc-common/shared/table/models/table-settings';

@Component(
    {
        templateUrl: 'advanced-filter.component.html'
    })
export class AdvancedFilterComponent implements OnInit, OnDestroy {
    public filterDefinitions: AdvancedFilterDefinition[];

    public form: UntypedFormGroup;

    public filters: UntypedFormArray;

    public unionTypes: SelectItem[];

    public renderAs = FilterRenderAsEnum;

    public saveAsTemplateEnabled = false;

    private _tableSettings: TableSettings<any>;

    private readonly _destroy$ = new Subject<void>();

    private readonly _advancedFilterService: AdvancedFilterService;

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _tableLocalize: TableLocalizingService,
        private readonly _dialogRef: DynamicDialogRef,
        private readonly _injector: Injector,
        dialogConfig: DynamicDialogConfig) {

        this._tableSettings = dialogConfig.data.tableSettings;
        this._advancedFilterService = dialogConfig.data.advancedFilterService;
    }

    public ngOnInit(): void {

        this.unionTypes = [
            { label: $localize`And`, value: FilterUnionType.and },
            { label: $localize`Or`, value: FilterUnionType.or }
        ];

        this.filterDefinitions = this._getFilterDefinitions();

        this._initForm();

        this._restoreState();

        this._advancedFilterService.filterCleared$
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this.clearFilters());
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public get unionTypeLabel(): string {
        const value = this.form.get('unionType').value;

        return this.unionTypes.filter(item => item.value === value)[0].label;
    }

    public getDefinition(control: AbstractControl): AdvancedFilterDefinition {

        const value = control.get('definition').value as AdvancedFilterDefinition;

        if (value != null) {
            return value;
        }

        return null;
    }

    public getOperator(control: AbstractControl): FilterOperator {

        const value = control.get('operator').value as FilterOperator;

        if (value != null) {
            return value;
        }

        return null;
    }

    public addFilter(): void {
        this.filters.push(this._convertToForm(new AdvancedFilter()));
    }

    public deleteFilter(index: number): void {
        this.filters.removeAt(index);
    }

    public clearFilters(): void {
        this.filters.clear();
        this.form.patchValue({ unionType: FilterUnionType.and });

        this.filters.push(this._convertToForm(new AdvancedFilter()));
    }

    public applyFilter(): void {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const state: ScFilterCollection = {
            unionType: this.form.value.unionType,
            filters: this.form.value.filters.map((f: AdvancedFilter) => ({
                field: f.definition.field,
                matchMode: f.operator.matchMode,
                value: f.value,
                isArray: f.definition.isArray
            }))
        };

        this._advancedFilterService.applyFilter(state, this._tableSettings);

        this._dialogRef.close();
    }

    public saveAsTemplate(): void {
        console.log('Not implemented.');
    }

    private _initForm(): void {

        this.filters = this._formBuilder.array([]);

        this.form = this._formBuilder.group(
            {
                unionType: [FilterUnionType.and],
                filters: this.filters
            });
    }

    private _restoreState(): void {
        const state = this._advancedFilterService.getFilterValue(this._tableSettings);

        if (state) {

            state.filters.forEach(f => {
                const definition = this.filterDefinitions.find(x => x.field === f.field);
                const af: AdvancedFilter = {
                    definition: definition,
                    operator: definition.operators.find(x => x.matchMode === f.matchMode),
                    value: f.value
                };

                this.filters.push(this._convertToForm(af));
            });

            this.form.patchValue({ unionType: state.unionType });

        } else {
            this.addFilter();
        }
    }

    private _convertToForm(advancedFilter: AdvancedFilter): UntypedFormGroup {

        const definitionControl = this._formBuilder.control(advancedFilter.definition, Validators.required);
        const operatorControl = this._formBuilder.control({ value: advancedFilter.operator, disabled: !advancedFilter.definition }, Validators.required);
        const valueControl = this._formBuilder.control(advancedFilter.value);

        const group = this._formBuilder.group(
            {
                definition: definitionControl,
                operator: operatorControl,
                value: valueControl
            });

        definitionControl.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                definition => {

                    if (definition) {
                        operatorControl.enable();
                    }

                    operatorControl.reset();
                    valueControl.reset();
                });

        operatorControl.valueChanges
            .pipe(filter(operator => operator != null), takeUntil(this._destroy$))
            .subscribe(
                (operator: FilterOperator) => {

                    if (operator.renderAs === FilterRenderAsEnum.empty) {
                        valueControl.setValidators([]);
                    } else {
                        valueControl.setValidators(Validators.required);
                    }

                    valueControl.updateValueAndValidity();
                });

        return group;
    }

    private _getFilterDefinitions(): AdvancedFilterDefinition[] {

        const modelPrototype = this._tableSettings.modelPrototype;

        const modelProperties: string[] = this._tableSettings.modelFields;

        const separator = '[]';

        return modelProperties
            .filter(modelField => hasColumnMap(modelPrototype, modelField))
            .map(modelField => {

                const columnSettings: ColumnSettings = this._tableSettings.columns[modelField];

                const columnMetadataValue: ColumnMetadataValue = getColumnMap(modelPrototype, modelField);

                const vType = columnMetadataValue.typeRef();

                const isEnum = (typeof vType === 'object');
                const columnDataType: Type<any> = isEnum ? String : vType as Type<any>;
                const isApiNamedItem = columnDataType.prototype === ApiNamedListItem.prototype;

                const filterKey = columnSettings?.filterExpr
                    ? `${ modelField }${ isApiNamedItem ? separator : '' }${ getExpressionPath(columnSettings.filterExpr) }`
                    : modelField;

                const label = columnSettings?.header ?? this._tableLocalize.columns[modelField] ?? modelField;

                if (columnSettings?.advancedFilter) {
                    switch (columnSettings.advancedFilter.type) {
                        case AdvancedFilterWithSourceEnum.select:
                            return new AdvancedSelectFilter(filterKey, label, this._mapSourceToSelectItems(columnSettings));
                        case AdvancedFilterWithSourceEnum.multiselect:
                            return new AdvancedMultiSelectFilter(filterKey, label, this._mapSourceToSelectItems(columnSettings));
                        default:
                            throw new Error(`Unknown filter type: ${ columnSettings.advancedFilter.type }`);
                    }
                }

                if (isEnum) {

                    const mappedEnum = this._injector.get(enumToken(vType)) as EnumMap;

                    const itemSource$ = of(Array.from(mappedEnum).map(([k, v]) => ({ label: v.label, value: k })));

                    return new AdvancedMultiSelectFilter(filterKey, label, itemSource$);
                }

                switch (columnDataType.prototype) {
                    case ApiNamedListItem.prototype:
                        return new AdvancedNamedListFilter(filterKey, label);
                    case String.prototype:
                        return new AdvancedStringFilter(filterKey, label);
                    case Number.prototype:
                        return new AdvancedNumberFilter(filterKey, label);
                    case Date.prototype:
                        return new AdvancedDateRangeFilter(filterKey, label);
                    case Boolean.prototype:
                        return new AdvancedBooleanFilter(filterKey, label);
                    default:
                        throw new Error(`Unknown filter type: ${ columnDataType }`);

                }
            });
    }

    private _mapSourceToSelectItems(columnSettings: ColumnSettings): Observable<SelectItem[]> {
        return columnSettings.advancedFilter.source$
            .pipe(
                map(source => source.map(item => ({
                    label: item.name,
                    value: item.id
                })))
            );
    }
}
