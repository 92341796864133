<ng-container *ngIf="(isReadonlyWrapper$ | async) as wrapper">
    <div class="mt-3">
        <h3 i18n>Editor File(s)</h3>
        <p-table [scTableSettings]="editorTableSettings"></p-table>
    </div>

    <div class="mt-3">
        <h3 i18n>Content Manager File(s)</h3>
        <p-table [scTableSettings]="attachmentTableSettings">
            <ng-template *ngIf="!wrapper.isReadonly"
                         pTemplate="tools-panel-left">
                <p-button label="Add File"
                          (onClick)="addFileDialog()"
                          i18n-label
                          styleClass="
                      p-button-outlined">
                </p-button>
            </ng-template>
            <ng-template *ngIf="!wrapper.isReadonly"
                         pTemplate="row-actions"
                         let-rowData>
                <p-button styleClass="p-button-rounded p-button-text"
                          icon="fas fa-trash"
                          (onClick)="onDeleteDocument(rowData)"
                          pTooltip="Click to delete document"
                          tooltipPosition="top"
                          i18n-pTooltip>
                </p-button>
            </ng-template>
        </p-table>
    </div>
</ng-container>