import { Component, OnDestroy } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { Subject, takeUntil } from 'rxjs';

import { IProjectReturnToEditorDialogData } from 'content-manager/src/app/projects/project/details/return-to-editor-dialog/project-return-to-editor-dialog-data';

@Component({
    templateUrl: './project-return-to-editor-dialog.component.html'
})
export class ProjectReturnToEditorDialogComponent implements OnDestroy {

    public requestInProcess = false;

    public applyChanges: boolean;

    private readonly _dialogData: IProjectReturnToEditorDialogData;

    private readonly _destroy$ = new Subject<void>();

    constructor(dialogConfig: DynamicDialogConfig, private readonly _dialogRef: DynamicDialogRef) {
        this._dialogData = dialogConfig.data;
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public accept(): void {
        this.requestInProcess = true;

        this._dialogData.returnAction(this.applyChanges)
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this.requestInProcess = false;

                this._dialogRef.close(true);
            });
    }

    public reject(): void {
        this._dialogRef.close(false);
    }
}
