import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { ScFilterCollection } from 'sc-common/core/models/filter';
import { TableSettings } from 'sc-common/shared/table/models/table-settings';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Injectable()
export class AdvancedFilterService {
    private readonly _filterAppliedSource$ = new Subject<void>();

    private readonly _clearFilterSource$ = new Subject<void>();

    public readonly filterApplied$ = this._filterAppliedSource$.asObservable();

    public readonly filterCleared$ = this._clearFilterSource$.asObservable();

    private _value: ScFilterCollection | null;

    constructor(private readonly _tableStateService: TableStateService) {
    }

    public applyFilter(filters: ScFilterCollection, settings: TableSettings<any>): void {
        this._value = filters;
        this._tableStateService.saveAdvancedFilter(filters, settings);
        this._filterAppliedSource$.next();
    }

    public clearFilter(settings: TableSettings<any>): void {
        this._value = null;
        this._clearFilterSource$.next();
        this._tableStateService.saveAdvancedFilter(null, settings);
    }

    public getFilterValue(settings: TableSettings<any>): ScFilterCollection | null {
        return this._value || this._tableStateService.getAdvancedFilterState(settings);
    }
}
