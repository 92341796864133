<p-table *ngIf="(tableModel$ | async) as tableModel"
         [scTableSettings]="tableModel.tableSettings">
    <ng-template pTemplate="tools-panel-left">
        <p-button *ngIf="!tableModel.hasChapters && !tableModel.isReadonly"
                  (onClick)="openProjectTopicsDialog()"
                  label="Update Topics"
                  i18n-label>
        </p-button>
    </ng-template>
    <ng-template *ngIf="tableModel.hasChapters && !tableModel.isReadonly"
                 pTemplate="row-actions"
                 let-rowData>
        <p-button styleClass="p-button-rounded p-button-text"
                  icon="fas fa-pen"
                  (onClick)="openChapterTopicsDialog(rowData)"
                  pTooltip="Click to update topics"
                  tooltipPosition="top"
                  i18n-pTooltip>
        </p-button>
    </ng-template>
</p-table>