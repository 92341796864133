import { Component } from '@angular/core';

import { ServerResponseService } from 'sc-external/shared/components/error/server-response.service';

@Component({
    templateUrl: './500-server-error.component.html'
})
export class ServerErrorComponent {

    constructor(serverResponseService: ServerResponseService) {
        serverResponseService.setStatus(500, 'Internal server error.');
    }
}
