import { Type } from '@angular/core';

const isTextPropName = 'isText';

type FilterBlankId = {
    [isTextPropName]: boolean;
};

export type SelectFilterItem = { id: FilterBlankId | number | string | boolean; name: string; };

export function createBlankFilterItem(type: Type<any> | object): SelectFilterItem {

    return {
        id: { [isTextPropName]: (typeof type !== 'object') && type.prototype === String.prototype },
        name: $localize`(blank)`
    };
}

export function checkFilterBlankValue(id: any): id is FilterBlankId {

    return id && Object.keys(id).includes(isTextPropName);
}
