import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

export type DateTimeFormat = 'dateTime' | 'date' | 'time' | 'y-m-d-t' | 'y-m-d' | string;

@Pipe({
    name: 'date',
    standalone: true
})
export class ScDatePipe extends DatePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) locale: string) {
        super(locale);
    }

    public transform(value: Date, format: DateTimeFormat = 'dateTime'): any {
        format = this._getFormat(format);
        return super.transform(value, format);
    }

    private _getFormat(format: DateTimeFormat): string {
        switch (format) {
            case 'dateTime':
                return 'dd/MM/yyyy, HH:mm:ss';
            case 'date':
                return 'dd/MM/yyyy';
            case 'time':
                return 'HH:mm';
            case 'y-m-d-t':
                return 'yyyy-MM-dd HH:mm';
            case 'y-m-d':
                return 'yyyy-MM-dd';
            default:
                return format;
        }
    }

}
