import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Message } from 'primeng/api';

import { Subject } from 'rxjs';

import { EnumLocalizingService, FileUploadErrorEnum } from 'sc-common';

@Injectable({
    providedIn: 'root'
})
export class FileUploaderService {

    constructor(
        private readonly _http: HttpClient,
        private readonly _enumLocalize: EnumLocalizingService) {
    }

    public startUpload(
        uploadUrl: string,
        formData: FormData,
        requestInProcess$: Subject<boolean>,
        progress$: Subject<number>,
        uploadErrors$: Subject<Message[]>,
        uploadResponse$?: Subject<any>): void {

        requestInProcess$.next(true);

        this._http.post(uploadUrl ?? uploadUrl, formData, { reportProgress: true, observe: 'events' })
            .subscribe({
                next: (event: HttpEvent<any>) => {
                    switch (event.type) {
                        case HttpEventType.Response:

                            if (!(event.status >= 200 && event.status < 300)) {
                                this._handleError(uploadErrors$);
                            }

                            progress$.next(0);
                            requestInProcess$.next(false);

                            if (event.body && uploadResponse$) {
                                uploadResponse$.next(event.body);
                            }

                            break;
                        case HttpEventType.UploadProgress: {
                            if (event.loaded) {
                                progress$.next(Math.round((event.loaded * 100) / event.total));
                            }
                            break;
                        }
                    }
                },
                error: (event: any) => {
                    requestInProcess$.next(false);
                    this._handleError(uploadErrors$, this._enumLocalize.getName(FileUploadErrorEnum, event.error));
                }
            });
    }

    private _handleError(uploadErrors$: Subject<Message[]>, message = $localize`Unexpected error with upload`): void {
        uploadErrors$.next([{
            severity: 'error',
            summary: message
        }]);
    }
}
