<div class="flex flex-column align-items-start">
    <div class="mt-3">
        <div class="mb-3"><span i8n>Folder Name: </span><span class="subtitle3Font">{{ dialogData.folderName }}</span></div>
        <div i18n>Exclude quotes: <span class="subtitle3Font">Off</span></div>
        <div i18n>Exclude bibliography: <span class="subtitle3Font">On</span></div>
        <div i18n>Exclude phrases: <span class="subtitle3Font">Off</span></div>
        <div i18n>Exclude small matches: <span class="subtitle3Font">Off</span></div>
        <div i18n>Exclude abstract: <span class="subtitle3Font">Off</span></div>
        <div i18n>Exclude methods and materials: <span class="subtitle3Font">Off</span></div>
    </div>

    <div class="flex align-items-center justify-content-center w-full mt-5">
        <p-button (onClick)="cancel()"
                  styleClass="p-button-outlined"
                  class="mr-5"
                  label="Cancel"
                  i18n-label>
        </p-button>

        <p-button (onClick)="checkForPlagiarism()"
                  [disabled]="requestInProcess"
                  label="Confirm and Check"
                  i18n-label>
        </p-button>
    </div>
</div>