import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ServerResponseService } from 'sc-external/shared/components/error/server-response.service';

@Component({
    templateUrl: './404-not-found.component.html'
})
export class NotFoundComponent {

    public readonly message: string;

    constructor(router: Router, serverResponseService: ServerResponseService) {

        this.message = router.getCurrentNavigation().extras?.state?.message
            ?? $localize`The resource you are looking for might have been removed, had its name changed, or is temporarily unavailable.`;

        serverResponseService.setStatus(404, this.message);
    }
}
