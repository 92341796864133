import { Component, OnDestroy } from '@angular/core';

import { DialogService } from 'primeng/dynamicdialog';

import { filter, map, Observable, of, Subject, takeUntil } from 'rxjs';

import { ApiPaperPlagiarismReportListItem, ApiProjectPlagiarismCheckStatus, ProjectPlagiarismCheckStatusEnum, PlagiarismReportsClient, PapersClient } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { IProjectPlagiarismReportCheckInfoDialogData } from 'content-manager/src/app/projects/project/plagiarism-reports/check-info-dialog/project-plagiarism-report-check-info-dialog-data';
import { ProjectPlagiarismReportCheckInfoDialogComponent } from 'content-manager/src/app/projects/project/plagiarism-reports/check-info-dialog/project-plagiarism-report-check-info-dialog.component';
import { PlagiarismReportsRenderers } from 'content-manager/src/app/projects/project/plagiarism-reports/renderers/plagiarism-reports-renderers';
import { ProjectStateService } from 'content-manager/src/app/projects/project/project-state.service';
import { BackgroundProcessEnum, BackgroundProcessService, Filters, Renderers, TableSettings } from 'sc-common';
import { ExternalRenderers } from 'sc-external/shared/table/renderers/renderers';

@Component({
    templateUrl: './project-plagiarism-reports.component.html'
})
export class ProjectPlagiarismReportsComponent implements OnDestroy {
    public readonly tableModel$: Observable<{ isReadonly: boolean; tableSettings: TableSettings<ApiPaperPlagiarismReportListItem>; }>;

    public readonly statusEnumType = ProjectPlagiarismCheckStatusEnum;

    public plagiarismCheckInProgress: boolean;

    public checkStatus: ApiProjectPlagiarismCheckStatus;

    private readonly _refreshTable$ = new Subject<void>();

    private readonly _destroy$ = new Subject<void>();

    private _projectId: number;

    constructor(
        private readonly _projectStateService: ProjectStateService,
        private readonly _plagiarismClient: PlagiarismReportsClient,
        private readonly _papersClient: PapersClient,
        private readonly _bgProcessService: BackgroundProcessService,
        private readonly _dialogService: DialogService) {

        this.tableModel$ = _projectStateService.model$
            .pipe(map(project => ({ isReadonly: project.isReadonly, tableSettings: this._initTable(project.isReadonly) })));

        this._projectStateService.model$.pipe(takeUntil(this._destroy$)).subscribe(project => this._projectId = project.id);

        this._addBackgroundProcessCallback();
        this._refreshStatus();
    }

    public checkForPlagiarism(): void {

        const dialogData: IProjectPlagiarismReportCheckInfoDialogData = {
            folderName: this.checkStatus.folderName,
            startCheck: () => this._plagiarismClient.checkForPlagiarism(this._projectId)
        };

        const dialogRef = this._dialogService.open(ProjectPlagiarismReportCheckInfoDialogComponent, {
            width: '30vw',
            header: $localize`Check for Plagiarism`,
            data: dialogData
        });

        dialogRef.onClose
            .pipe(filter(r => r), takeUntil(this._destroy$))
            .subscribe(() => this.plagiarismCheckInProgress = true);
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._refreshTable$.complete();
    }

    private _addBackgroundProcessCallback(): void {

        this._bgProcessService.onComplete(BackgroundProcessEnum.CheckProjectForPlagiarism)
            .pipe(
                filter(m => +m.arguments?.projectId === this._projectId),
                takeUntil(this._destroy$))
            .subscribe(() => {
                this.plagiarismCheckInProgress = false;
                this._refreshTable$.next();
                this._refreshStatus();
            });
    }

    private _initTable(isReadonly: boolean): TableSettings<ApiPaperPlagiarismReportListItem> {
        const yesNoList$ = of([
            { id: true, name: $localize`Yes` },
            { id: false, name: $localize`No` }
        ]);

        return TableSettings.create(ApiPaperPlagiarismReportListItem, {
            dataSource: oDataQuery => this._projectStateService.switchMap(project => this._plagiarismClient.getList(project.id, oDataQuery)),
            columnResizeMode: 'fit',
            emptyMessageText: $localize`No papers`,
            reload$: this._refreshTable$.asObservable(),
            columns: {
                paperName: {
                    renderer: Renderers.rawHtml()
                },
                checkResult: {
                    renderer: PlagiarismReportsRenderers.checkResult(() => this.checkStatus.folderPath),
                    centered: true,
                    width: '200px'
                },
                isRejected: {
                    renderer: isReadonly
                        ? Renderers.boolean(true)
                        : ExternalRenderers.dropdown(yesNoList$, (x: boolean) => x ? $localize`Yes` : $localize`No`, 'id', 'name', 'id'),
                    editable: isReadonly
                        ? null
                        : {
                            callback: (cellData, rowData) => this._projectStateService.switchMap(project =>
                                cellData
                                    ? this._papersClient.reject(project.id, rowData.id)
                                    : this._papersClient.accept(project.id, rowData.id)
                            ),
                            reloadRequired: true
                        },
                    width: '150px',
                    filter: Filters.dropdown(yesNoList$),
                    centered: true
                }
            }
        });
    }

    private _refreshStatus(): void {
        this._projectStateService.firstSwitchMap(project => this._plagiarismClient.getStatus(project.id))
            .pipe(takeUntil(this._destroy$))
            .subscribe(status => this.checkStatus = status);
    }
}
