<ng-template #displayTemplateRef
             let-cellData>
    <span scOverflowTooltip
          [tooltipDisabled]="tableColumn.settings.wrap">
        {{ getCellValue(cellData) }}
    </span>
</ng-template>

<ng-template #editableTemplateRef
             let-rowData="rowData">

    <input *ngIf="!multilineEdit"
           pInputText
           scValidatable
           [scValidatableTooltip]="tableColumn.settings.editable.showValidationTooltip"
           [scEditableCellValidation]="tableColumn"
           [(ngModel)]="rowData[tableColumn.field]">

    <textarea *ngIf="multilineEdit"
              pInputTextarea
              [autoResize]="true"
              scValidatable
              [scValidatableTooltip]="tableColumn.settings.editable.showValidationTooltip"
              [scEditableCellValidation]="tableColumn"
              [(ngModel)]="rowData[tableColumn.field]"></textarea>
</ng-template>