import { Directive, Input } from '@angular/core';

import { Button } from 'primeng/button';

@Directive(
    {
        // eslint-disable-next-line @angular-eslint/directive-selector
        selector: 'p-button[scSubmitButton][requestInProcess]'
    })
export class SubmitButtonDirective {

    @Input()
    public set requestInProcess(value: boolean) {

        if (value === true) {

            if (this._button.label) {
                this._oldLabel = this._button.label;
                this._button.label = $localize`Submitted`;
            }

            this._button.loading = true;
            this._button.disabled = true;
        }

        if (value === false) {
            if (this._oldLabel) {
                this._button.label = this._oldLabel;
            }

            this._button.loading = false;
            this._button.disabled = false;
        }
    }

    private _oldLabel: string;

    constructor(private readonly _button: Button) {
        this._button.type = 'submit';
    }
}
