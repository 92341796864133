
import { AuthorsRendererComponent } from 'content-manager/src/app/projects/project/papers/author/authors-renderer.component';
import { CommentsRendererComponent } from 'content-manager/src/app/projects/project/papers/comment/comments-renderer.component';
import { RendererMetadata } from 'sc-common';
import { ICommentsData } from 'sc-common/shared/components/comments-dialog/comments-dialog-data';

export class ProjectPapersRenderers {

    public static authors(action: (memberId: number) => void): RendererMetadata<AuthorsRendererComponent> {
        return {
            componentType: AuthorsRendererComponent,
            initializer: inst => inst.action = action

        };
    }

    public static comments(predicate: (rowData: any) => ICommentsData, isReadonly: boolean): RendererMetadata<CommentsRendererComponent> {
        return {
            componentType: CommentsRendererComponent,
            initializer: inst => {
                inst.dialogDataInitializer = predicate;
                inst.isReadonly = isReadonly;
            }
        };
    }
}
