import { Type } from '@angular/core';

import { Observable, of } from 'rxjs';

import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { SelectFilterItem } from 'sc-common/core/models/select-filter-item';
import { DateFilterComponent } from 'sc-common/shared/table/header/filters/date/date-filter.component';
import { DropdownFilterComponent } from 'sc-common/shared/table/header/filters/dropdown/dropdown-filter.component';
import { FilterBaseComponent } from 'sc-common/shared/table/header/filters/filter-base.component';
import { GroupMultiselectFilterComponent } from 'sc-common/shared/table/header/filters/group-multiselect/group-multiselect-filter.component';
import { IssnFilterComponent } from 'sc-common/shared/table/header/filters/issn-filter/issn-filter.component';
import { MultiselectFilterComponent } from 'sc-common/shared/table/header/filters/multiselect/multiselect-filter.component';
import { NumberFilterComponent } from 'sc-common/shared/table/header/filters/number/number-filter.component';
import { RangeFilterComponent } from 'sc-common/shared/table/header/filters/range/range-filter.component';
import { TextFilterComponent } from 'sc-common/shared/table/header/filters/text/text-filter.component';
import { DateFilterType } from 'sc-common/shared/table/models/date-filter-enum';
import { FilterMetadata } from 'sc-common/shared/table/models/filter-metadata';
import { TableColumn } from 'sc-common/shared/table/models/table-column';

export class Filters {

    public static date(type = DateFilterType.range): FilterMetadata<DateFilterComponent> {

        return {
            componentType: DateFilterComponent,
            initializer: inst => inst.dateFilterType = type
        };
    }

    // eslint-disable-next-line id-blacklist
    public static number(matchMode = MatchMode.equals, defaultValue?: number): FilterMetadata<NumberFilterComponent> {

        return {
            componentType: NumberFilterComponent,
            initializer: inst => {
                inst.matchMode = matchMode;
                inst.filterValue = inst.appliedFilterValue = defaultValue;
            }
        };
    }

    public static issn(matchMode = MatchMode.contains): FilterMetadata<IssnFilterComponent> {

        return {
            componentType: IssnFilterComponent,
            initializer: inst => inst.matchMode = matchMode
        };
    }

    public static text(matchMode = MatchMode.contains, defaultValue?: string): FilterMetadata<TextFilterComponent> {

        return {
            componentType: TextFilterComponent,
            initializer: inst => {
                inst.matchMode = matchMode;
                inst.filterValue = inst.appliedFilterValue = defaultValue;
            }
        };
    }

    public static dropdown(itemsSource?: Observable<SelectFilterItem[]>): FilterMetadata<DropdownFilterComponent> {

        return {
            componentType: DropdownFilterComponent,
            initializer: inst => inst.itemsSource$ = itemsSource
        };
    }

    public static multiselect(itemsSource?: Observable<SelectFilterItem[]>,
        defaultValues?: (number | string | boolean)[]): FilterMetadata<MultiselectFilterComponent> {

        return {
            componentType: MultiselectFilterComponent,
            initializer: inst => {
                inst.itemsSource$ = itemsSource;
                inst.filterValue = inst.appliedFilterValue = defaultValues;
            }
        };
    }

    public static groupMultiselect<TModel extends SelectFilterItem, TKey>(
        itemsSource: Observable<TModel[]>,
        groupBy: (m: TModel) => TKey,
        groupDisplay: (m: TKey) => any,
        groupLabel: string,
        itemsLabel: string,
        searchEnabled = true): FilterMetadata<GroupMultiselectFilterComponent> {

        return {
            componentType: GroupMultiselectFilterComponent,
            initializer: inst => {
                inst.itemsSource$ = itemsSource;
                inst.groupBy = groupBy;
                inst.groupDisplay = groupDisplay;
                inst.groupsLabel = groupLabel;
                inst.itemsLabel = itemsLabel;
                inst.searchEnabled = searchEnabled;
            }
        };
    }

    public static country<TModel extends SelectFilterItem, TKey>(
        itemsSource: Observable<TModel[]>,
        regionField: (m: TModel) => TKey,
        regionDisplay: (m: TKey) => any): FilterMetadata<GroupMultiselectFilterComponent> {

        return Filters.groupMultiselect(itemsSource, regionField, regionDisplay, $localize`Regions`, $localize`Countries`);
    }

    public static range(useBackendValues: boolean = false): FilterMetadata<RangeFilterComponent> {

        return {
            componentType: RangeFilterComponent,
            initializer: inst => inst.useBackendValues = useBackendValues
        };
    }

    // eslint-disable-next-line id-blacklist
    public static boolean(defaultValue?: boolean): FilterMetadata<DropdownFilterComponent> {

        return {
            componentType: DropdownFilterComponent,
            initializer: inst => {
                const values = [{ id: true, name: $localize`Yes` }, { id: false, name: $localize`No` }];
                inst.itemsSource$ = of(values);
                if (defaultValue !== null && defaultValue !== undefined) {
                    inst.filterValue = inst.appliedFilterValue = defaultValue;
                    inst.dropdownFilterValue = values.find(x => x.id === defaultValue);
                }
            }
        };
    }

    public static getDefault(tableColumn: TableColumn): FilterMetadata<FilterBaseComponent> {

        if (tableColumn.enumMap) {

            const itemsSource$ = of(Array.from(tableColumn.enumMap).map(([k, v]) => ({ id: k, name: v.label })));

            if (tableColumn.enumMap.size > 2
                || (tableColumn.enumMap.size === 2 && tableColumn.settings.useBlankFilterItem)) {

                return Filters.multiselect(itemsSource$);

            } else {

                return Filters.dropdown(itemsSource$);
            }
        }

        switch ((tableColumn.dataType as Type<any>)?.prototype) {

            case Number.prototype:
                return Filters.number();

            case Date.prototype:
                return Filters.date();

            case Boolean.prototype:
                return Filters.boolean();

            default:
                return Filters.text();
        }
    }
}
