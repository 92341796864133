import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ApiProjectInfo, ProjectDetailsClient } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { BaseStateService } from 'sc-common';

@Injectable()
export class ProjectStateService extends BaseStateService<ApiProjectInfo> {

    constructor(activatedRoute: ActivatedRoute, client: ProjectDetailsClient) {
        super(activatedRoute, id => client.getInfo(id));
    }
}
