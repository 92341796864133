<div class="sc-footer">
    <div class="sc-footer-content">
        <div class="sc-footer-item">
            <img width="198"
                 height="64"
                 src="assets/logo.svg">
            <div>
                <a href="https://www.linkedin.com/company/scientificnet/"
                   target="_blank">
                    <i class="fab fa-lg fa-linkedin"></i>
                </a>

                <a href="https://www.facebook.com/Scientific.Net.Ltd/"
                   target="_blank">
                    <i class="fab fa-lg fa-square-facebook"></i>
                </a>
                <a href="https://twitter.com/Scientific_Net/"
                   target="_blank">
                    <i class="fab fa-lg fa-square-twitter"></i>
                </a>
            </div>

            <div class="sc-footer-copyright caption2Font">
                <div i18n>
                    Scientific.Net is a registered brand of Trans
                    Tech Publications Ltd © {{currentYear}} by Trans
                    Tech Publications Ltd. All Rights Reserved
                </div>
            </div>
        </div>

        <div class="sc-footer-item">
            <p class="sc-footer-menu-title title4Font"
               i18n>Our Content</p>
            <a scPublicRouterLink="journal-list"
               class="subtitle3Font"
               i18n>Journal List</a>
            <a scPublicRouterLink="series-and-collections"
               class="subtitle3Font"
               i18n>Series and Collections</a>
            <a routerLink=""
               class=""
               i18n>All Books</a>
        </div>

        <div class="sc-footer-item">
            <p class="sc-footer-menu-title title4Font"
               i18n>Services</p>

            <a routerLink=""
               class="subtitle3Font"
               i18n>For Authors/Editors/Reviewers</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Publish Open Access</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Open Athens Service</a>
        </div>

        <div class="sc-footer-item">
            <p class="sc-footer-menu-title title4Font"
               i18n>To Publish</p>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Conferences</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Special Issues</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Monograph</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Submit your Paper</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Featured Conferences</a>
        </div>

        <div class="sc-footer-item">
            <p class="sc-footer-menu-title title4Font"
               i18>About</p>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Privacy Policy</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>History</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Imprint</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Insights</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Contact As</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Newsletter Registering</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Sitemap</a>
            <a routerLink=""
               class="subtitle3Font"
               i18n>Publishing Policies</a>
        </div>
    </div>
</div>