import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SelectFilterItem } from 'sc-common/core/models/select-filter-item';
import { FilterBaseComponent } from 'sc-common/shared/table/header/filters/filter-base.component';
import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Component({
    templateUrl: 'dropdown-filter.component.html'
})
export class DropdownFilterComponent extends FilterBaseComponent implements OnInit {

    public dropdownFilterValue: SelectFilterItem = null;

    public itemsSource$: Observable<SelectFilterItem[]>;

    public readonly placeholder = $localize`Select`;

    constructor(
        protected readonly tableStateService: TableStateService,
        protected readonly _tableColumn: TableColumn) {

        super(tableStateService, _tableColumn);
    }

    public ngOnInit(): void {

        if (this.itemsSource$ && this._tableColumn.settings.useBlankFilterItem) {

            this.itemsSource$ = this.itemsSource$
                .pipe(map(values => [this.filterBlankItem, ...values]));
        }

        if (this.hasFilterValue && this.itemsSource$) {

            this.itemsSource$ = this.itemsSource$
                .pipe(tap(values => this.dropdownFilterValue = values.find(v => v.id === this.filterValue)));
        }
    }

    public filter(): void {

        this.filterValue = this.dropdownFilterValue.id;

        super.filter();
    }

    public cleanFilter(): void {

        this.dropdownFilterValue = null;

        super.cleanFilter();
    }
}
