import { Component, Input, ViewEncapsulation } from '@angular/core';

import { NgxGaugeModule } from 'ngx-gauge';

@Component({
    selector: 'sc-progress-circle',
    templateUrl: './progress-circle.component.html',
    styleUrls: ['./progress-circle.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgxGaugeModule]
})
// TODO Remove if PrimeNg implements its own ProgressCircle
// docs: https://github.com/ashish-chopra/ngx-gauge
export class ProgressCircleComponent {

    @Input()
    public progress = 0;

    constructor() { }
}
