import { Component, OnDestroy } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { Subject, takeUntil } from 'rxjs';

import { IProjectPlagiarismReportCheckInfoDialogData } from 'content-manager/src/app/projects/project/plagiarism-reports/check-info-dialog/project-plagiarism-report-check-info-dialog-data';

@Component({
    selector: 'cm-project-plagiarism-report-check-info-dialog',
    templateUrl: './project-plagiarism-report-check-info-dialog.component.html'
})
export class ProjectPlagiarismReportCheckInfoDialogComponent implements OnDestroy {

    public readonly dialogData: IProjectPlagiarismReportCheckInfoDialogData;

    public requestInProcess: boolean;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        dialogConfig: DynamicDialogConfig,
        private readonly _dialogRef: DynamicDialogRef) {
        this.dialogData = dialogConfig.data;
    }

    public cancel(): void {
        this._dialogRef.close(false);
    }

    public checkForPlagiarism(): void {

        this.requestInProcess = true;

        this.dialogData.startCheck()
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this.requestInProcess = true;
                this._dialogRef.close(true);
            });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
