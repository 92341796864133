import { NgModule } from '@angular/core';

import { ExternalFooterComponent } from 'sc-external/shared/components/footer/footer.component';
import { PublicRouterLinkDirective } from 'sc-external/shared/directives/public-router-link.directive';

@NgModule({
    declarations: [ExternalFooterComponent],
    imports: [PublicRouterLinkDirective],
    exports: [ExternalFooterComponent]
})
export class ExternalFooterModule { }
