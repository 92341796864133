<ng-template #templateRef>
    <div class="p-inputgroup">
        <input type="text"
               pInputText
               [(ngModel)]="filterValue"
               [placeholder]="placeholder"
               (keyup.enter)="filter()" />
        <button *ngIf="isFilterApplied"
                pButton
                type="button"
                icon="pi pi-times"
                (click)="cleanFilter()"></button>
    </div>
</ng-template>
