/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PermissionService } from 'sc-common/core/services/identity/permission.service';

@Directive({
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {

    @Input()
    public set hasPermission(permission: number) {
        this._permission = permission;
    }

    private _permission: number;

    private readonly _destroy$ = new Subject<void>();

    private _hasView = false;

    constructor(
        private readonly _templateRef: TemplateRef<any>,
        private readonly _viewContainer: ViewContainerRef,
        private readonly _permissionService: PermissionService) {
    }

    public ngOnInit(): void {
        this._permissionService.hasPermission(this._permission)
            .pipe(takeUntil(this._destroy$))
            .subscribe(hasPermission => {
                if (!this._hasView && hasPermission) {
                    this._viewContainer.createEmbeddedView(this._templateRef);
                    this._hasView = true;
                } else if (this._hasView && !hasPermission) {

                    this._viewContainer.clear();
                    this._hasView = false;
                }
            });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}