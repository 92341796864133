import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnInit, Renderer2 } from '@angular/core';

import { DomHandler } from 'primeng/dom';
import { ReorderableRow, Table } from 'primeng/table';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Directive({ selector: '[scReorderableRow]' })
export class ScReorderableRowDirective extends ReorderableRow implements OnInit, AfterViewInit {

    /**
     * Marks row as group row
     */
    @Input()
    public isGroupRow: boolean;

    /**
     * Contains group identifier
     */
    @Input()
    public rowGroupValue: string = null;

    @Input('scReorderableRow')
    public index: number;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('scReorderableRowDisabled')
    public pReorderableRowDisabled: boolean;

    private _dragImageContainerEl: HTMLElement = null;

    private readonly _destroy$ = new Subject<void>();

    private _srcGroupId: string;

    private get _isDragOutsideGroupScope(): boolean {

        return !!this.rowGroupValue && !!this._srcGroupId && this._srcGroupId !== this.rowGroupValue;
    }

    private readonly _rowElement: HTMLTableRowElement;

    constructor(renderer: Renderer2, dt: Table, el: ElementRef, zone: NgZone, private readonly _tableStateService: TableStateService) {

        super(renderer, dt, el, zone);

        this._rowElement = el.nativeElement as HTMLTableRowElement;
    }

    public ngOnInit(): void {

        if (this.rowGroupValue) {

            let blockArea: HTMLDivElement = null;

            this._tableStateService.rowDragDrop$
                .pipe(takeUntil(this._destroy$))
                .subscribe(x => {

                    this._srcGroupId = x?.groupId;

                    const tableBodyEl: HTMLElement = this.dt.tableViewChild.nativeElement;

                    if (this._isDragOutsideGroupScope) {

                        blockArea = document.createElement('div');
                        blockArea.classList.add('sc-row-block-frame');
                        blockArea.style.position = 'absolute';
                        blockArea.style.top = tableBodyEl.offsetTop + this._rowElement.offsetTop + 'px';
                        blockArea.style.left = '0px';
                        blockArea.style.width = this._rowElement.clientWidth + 'px';
                        blockArea.style.height = this._rowElement.clientHeight + 'px';

                        DomHandler.appendChild(blockArea, tableBodyEl);

                    } else if (blockArea) {

                        DomHandler.removeChild(blockArea, tableBodyEl);

                        blockArea = null;
                    }
                });
        }
    }

    public ngAfterViewInit(): void {

        if (!this.isGroupRow) {

            super.ngAfterViewInit();
        }
    }

    public onDragStart(event: DragEvent): void {

        const dragImageContainerEl = document.createElement('div');

        dragImageContainerEl.classList.add('sc-row-drag-icon', 'fas', 'fa-sort');

        this._dragImageContainerEl = document.body.appendChild(dragImageContainerEl);

        event.dataTransfer.setDragImage(this._dragImageContainerEl, -25, 0);

        super.onDragStart(event);

        if (this.rowGroupValue) {

            this._tableStateService.dragRow({ groupId: this.rowGroupValue });
        }
    }

    public onDragOver(event: DragEvent): void {

        if (this._isDragOutsideGroupScope) {

            const rowEl = this.el.nativeElement as HTMLElement;

            rowEl.classList.toggle('p-datatable-dragpoint-top', false);

            rowEl.classList.toggle('p-datatable-dragpoint-bottom', false);

        } else {

            super.onDragOver(event);
        }

        event.preventDefault();
    }

    public onDragEnd(event: DragEvent): void {

        super.onDragEnd(event);

        if (this._dragImageContainerEl) {

            document.body.removeChild(this._dragImageContainerEl);
        }

        this._tableStateService.dragRow(null);
    }

    public onDrop(event: DragEvent): void {

        if (this.isEnabled() && this.dt.rowDragging) {

            if (this._isDragOutsideGroupScope) {

                this.onDragLeave(event);

                this.onDragEnd(event);

            } else {

                super.onDrop(event);
            }
        }

        event.preventDefault();
    }
}
