import { TemplateRef, Type } from '@angular/core';

import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { EnumMap } from 'sc-common/core/utils/enum-map';
import { ColumnSettings } from 'sc-common/shared/table/models/column-settings';

export class TableColumn {

    public isKey: boolean;

    public field: string;

    public header: string;

    public sortable: boolean;

    public sortableFieldPath: string;

    public filterTemplate?: TemplateRef<any>;

    public filterKey?: string;

    public filterValue?: any;

    public filterMatchMode?: MatchMode;

    public bodyTemplate?: TemplateRef<any>;

    public bodyEditableTemplate?: TemplateRef<any>;

    public isArray: boolean;

    public settings: ColumnSettings;

    public enumMap?: EnumMap;

    public dataType: Type<any> | object;

    public isReorderable?: boolean;

    public isGroupable?: boolean;

    public isEditable?: boolean;

    public headerStyle?: { [prop: string]: any; };

    public bodyStyle?: { [prop: string]: any; };

}
