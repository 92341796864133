<form [formGroup]="form"
      class="mt-5 body2Font"
      (ngSubmit)="createNewChapter()">
    <div i18n>
        Please choose and write one of the following forms for your chapter titles:
    </div>
    <div>
        <ul class="sc-list">
            <li i18n>Chapter 1: Chapter Name</li>
            <li i18n>I. Chapter Name</li>
        </ul>
    </div>

    <div class="field p-fluid">
        <textarea inputId="textarea"
                  scValidatable
                  pInputTextarea
                  placeholder="Write name of chapter"
                  [rows]="3"
                  formControlName="name"
                  i18n-placeholder></textarea>
    </div>

    <p-button scSubmitButton
              [requestInProcess]="requestInProcess"
              class="flex justify-content-center mt-5"
              label="Save"
              i18n-label></p-button>
</form>