import * as signalR from '@microsoft/signalr';

import { from, Observable, Subject, filter, mergeMap, firstValueFrom, retry } from 'rxjs';

import { GlobalErrorHandler } from 'sc-common/core/services/error-handler/error-handler.service';
import { IdentityService } from 'sc-common/core/services/identity/identity.service';

export abstract class SignalRBaseClientService {

    private readonly _connection: signalR.HubConnection;

    private readonly _callbackCache: {
        [callbackName: string]: Subject<any>;
    } = {};

    constructor(
        protected readonly identityService: IdentityService,
        protected readonly errorHandler: GlobalErrorHandler,
        protected readonly hubUrl: string) {

        this._connection = new signalR.HubConnectionBuilder()
            .withUrl(hubUrl,
                {
                    accessTokenFactory: () => firstValueFrom(identityService.accessToken$)
                })
            .withAutomaticReconnect()
            .build();

        this._connectionStart();
    }

    public getCallbackSource$<T>(callbackName: string): Observable<T> {

        let obs$ = this._callbackCache[callbackName];

        if (!obs$) {

            obs$ = new Subject<any>();

            this._callbackCache[callbackName] = obs$;

            const predicate = (...args: any[]): void => obs$.next(args.length ? args[0] : null);

            this._connection.on(callbackName, predicate);

            obs$.subscribe({ complete: () => this._connection.off(callbackName, predicate) });
        }

        return obs$.asObservable();
    }

    public removeCallback(callbackName: string): void {

        const obs$ = this._callbackCache[callbackName];

        if (obs$) {

            obs$.complete();

            this._callbackCache[callbackName] = undefined;
        }
    }

    private _connectionStart(): void {

        this.identityService.authenticatedUser$
            .pipe(
                filter(x => !!x),
                mergeMap(_ => from(this._connection.start())),
                retry({ count: 10, delay: 1000 })
            )
            .subscribe({
                next: _ => {
                    console.assert(this._connection.state === signalR.HubConnectionState.Connected);
                    console.log('SignalR Connected. SignalRClientService.');
                },
                error: err => {
                    console.assert(this._connection.state === signalR.HubConnectionState.Disconnected);
                    console.log(err);
                }
            });
    };
}
