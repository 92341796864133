import { Directive, TemplateRef, ViewChild } from '@angular/core';

import { TableColumn } from 'sc-common/shared/table/models/table-column';

@Directive()
export abstract class RendererBaseComponent {

    @ViewChild('displayTemplateRef')
    public readonly displayTemplateRef: TemplateRef<any>;

    @ViewChild('editableTemplateRef')
    public readonly editableTemplateRef?: TemplateRef<any>;

    public dataExpr?: (model: any) => any;

    private _tableColumn: TableColumn;

    public get tableColumn(): TableColumn {

        return this._tableColumn;
    }
    public set tableColumn(value: TableColumn) {

        this._tableColumn = value;
    }

    public readonly isCustomEditor: boolean = false;

    protected constructor() {
    }

    public checkHasValue(value: any): boolean {
        return value !== undefined && value !== null;
    }

    public getCellValue(value: any): any {

        return this.checkHasValue(value) && this.dataExpr
            ? this.dataExpr(value)
            : value;
    }
}
