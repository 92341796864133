<div *ngFor="let item of menuItems; let last = last; let i = index;"
     class="sc-progress-menu-item"
     [ngClass]="{ 'sc-item-disabled': !item.enabled }">
    <div *ngIf="showCheckmarks"
         class="sc-progress-menu-item-separator">
        <span class="sc-item-marker"
              [routerLink]="item.routerLink"
              [ngClass]="{ 'sc-item-marker-inactive': !item.completed, 'fa-stack': !item.completed && item.active }">
            <i [ngClass]="{
                  'fas fa-check-circle': item.completed,
                  'far': !item.completed,
                  'fa-circle': !item.completed && !item.active,
                  'fa-dot-circle fa-stack-1x sc-active-incomplete-step': !item.completed && item.active }"></i>
            <i *ngIf="!item.completed && item.active"
               class="far fa-circle fa-stack-1x"></i>
        </span>

        <div *ngIf="!last"
             class="sc-progress-menu-connector"
             [ngClass]="{ 'sc-progress-menu-connector-inactive': !item.completed || (!menuItems[i + 1]?.completed && !menuItems[i + 1]?.active) }">
        </div>
    </div>

    <div class="sc-progress-menu-item-content">
        <a [routerLink]="item.routerLink"
           [ngClass]="{ 'sc-active-item': item.active, 'sc-item-without-checkmarks': !showCheckmarks }">{{item.label}}</a>
    </div>
</div>