import { Type } from '@angular/core';

import { DateTimeFormat } from 'sc-common/shared/pipes/date.pipe';
import { ActionLinkRendererComponent } from 'sc-common/shared/table/body/renderers/action-link/action-link-renderer.component';
import { BooleanRendererComponent } from 'sc-common/shared/table/body/renderers/boolean/boolean-renderer.component';
import { DateRendererComponent } from 'sc-common/shared/table/body/renderers/date/date-renderer.component';
import { ExpandableRendererComponent } from 'sc-common/shared/table/body/renderers/expandable/expandable-renderer.component';
import { IconActionRendererComponent } from 'sc-common/shared/table/body/renderers/icon-action/icon-action-renderer.component';
import { ILinkRendererFilterData } from 'sc-common/shared/table/body/renderers/link/link-renderer-filter-data';
import { LinkRendererComponent } from 'sc-common/shared/table/body/renderers/link/link-renderer.component';
import { NumberRendererComponent } from 'sc-common/shared/table/body/renderers/number/number-renderer.component';
import { RadioButtonRendererComponent } from 'sc-common/shared/table/body/renderers/radio-button/radio-button-renderer.component';
import { RawHtmlRendererComponent } from 'sc-common/shared/table/body/renderers/raw-html/raw-html-renderer.component';
import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';
import { TagRendererComponent } from 'sc-common/shared/table/body/renderers/tag/tag-renderer.component';
import { TextRendererComponent, TextRendererPipe } from 'sc-common/shared/table/body/renderers/text/text-renderer.component';
import { RendererMetadata } from 'sc-common/shared/table/models/renderer-metadata';
import { TableColumn } from 'sc-common/shared/table/models/table-column';

export class Renderers {

    public static date(format?: DateTimeFormat): RendererMetadata<DateRendererComponent> {

        return {
            componentType: DateRendererComponent,
            initializer: inst => {
                inst.format = format;
            }
        };
    }

    public static text(pipeName?: TextRendererPipe, pipeArgs: any[] = [], multilineEdit = false): RendererMetadata<TextRendererComponent> {

        return {
            componentType: TextRendererComponent, initializer: inst => {
                inst.pipeInfo = { name: pipeName, args: pipeArgs };
                inst.multilineEdit = multilineEdit;
            }
        };
    }

    public static rawHtml(): RendererMetadata<RawHtmlRendererComponent> {
        return {
            componentType: RawHtmlRendererComponent
        };
    }

    // eslint-disable-next-line id-blacklist
    public static number(_format?: string): RendererMetadata<NumberRendererComponent> {

        return { componentType: NumberRendererComponent };
    }

    public static link(
        linkExpr: (rowModel: any, cellModel?: any) => string | any[],
        isClickableExpr?: (rowModel: any) => boolean,
        filterData?: ILinkRendererFilterData,
        hasIcon?: boolean): RendererMetadata<LinkRendererComponent> {

        return {
            componentType: LinkRendererComponent,
            initializer: inst => {
                inst.linkExpr = linkExpr;
                inst.isClickableExpr = isClickableExpr;
                inst.filterData = filterData;
                inst.hasIcon = hasIcon;
            }
        };
    }

    public static iconAction(
        action: (rowModel: any, cellModel?: any) => void,
        iconClass: string): RendererMetadata<IconActionRendererComponent> {

        return {
            componentType: IconActionRendererComponent,
            initializer: inst => {
                inst.action = action;
                inst.iconClass = iconClass;
            }
        };
    }

    public static externalLink(
        linkExpr: (rowModel: any, cellModel?: any) => string,
        hasIcon?: boolean): RendererMetadata<LinkRendererComponent> {

        return {
            componentType: LinkRendererComponent,
            initializer: inst => {
                inst.linkExpr = linkExpr;
                inst.external = true;
                inst.hasIcon = hasIcon;
            }
        };
    }

    public static actionLink(action: (rowModel: any, cellModel?: any) => void): RendererMetadata<ActionLinkRendererComponent> {

        return {
            componentType: ActionLinkRendererComponent,
            initializer: inst => {
                inst.action = action;
            }
        };
    }

    public static tag(enumType?: any): RendererMetadata<TagRendererComponent> {

        return {
            componentType: TagRendererComponent,
            initializer: inst => {
                inst.enumType = enumType;
            }
        };
    }

    // eslint-disable-next-line id-blacklist
    public static boolean(renderAsText = false): RendererMetadata<BooleanRendererComponent> {

        return {
            componentType: BooleanRendererComponent,
            initializer: inst => {
                inst.renderAsText = renderAsText;
            }
        };
    }

    public static expandable(clickHandler?: (model: any) => void,
        isClickableExpr?: (model: any) => boolean): RendererMetadata<ExpandableRendererComponent> {
        return {
            componentType: ExpandableRendererComponent,
            initializer: inst => {
                inst.clickHandler = clickHandler;
                inst.isClickableExpr = isClickableExpr;
            }
        };
    }

    public static radioButton(): RendererMetadata<RadioButtonRendererComponent> {

        return {
            componentType: RadioButtonRendererComponent
        };
    }

    public static getDefault(tableColumn: TableColumn): RendererMetadata<RendererBaseComponent> {

        if (tableColumn.enumMap) {

            return Renderers.tag();
        }

        switch ((tableColumn.dataType as Type<any>)?.prototype) {

            case Date.prototype:
                return Renderers.date();

            case Number.prototype:
                return Renderers.number();

            case Boolean.prototype:
                return Renderers.boolean();

            default:
                return Renderers.text();
        }
    }
}
