import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';

import { DialogService } from 'primeng/dynamicdialog';

import { filter, map, Observable, Subject, takeUntil } from 'rxjs';

import { ApiProject, ApiProjectCreateRequest, ApiProjectListItem, ProjectDetailsClient, ProjectsClient, ProjectStatusEnum } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { MemberStateService } from 'content-manager/src/app/projects/member-state.service';
import { ICreateProjectDialogData } from 'content-manager/src/app/projects/project/create-project-dialog/create-project-dialog-data';
import { CreateProjectDialogComponent } from 'content-manager/src/app/projects/project/create-project-dialog/create-project-dialog.component';
import { FormAnimations, Permission, PermissionService, Renderers, ScConfirmationService, TableSettings } from 'sc-common';
import { ExternalRenderers } from 'sc-external/shared/table/renderers/renderers';

@Component({
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss'],
    animations: [FormAnimations.AnimateDisplay],
    encapsulation: ViewEncapsulation.None,
    providers: [MemberStateService]
})
export class ProjectListComponent implements OnDestroy {

    private readonly _refreshTable$ = new Subject<void>();

    private readonly _destroy$ = new Subject<void>();

    private _tableSettings: TableSettings<ApiProjectListItem>;

    public readonly inWorkStatus = ProjectStatusEnum.InWork;

    public readonly tablePermission$: Observable<{ isManager: boolean; }>;

    public welcomeBlockVisible$: Observable<boolean>;

    public get tableSettings(): TableSettings<ApiProjectListItem> {

        return this._tableSettings ??= this._initTable();
    }

    constructor(
        private readonly _projectsClient: ProjectsClient,
        private readonly _projectDetailsClient: ProjectDetailsClient,
        private readonly _dialogService: DialogService,
        private readonly _confirmationService: ScConfirmationService,
        private readonly _memberStateService: MemberStateService,
        permissionService: PermissionService) {

        this.tablePermission$ = permissionService.memberPermissions$
            .pipe(map(permissions => ({ isManager: permissions.includes(Permission.ContentManagerView) })));

        this.welcomeBlockVisible$ = _memberStateService.model$.pipe(map(model => !model.welcomeBlockHidden));
    }

    public onWelcomeBlockHide(): void {
        this._memberStateService.closeWelcomeBlock();
    }

    public newProjectDialog(): void {
        const projectIds$ = this._projectsClient.getIdList().pipe(takeUntil(this._destroy$));

        const dialogData: ICreateProjectDialogData = {
            isCreate: true,
            excludedTitleIdArray$: projectIds$,
            createProject: (request: ApiProjectCreateRequest) => this._projectsClient.create(request)
        };

        const dialogRef = this._dialogService.open(CreateProjectDialogComponent, {
            width: '35vw',
            header: $localize`Create Project`,
            data: dialogData
        });

        dialogRef.onClose.pipe(filter(result => !!result), takeUntil(this._destroy$))
            .subscribe(() => this._refreshTable$.next());
    }

    public updateProjectDialog(model: ApiProjectListItem): void {
        const dialogData: ICreateProjectDialogData = {
            model: model,
            isCreate: false,
            updateProject: (projectId: number, updateModel: ApiProject) => this._projectDetailsClient.update(projectId, updateModel)
        };

        const dialogRef = this._dialogService.open(CreateProjectDialogComponent, {
            width: '35vw',
            header: $localize`Rename Project`,
            data: dialogData
        });

        dialogRef.onClose.pipe(filter(result => !!result), takeUntil(this._destroy$))
            .subscribe(() => this._refreshTable$.next());
    }

    public remove(projectId: number): void {
        this._confirmationService.confirm({
            width: '30vw',
            title: $localize`Delete Project`,
            message: $localize`Are you sure to delete this project?`,
            acceptLabel: $localize`Delete`,
            accept$: this._projectDetailsClient.delete(projectId),
            acceptCallback: () => this._refreshTable$.next()
        });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._refreshTable$.complete();
    }

    private _initTable(): TableSettings<ApiProjectListItem> {

        return TableSettings.create(ApiProjectListItem, {
            dataSource: oDataQuery => this._projectsClient.getList(oDataQuery),
            columnResizeMode: 'fit',
            actionsCount: 2,
            emptyMessageText: $localize`No project`,
            rowsPerPageOptions: [10, 25, 50, 100, 150],
            rows: 100,
            reload$: this._refreshTable$.asObservable(),
            defaultSort: { field: x => x.createdDateTime, order: 'desc' },
            columns: {
                id: {
                    hidden: true
                },
                projectName: {
                    header: $localize`Project Name`,
                    renderer: Renderers.link((row: ApiProjectListItem, _) => [row.id, 'details'])
                },
                titleIsbn: {
                    width: '300px',
                    header: $localize`ISBN`,
                    sortable: false
                },
                status: {
                    width: '200px',
                    header: $localize`Status`,
                    renderer: ExternalRenderers.enumTag()
                },
                createdDateTime: {
                    width: '200px',
                    header: $localize`Date Created`
                }
            }
        });
    }
}
