<ng-template #templateRef
             let-rowData
             let-rowIndex="rowIndex"
             let-columns="columns">
    <tr class="sc-table-groupable-row"
        *ngIf="canApplyGrouping && checkHasRowSpan(rowIndex)"
        [scReorderableRow]="rowIndex"
        [isGroupRow]="true"
        [rowGroupValue]="getGroupId(rowData)">
        <td *ngIf="tableSettings.selectionMode"
            class="sc-selectable-column">
        </td>

        <ng-container *ngFor="let column of columns; trackBy: trackByFunc;">

            <td *ngIf="column.isGroupable; else emptyCell"
                [attr.rowspan]="getRowSpanCount(rowIndex)"
                class="p-resizable-column">
                <ng-container *ngIf="column.bodyTemplate; else defaultCellDataTpl">
                    <ng-container
                                  *ngTemplateOutlet="column.bodyTemplate; context: {$implicit: rowData[column.field], rowData: rowData}">
                    </ng-container>
                </ng-container>
                <ng-template #defaultCellDataTpl>
                </ng-template>
            </td>
            <ng-template #emptyCell>
                <td></td>
            </ng-template>
        </ng-container>

        <td *ngIf="actionsTemplate"
            class="sc-actions-column sc-actions-column-{{tableSettings.actionsCount}}">
        </td>
    </tr>

    <tr [scReorderableRow]="rowIndex"
        [rowGroupValue]="getGroupId(rowData)"
        [scReorderableRowDisabled]="!canReorderRows"
        (dblclick)="tableSettings.doubleClickRedirectUrl ? navigate(tableSettings.doubleClickRedirectUrl(rowData)) : null"
        [ngStyle]="tableSettings.rowStyler ? tableSettings.rowStyler(rowData): null">
        <td *ngIf="tableSettings.selectionMode"
            class="sc-selectable-column">
            <ng-container [ngSwitch]="tableSettings.selectionMode">
                <p-tableRadioButton *ngSwitchCase="'single'"
                                    [value]="rowData"
                                    (dblclick)="preventMouseEvent($event)"></p-tableRadioButton>

                <p-tableCheckbox *ngSwitchCase="'multiple'"
                                 [value]="rowData"
                                 (dblclick)="preventMouseEvent($event)"></p-tableCheckbox>
            </ng-container>
        </td>

        <td *ngIf="tableSettings.reordering?.separateColumn"
            class="sc-reordering-column">
            <i pReorderableRowHandle
               class="pi pi-bars"></i>
        </td>

        <ng-container *ngFor="let column of columns; trackBy: trackByFunc; ">
            <ng-container *ngIf="column.isEditable; else nonEditable">
                <td class="p-resizable-column"
                    *ngIf="checkHasNoGrouping(column.isGroupable, rowIndex)"
                    scEditableColumn
                    #editableColumn="scEditableColumn"
                    [pEditableColumn]="rowData"
                    [pEditableColumnField]="column.field"
                    [pEditableColumnRowIndex]="rowIndex"
                    [ngClass]="{ 'sc-td-white-space-normal': column.settings.wrap }"
                    [ngStyle]="column.bodyStyle"
                    [column]="column">
                    <p-cellEditor class="sc-overflow-ellipsis">

                        <ng-container *ngIf="!column.settings.editable.showIcon">

                            <ng-template pTemplate="input">
                                <div class="relative">
                                    <ng-container
                                                  *ngTemplateOutlet="column.bodyEditableTemplate; context: {$implicit: rowData[column.field], rowData: rowData, rowIndex: rowIndex }">
                                    </ng-container>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <ng-container
                                              *ngTemplateOutlet="column.bodyTemplate; context: {$implicit: rowData[column.field], rowData: rowData, rowIndex: rowIndex}">
                                </ng-container>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngIf="column.settings.editable.showIcon">
                            <ng-template pTemplate="input">
                                <ng-container
                                              *ngTemplateOutlet="column.bodyEditableTemplate; context: {$implicit: rowData[column.field], rowData: rowData, rowIndex: rowIndex }">
                                </ng-container>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <div class="flex align-items-center sc-overflow-ellipsis">
                                    <i class="fas fa-pen mr-3"></i>

                                    <div class="flex-grow-1 relative sc-overflow-ellipsis">
                                        <ng-container
                                                      *ngTemplateOutlet="column.bodyTemplate; context: {$implicit: rowData[column.field], rowData: rowData, rowIndex: rowIndex}">
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </p-cellEditor>

                    <p-button *ngIf="editableColumn.canSwapValues"
                              [disabled]="editableColumn.swapButtonDisabled"
                              pTooltip="Click to swap values"
                              i18n-pTooltip
                              tooltipPosition="top"
                              styleClass="p-button-rounded"
                              icon="fas fa-exchange-alt">
                    </p-button>
                </td>
            </ng-container>

            <ng-template #nonEditable>
                <td class="p-resizable-column"
                    *ngIf="checkHasNoGrouping(column.isGroupable, rowIndex)"
                    [ngClass]="{ 'sc-row-reorder-column': column.isReorderable, 'justify-content-center': column.settings.centered, 'sc-td-white-space-normal': column.settings.wrap }"
                    [ngStyle]="column.bodyStyle">
                    <span *ngIf="column.isReorderable"
                          pReorderableRowHandle
                          class="p-element"
                          [ngClass]="{ 'pi pi-bars' : !tableSettings.reordering?.separateColumn }"></span>
                    <ng-container *ngIf="column.bodyTemplate; else defaultCellDataTpl">
                        <ng-container
                                      *ngTemplateOutlet="column.bodyTemplate; context: {$implicit: rowData[column.field], rowData: rowData, rowIndex: rowIndex}">
                        </ng-container>
                    </ng-container>
                </td>
            </ng-template>
            <ng-template #defaultCellDataTpl>
                {{rowData[column.field]}}
            </ng-template>
        </ng-container>

        <td *ngIf="actionsTemplate"
            class="sc-actions-column sc-actions-column-{{tableSettings.actionsCount}}">
            <ng-container *ngTemplateOutlet="actionsTemplate; context: {$implicit: rowData, rowIndex: rowIndex}">
            </ng-container>
        </td>
    </tr>
</ng-template>