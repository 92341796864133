import { Directive, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { cloneDeep } from 'lodash-es';

import { DialogService } from 'primeng/dynamicdialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ScDialogService } from 'sc-common';
import { ValidationService } from 'sc-common/core/services/input-validation/validation.service';
import { UnsavedFormStateService } from 'sc-common/shared/services/unsaved-form-state.service';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[formGroup]',
    host: { '(submit)': 'onSubmit()' },
    providers: [{ provide: DialogService, useExisting: ScDialogService }]
})
export class ScFormGroupDirective implements OnInit, OnDestroy {

    private _initialData: any;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _unsavedFormStateService: UnsavedFormStateService,
        @Optional() @SkipSelf() private readonly _parentForm: FormGroupDirective,
        private readonly _formGroupDirective: FormGroupDirective,
        private readonly _dialogService: DialogService) {
    }

    public ngOnDestroy(): void {
        this._dialogService.dialogComponentRefMap.forEach(entry => {
            entry.onDestroy(() => {
                this._unsavedFormStateService.removeUnsavedForm(this._formGroupDirective.form);
            });
        });
        this._destroy$.next();
        this._destroy$.complete();
    }

    public onSubmit(): void {

        const form = this._formGroupDirective.form;

        if (form.valid) {
            this._unsavedFormStateService.removeUnsavedForm(this._formGroupDirective.form);
            this._initialData = form.value;
        }
    }

    public ngOnInit(): void {

        const form = this._formGroupDirective.form;

        this._initialData = cloneDeep(form.value);

        this._formGroupDirective.form.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((newValue: any) => {

                if (form.pristine && form.value && ValidationService.isFormChanged(this._initialData, newValue)) {
                    this._initialData = cloneDeep(form.value);
                }
                else {
                    if (ValidationService.isFormChanged(this._initialData, newValue)) {

                        this._unsavedFormStateService.addUnsavedForm(this._parentForm?.form ?? form);

                        if (this._parentForm) {
                            this._parentForm.form.markAsDirty();
                        }
                    }
                    else {
                        this._unsavedFormStateService.removeUnsavedForm(form);
                    }
                }
            });
    }
}
