import { ProjectStatusEnum } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { buildEnumMapProvider } from 'sc-common';

export const projectStatusEnumMapProvider = buildEnumMapProvider(ProjectStatusEnum, getEnumText, getStatusStyle);

export function getEnumText(value: ProjectStatusEnum): string {

    switch (value) {

        case ProjectStatusEnum.InWork:
            return $localize`In Work`;
        case ProjectStatusEnum.Canceled:
            return $localize`Canceled`;
        case ProjectStatusEnum.Completed:
            return $localize`Completed`;
        case ProjectStatusEnum.Returned:
            return $localize`Returned`;
    }
}

export function getStatusStyle(status: ProjectStatusEnum): string {
    switch (status) {
        case ProjectStatusEnum.Completed:
            return 'cm-project-status-completed';

        case ProjectStatusEnum.InWork:
            return 'cm-project-status-in-work';

        case ProjectStatusEnum.Returned:
            return 'cm-project-status-returned';

        case ProjectStatusEnum.Canceled:
            return 'cm-project-status-canceled';

        default: return '';
    }
}
