import { Component, Input, OnDestroy } from '@angular/core';

import { finalize, first, Observable, Subject, takeUntil } from 'rxjs';

import { ApiFileInfo, FileUtils, FileResponse } from 'sc-common';

@Component({
    selector: 'sc-file-download',
    templateUrl: './file-download.component.html',
    styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent implements OnDestroy {

    @Input()
    public fileInfo: ApiFileInfo;

    @Input()
    public download$: Observable<FileResponse>;

    @Input()
    public disabled = false;

    public requestInProcess = false;

    private readonly _destroy$ = new Subject<void>();

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public get fileIconClass(): string {
        return FileUtils.getFileIconClass(this.fileInfo?.originalName, 1.5);
    }

    public download(): void {
        if (this.requestInProcess || this.disabled) {
            return;
        }

        this.requestInProcess = true;
        this.download$
            .pipe(
                first(),
                finalize(() => { this.requestInProcess = false; }),
                takeUntil(this._destroy$)
            )
            .subscribe((file: FileResponse) => {
                FileUtils.save(file);
            });
    }
}
