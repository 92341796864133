<div class="flex flex-column align-items-center">
    <span class="title3Font mb-2">{{dialogData.title}}</span>
    <span class="mb-5">{{dialogData.message}}</span>

    <div class="et-confirmation-dialog-buttons flex align-items-center justify-content-center w-full">
        <p-button [disabled]="requestInProcess"
                  (onClick)="accept()"
                  [label]="dialogData.acceptLabel"
                  [loading]="requestInProcess"
                  class="mr-5 et-confirm-button">
        </p-button>
        <p-button *ngIf="dialogData.rejectVisible"
                  (onClick)="reject()"
                  [disabled]="requestInProcess"
                  [label]="dialogData.rejectLabel"
                  styleClass="p-button-outlined">
        </p-button>
    </div>
</div>