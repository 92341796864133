import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { ODataService } from 'sc-common/core/services/odata/odata.service';
import { FilterBaseComponent } from 'sc-common/shared/table/header/filters/filter-base.component';
import { FilterLimits } from 'sc-common/shared/table/models/filter-limits';
import { PageResult } from 'sc-common/shared/table/models/page-result';
import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableSettings } from 'sc-common/shared/table/models/table-settings';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Component({
    templateUrl: 'range-filter.component.html'
})
export class RangeFilterComponent extends FilterBaseComponent implements OnInit, OnDestroy {

    public limits: FilterLimits = { min: 0, max: 100 };

    public rangeFilterValue: number[];

    public useBackendValues = true;

    private readonly _destroy$ = new Subject<void>();

    public get hasSameLimits(): boolean {

        return this.limits.min === this.limits.max;
    }

    constructor(protected readonly _tableStateService: TableStateService,
        private readonly _tableSettings: TableSettings<any>,
        private readonly _odataService: ODataService,
        tableColumn: TableColumn) {

        super(_tableStateService, tableColumn);

        this.rangeFilterValue = [this.limits.min, this.limits.max];

        this.matchMode = MatchMode.between;
    }

    public ngOnInit(): void {

        const restoreValue = this.appliedFilterValue as number[];

        if (this.useBackendValues) {

            const limitsQuery = this._odataService.buildAggregateLimitsQuery(this.columnName);

            this._tableSettings.dataSource(limitsQuery).pipe(
                map((x: PageResult<FilterLimits>) => x.selectItems[0]),
                filter(x => !!x),
                takeUntil(this._destroy$))
                .subscribe(x => {

                    this.limits = x;

                    this.rangeFilterValue = restoreValue ? [...restoreValue] : [x.min, x.max];
                });

        } else if (restoreValue) {

            this.rangeFilterValue = [...restoreValue];
        }
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public filter(): void {

        if (this.rangeFilterValue[0] === this.limits.min && this.rangeFilterValue[1] === this.limits.max) {

            this.cleanFilter();

        } else {

            this.filterValue = this.rangeFilterValue;

            super.filter();
        }
    }

    public cleanFilter(): void {

        this.filterValue = null;

        this.rangeFilterValue = [this.limits.min, this.limits.max];

        super.cleanFilter();
    }
}
