import { Component, Input } from '@angular/core';

import { DialogService } from 'primeng/dynamicdialog';

import { ProjectStatusEnum } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { IProjectHeaderData } from 'content-manager/src/app/projects/project/project-layout/header/project-header-data';
import { CommentsDialogComponent } from 'sc-common/shared/components/comments-dialog/comments-dialog.component';

@Component({
    selector: 'cm-project-header',
    templateUrl: './project-header.component.html',
    styleUrls: ['project-header.component.scss']
})
export class ProjectHeaderComponent {

    @Input()
    public data: IProjectHeaderData;

    public projectStatusEnumType = ProjectStatusEnum;

    constructor(private readonly _dialogService: DialogService) { }

    public openComments(): void {
        this._dialogService.open(CommentsDialogComponent, {
            header: $localize` Comments. Communication between all content managers`,
            width: '50vw',
            data: this.data.commentData
        });
    }
}
