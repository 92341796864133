import { Component } from '@angular/core';

import { MenuItem } from 'primeng/api/menuitem';

@Component({
    selector: 'sc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public title = 'content-manager';

    public readonly mainMenu: MenuItem = {
        label: $localize`projects`,
        routerLink: ['/projects']
    };
}
