import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IPermissionTokenProvider } from 'sc-common/core/services/identity/permission.service';
import { PermissionsClient } from 'sc-common/core/services/open-api/main-api-client';

@Injectable()
export class DefaultPermissionTokenProvider implements IPermissionTokenProvider {

    constructor(private readonly _client: PermissionsClient) {
    }

    public getPermissionsToken(): Observable<string> {
        return this._client.getPermissionsToken();
    }
}