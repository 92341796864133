import { buildEnumMapProvider, NoteTypeEnum } from 'sc-common';

export const noteTypeEnumMap = buildEnumMapProvider(NoteTypeEnum, getEnumText);

export function getEnumText(value: NoteTypeEnum): string {

    switch (value) {

        case NoteTypeEnum.None:
            return '';

        case NoteTypeEnum.Comment:
            return $localize`Comment`;

        case NoteTypeEnum.Subscription:
            return $localize`Subscription`;

        case NoteTypeEnum.DoublePr:
            return $localize`Double PR`;

        case NoteTypeEnum.TriplePr:
            return $localize`Triple PR`;

        case NoteTypeEnum.EditorComment:
            return $localize`Editor`;

        case NoteTypeEnum.AuthorComment:
            return $localize`Author`;
    }
}
