import { Injectable } from "@angular/core";
import { ILogger } from "oidc-client-ts";

@Injectable()
export class IdentityLoggerService implements ILogger {

    public debug(...args: unknown[]): void {
        console.debug(args);
    }

    public info(...args: unknown[]): void {
        console.info(args);
    }

    public warn(...args: unknown[]): void {
        console.warn(args);
    }

    public error(...args: unknown[]): void {
        console.error(args);
    }
}
