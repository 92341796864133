import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safeMessage',
    standalone: true
})
export class SafeMessagePipe implements PipeTransform {
    constructor(private readonly _sanitizer: DomSanitizer) { }

    public transform(value: any, args: any): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {

        switch (args) {
            case 'html': return this._sanitizer.bypassSecurityTrustHtml(value);
            case 'style': return this._sanitizer.bypassSecurityTrustStyle(value);
            case 'script': return this._sanitizer.bypassSecurityTrustScript(value);
            case 'url': return this._sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl': return this._sanitizer.bypassSecurityTrustResourceUrl(value);
            default: throw new Error(`Invalid safe type ${ args } specified`);
        }
    }
}
