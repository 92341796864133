import { Component, OnDestroy } from '@angular/core';

import { DialogService } from 'primeng/dynamicdialog';

import { filter, map, Observable, Subject, takeUntil } from 'rxjs';

import { ApiProjectDocument, FilesClient } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { IAddFileDialogData } from 'content-manager/src/app/projects/project/files/add-file-dialog/add-file-dialog-data';
import { AddFileDialogComponent } from 'content-manager/src/app/projects/project/files/add-file-dialog/add-file-dialog.component';
import { ProjectStateService } from 'content-manager/src/app/projects/project/project-state.service';
import { FileParameter, ScConfirmationService, TableSettings } from 'sc-common';
import { ExternalRenderers } from 'sc-external/shared/table/renderers/renderers';

@Component({
    templateUrl: './project-files.component.html'
})
export class ProjectFilesComponent implements OnDestroy {

    public readonly editorTableSettings: TableSettings<ApiProjectDocument>;

    public readonly attachmentTableSettings: TableSettings<ApiProjectDocument>;

    public readonly isReadonlyWrapper$: Observable<{ isReadonly: boolean; }>;

    private readonly _destroy$ = new Subject<void>();

    private readonly _refreshTable$ = new Subject<void>();

    private _projectId: number;

    constructor(
        private readonly _filesClient: FilesClient,
        private readonly _projectStateService: ProjectStateService,
        private readonly _dialogService: DialogService,
        private readonly _confirmationService: ScConfirmationService) {

        this.isReadonlyWrapper$ = _projectStateService.model$.pipe(map(project => ({ isReadonly: project.isReadonly })));

        this.editorTableSettings = this._initEditorTable();
        this.attachmentTableSettings = this._initAttachmentTable();

        this._projectStateService.model$
            .pipe(takeUntil(this._destroy$))
            .subscribe(project => this._projectId = project.id);
    }

    public ngOnDestroy(): void {
        this._refreshTable$.complete();
        this._destroy$.next();
        this._destroy$.complete();
    }

    public addFileDialog(): void {

        const dialogData: IAddFileDialogData = {
            validationRules$: this._projectStateService.switchMap(project => this._filesClient.getValidationRules(project.id)),
            getAddFileUrl: (name: string, document: FileParameter) =>
                this._filesClient.addDocumentUrl(this._projectId, name, document)
        };

        const dialogRef = this._dialogService.open(AddFileDialogComponent, {
            width: '30vw',
            header: $localize`Upload File`,
            data: dialogData
        });

        dialogRef.onClose.pipe(filter(result => !!result),
            takeUntil(this._destroy$))
            .subscribe(() => this._refreshTable$.next());

    }

    public onDeleteDocument(item: ApiProjectDocument): void {

        this._confirmationService.confirm({
            width: '30vw',
            title: $localize`Delete File`,
            message: $localize`Are you sure to delete this file?`,
            acceptLabel: $localize`Delete`,
            accept$: this._filesClient.deleteDocument(this._projectId, item.id),
            acceptCallback: () => {
                this._refreshTable$.next();
            }
        });
    }

    private _initEditorTable(): TableSettings<ApiProjectDocument> {
        return TableSettings.create(ApiProjectDocument, {
            dataSource: oDataQuery => this._projectStateService.switchMap(project => this._filesClient.getEditorDocuments(project.id, oDataQuery)),
            columns: {
                file: {
                    renderer: ExternalRenderers.fileDownload((item: ApiProjectDocument) =>
                        this._filesClient.getEditorDocumentFile(this._projectId, item.id)),
                    sortable: false
                }
            },
            defaultSort: {
                field: x => x.name,
                order: 'asc'
            },
            columnResizeMode: 'fit',
            paginator: false,
            rowHover: false,
            hasFilters: false
        });
    }

    private _initAttachmentTable(): TableSettings<ApiProjectDocument> {
        return TableSettings.create(ApiProjectDocument, {
            dataSource: oDataQuery => this._projectStateService.switchMap(project => this._filesClient.getAttachmentDocuments(project.id, oDataQuery)),
            columns: {
                file: {
                    renderer: ExternalRenderers.fileDownload((item: ApiProjectDocument) =>
                        this._filesClient.getAttachmentDocumentFile(this._projectId, item.id)),
                    sortable: false
                }
            },
            defaultSort: {
                field: x => x.name,
                order: 'asc'
            },
            reload$: this._refreshTable$.asObservable(),
            actionsCount: 1,
            columnResizeMode: 'fit',
            paginator: false,
            rowHover: false,
            hasFilters: false,
            emptyMessageText: $localize`No Files`
        });
    }
}
