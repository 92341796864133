<div class="sc-toolbar flex flex-row align-items-center"
     *ngIf="data">
    <div *ngIf="data.projectName"
         scOverflowTooltip
         class="mr-5 white-space-nowrap">
        <span class="subtitle3Font mr-2"
              i18n>Project:</span>
        <span [innerHTML]="data.projectName | safeMessage:'html'"
              class="body2Font"></span>
    </div>

    <sc-external-enum-tag [enumType]="projectStatusEnumType"
                          [enumValue]="data.projectStatus">
    </sc-external-enum-tag>

    <div *ngIf="data.commentData"
         class="flex align-items-center ml-auto">
        <p-button (onClick)="openComments()"
                  icon="fas fa-comment"
                  styleClass="p-button-rounded p-button-sm sc-grey">
        </p-button>

        <span (click)="openComments()"
              class="subtitle3Font ml-2 cursor-pointer"
              i18n>Comments: ({{ data.commentData.notesCountChanged$ | async }})</span>
    </div>
</div>
