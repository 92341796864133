import { Component } from '@angular/core';

import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';

@Component({
    templateUrl: 'boolean-renderer.component.html'
})
export class BooleanRendererComponent extends RendererBaseComponent {

    public renderAsText: boolean;

    constructor() {
        super();
    }
}
