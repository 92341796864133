import { InjectionToken, TemplateRef } from '@angular/core';

import { Observable } from 'rxjs';

export const TABLE_CAPTION_DATA = new InjectionToken<TableCaptionData>('Table caption data');

export type TableCaptionData = Readonly<{
    captionHeaderTemplate: TemplateRef<any>;
    leftButtonsTemplate: TemplateRef<any>;
    rightButtonsTemplate: TemplateRef<any>;
    canClear: () => boolean;
    canAcceptRowsOrder$: Observable<boolean>;
}>;
