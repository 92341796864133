import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'sc-external-enum-tag',
    templateUrl: './enum-tag.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalEnumTagComponent {

    @Input()
    public enumType: any;

    @Input()
    public enumValue: any;
}
