import { Component, TemplateRef, TrackByFunction, ViewChild } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableSettings } from 'sc-common/shared/table/models/table-settings';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Component({
    templateUrl: 'table-header.component.html'
})
export class TableHeaderComponent {

    @ViewChild('templateRef')
    public readonly templateRef: TemplateRef<any>;

    public readonly allCheckBoxState$: Observable<boolean>;

    public keyField: string;

    public hasRecords: () => boolean;

    public hasActionsColumn = (): boolean => false;

    public readonly trackByFunc: TrackByFunction<TableColumn> = (_: number, item: TableColumn): any => item.field;

    public get canReorderRows(): boolean {

        return !!this.tableSettings.reordering;
    }

    public get canSelect(): boolean {

        return this.tableSettings.selectionMode === 'multiple' && this.hasRecords && this.hasRecords();
    }

    constructor(
        public readonly tableSettings: TableSettings<any>,
        public readonly stateService: TableStateService) {

        this.allCheckBoxState$ = combineLatest([stateService.selectionSource$, stateService.pageUpdate$])
            .pipe(map(([selection, pageUpdate]) =>
                pageUpdate.records?.every(x =>
                    selection.some(s => (this.keyField
                        ? s[this.keyField] === x[this.keyField]
                        : x === x)))
                ?? false));
    }
}
