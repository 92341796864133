import { Component, OnDestroy } from '@angular/core';

import { DialogService } from 'primeng/dynamicdialog';

import { filter, map, Observable, Subject, takeUntil } from 'rxjs';

import { ApiProjectDetails, ProjectDetailsClient } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { IProjectReturnToEditorDialogData } from 'content-manager/src/app/projects/project/details/return-to-editor-dialog/project-return-to-editor-dialog-data';
import { ProjectReturnToEditorDialogComponent } from 'content-manager/src/app/projects/project/details/return-to-editor-dialog/project-return-to-editor-dialog.component';
import { ProjectStateService } from 'content-manager/src/app/projects/project/project-state.service';
import { Permission, PermissionService, ScConfirmationService } from 'sc-common';

@Component({
    templateUrl: './project-details.component.html'
})
export class ProjectDetailsComponent implements OnDestroy {

    public readonly isReadonly$: Observable<boolean>;

    public readonly apiModel$: Observable<ApiProjectDetails>;

    public readonly actionPermission$: Observable<{ isManager: boolean; }>;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _projectStateService: ProjectStateService,
        private readonly _projectClient: ProjectDetailsClient,
        permissionService: PermissionService,
        private readonly _confirmationService: ScConfirmationService,
        private readonly _dialogService: DialogService) {

        this.isReadonly$ = _projectStateService.model$.pipe(map(project => project.isReadonly));

        this.apiModel$ = _projectStateService.switchMap(project => _projectClient.getDetails(project.id));

        this.actionPermission$ = permissionService.memberPermissions$
            .pipe(map(permissions => ({ isManager: permissions.includes(Permission.ContentManagerView) })));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public applyChanges(): void {
        this._confirmationService.confirm({
            width: '30vw',
            title: $localize`Apply Changes`,
            message: $localize`Are you sure you want to Apply Changes?`,
            acceptLabel: $localize`Yes`,
            rejectLabel: $localize`No`,
            accept$: this._projectStateService.firstSwitchMap(project => this._projectClient.complete(project.id)),
            acceptCallback: () => this._projectStateService.refreshState()
        });
    }

    public cancel(): void {
        this._confirmationService.confirm({
            width: '30vw',
            title: $localize`Cancel Project`,
            message: $localize`Are you sure you want to Cancel Project?`,
            acceptLabel: $localize`Yes`,
            rejectLabel: $localize`No`,
            accept$: this._projectStateService.firstSwitchMap(project => this._projectClient.cancel(project.id)),
            acceptCallback: () => this._projectStateService.refreshState()
        });
    }

    public return(): void {

        const dialogData: IProjectReturnToEditorDialogData = {
            returnAction: applyChanges => this._projectStateService.firstSwitchMap(project => this._projectClient.return(project.id, applyChanges))
        };

        const dialogRef = this._dialogService.open(ProjectReturnToEditorDialogComponent, {
            width: '30vw',
            header: $localize`Return to Editor(s)`,
            data: dialogData
        });

        dialogRef.onClose
            .pipe(filter(result => !!result), takeUntil(this._destroy$))
            .subscribe(() => this._projectStateService.refreshState());
    }
}
