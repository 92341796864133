import { PlagiarismCheckResultRendererComponent } from 'content-manager/src/app/projects/project/plagiarism-reports/renderers/check-result/plagiarism-check-result-renderer.component';
import { RendererMetadata } from 'sc-common';

export class PlagiarismReportsRenderers {

    public static checkResult(folderPath: () => string): RendererMetadata<PlagiarismCheckResultRendererComponent> {
        return {
            componentType: PlagiarismCheckResultRendererComponent,
            initializer: inst => inst.folderPath = folderPath
        };
    }
}
