<div class="mt-5">
    <sc-file-upload #fileUpload
                    name="document"
                    [validationRules]="validationRules$ | async"
                    (uploadStarted)="onUploadStarted()"
                    (uploadCompleted)="onUploadCompleted()">
    </sc-file-upload>
    <form [formGroup]="form"
          class="grid formgrid p-fluid">
        <div class="field col-12">
            <label for="documentName"
                   class="font-bold sc-required-input"
                   i18n> Document name </label>
            <input scValidatable
                   id="documentName"
                   type="text"
                   pInputText
                   formControlName="name"
                   placeholder="Name"
                   i18n-placeholder />
        </div>
    </form>
    <p-button label="Upload"
              i18n-label
              class="flex justify-content-center mt-3"
              [loading]="requestInProcess"
              [disabled]="!fileUpload.isValid() || form.invalid"
              (onClick)="uploadDocument()">
    </p-button>
</div>
