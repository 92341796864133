import { Inject, Injectable, Optional } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

@Injectable({
    providedIn: 'root'
})
export class ServerResponseService {

    constructor(@Optional() @Inject(RESPONSE) private readonly _response: Response) {
    }

    public setStatus(code: number, message?: string): void {
        if (this._response) {
            this._response.statusCode = code;
            if (message) {
                this._response.statusMessage = message;
            }
        }
    }
}