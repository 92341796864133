import { ContentChild, Directive, ElementRef, HostBinding, Input, NgZone, OnDestroy, OnInit } from '@angular/core';

import { Button } from 'primeng/button';
import { EditableColumn, Table } from 'primeng/table';

import { combineLatest, iif, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { getExpressionField } from 'sc-common/core/utils/expression-path';
import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Directive({
    selector: '[scEditableColumn]',
    exportAs: 'scEditableColumn',
    providers: [{ provide: EditableColumn, useExisting: ScEditableColumnDirective }]
})
export class ScEditableColumnDirective extends EditableColumn implements OnInit, OnDestroy {
    private readonly _destroy$ = new Subject<void>();

    private readonly _swapButtonClick$ = new Subject<Observable<any>[]>();

    private _backupRowData: any;

    private _siblingColumn?: TableColumn;

    private _canSwapValues = false;

    private _swapButton: Button;

    @Input('scEditableColumn')
    public data: any;

    @Input()
    public column: TableColumn;

    @ContentChild(Button)
    public set swapButton(val: Button) {

        if (this._swapButton !== val) {

            this._swapButton = val;

            this._initSwapButton();
        }
    }

    public get swapButtonDisabled(): boolean {

        return this.dt.editingCellRowIndex === this.rowIndex;
    }

    @HostBinding('class.sc-td-can-swap')
    public get canSwapValues(): boolean {

        return this._canSwapValues;
    }

    constructor(dt: Table, el: ElementRef<any>, zone: NgZone, private readonly _tableStateService: TableStateService) {

        super(dt, el, zone);
    }

    public ngOnInit(): void {

        this._backupRowData = { ...this.data };

        this._tableStateService.columnsSource$
            .pipe(
                filter(x => !!x),
                takeUntil(this._destroy$))
            .subscribe(ci => {

                const columnIndex = ci.columns.indexOf(this.column);

                this._siblingColumn = ci.columns[columnIndex + 1];

                this._canSwapValues = this._siblingColumn
                    && getExpressionField(this.column.settings.editable.useSwap?.destField) === this._siblingColumn.field;
            });

        this.dt.onEditComplete
            .pipe(
                filter((e: { field: string; data: any; originalEvent: any; }) => e.field === this.column.field),
                switchMap(() => {
                    const editCallback = this.column.settings.editable?.callback;
                    const cellValue = this.data[this.column.field];

                    if (editCallback && cellValue !== this._backupRowData[this.column.field]) {

                        this.dt.loading = true;

                        return editCallback(cellValue, this.data);

                    } else {
                        return of(true);
                    }
                }),
                takeUntil(this._destroy$))
            .subscribe({
                next: () => this._cellEditComplete(),
                error: () => this._cellEditComplete(true)
            });

        this.dt.onEditCancel
            .pipe(
                filter((e: { field: string; data: any; originalEvent: any; }) => e.field === this.column.field),
                takeUntil(this._destroy$))
            .subscribe(() => this._rollbackCellValue());
    }

    public ngOnDestroy(): void {

        this._destroy$.next();
        this._destroy$.complete();
    }

    private _initSwapButton(): void {

        this._swapButton.onClick
            .pipe(takeUntil(this._destroy$))
            .subscribe((e: Event) => {

                e.stopPropagation();

                this.dt.loading = true;

                const currentCellValue = this.data[this.column.field];
                const siblingCellValue = this.data[this._siblingColumn.field];

                this.data[this.column.field] = siblingCellValue;

                this.data[this._siblingColumn.field] = currentCellValue;

                const swapCallbackSources = this.column.settings.editable.useSwap.callback
                    ? [
                        this.column.settings.editable.useSwap.callback(siblingCellValue, currentCellValue, this.data)
                    ]
                    : [
                        this.column.settings.editable.callback(siblingCellValue, this.data),
                        this._siblingColumn.settings.editable.callback(currentCellValue, this.data)
                    ];

                this._swapButtonClick$.next(swapCallbackSources);
            });

        this._swapButtonClick$
            .pipe(
                switchMap(callbackSources => iif(() => callbackSources.length > 1, combineLatest(callbackSources), callbackSources[0])),
                takeUntil(this._destroy$))
            .subscribe({
                next: () => this.dt.loading = false,
                error: _ => {

                    Object.assign(this.data, this._backupRowData);

                    this.dt.loading = false;
                }
            });
    }

    private _cellEditComplete(rollbackValue?: boolean): void {

        this.dt.loading = false;

        if (rollbackValue) {
            this._rollbackCellValue();

        } else {

            this._backupRowData[this.column.field] = this.data[this.column.field];

            if (this.column.settings.editable.reloadRequired) {

                this._tableStateService.reload();
            }
        }
    }

    private _rollbackCellValue(): void {
        this.data[this.column.field] = this._backupRowData[this.column.field];
    }
}
