import { Component, Inject, OnDestroy, TemplateRef, ViewChild } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { ODataService } from 'sc-common/core/services/odata/odata.service';
import { TableLocalizingService } from 'sc-common/core/services/table-localizing.service';
import { FileUtils } from 'sc-common/core/utils/file-utils';
import { AdvancedFilterComponent } from 'sc-common/shared/table/advanced-filter/advanced-filter.component';
import { AdvancedFilterService } from 'sc-common/shared/table/advanced-filter/advanced-filter.service';
import { TableCaptionData, TABLE_CAPTION_DATA } from 'sc-common/shared/table/models/caption-data';
import { PageUpdateInfo } from 'sc-common/shared/table/models/page-update-info';
import { TableSettings } from 'sc-common/shared/table/models/table-settings';
import { ToggleColumnsSettings } from 'sc-common/shared/table/models/toggle-columns-settings';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

type ColumnMultiselectItem = { field: string; header: string; };

@Component({
    templateUrl: 'table-caption.component.html'
})
export class TableCaptionComponent implements OnDestroy {

    @ViewChild('templateRef')
    public readonly templateRef: TemplateRef<any>;

    public readonly toggleColumnsSettings: ToggleColumnsSettings;

    public settingsColumns: ColumnMultiselectItem[] = [];

    public selectedColumns: ColumnMultiselectItem[];

    public buttonsContext: { $implicit: any[]; pageInfo?: PageUpdateInfo; } = { $implicit: [], pageInfo: null };

    public selection: any[] = [];

    public get filterButtonLabel(): string {

        const filtersCount = this._advancedFilterService.getFilterValue(this.tableSettings)?.filters.length;

        const label = $localize`Filter${ (filtersCount === 1 ? '' : 's') }`;

        return filtersCount ? $localize`${ filtersCount } ${ label }` : label;
    }

    public get hasContent(): boolean {

        return !!this.data.leftButtonsTemplate || !!this.data.rightButtonsTemplate
            || this.tableSettings.hasAdvancedFilters
            || this.tableSettings.toggleColumns?.canToggleColumns
            || this.tableSettings.hasFilters;
    }

    public exportInProcess: boolean;

    public canAcceptRowsOrder = false;

    public get orderingInProcess(): boolean {
        return this._orderingInProcess;
    }

    public exportButtonOptions: MenuItem[];

    private _orderingInProcess = false;

    private readonly _destroy$ = new Subject<void>();

    private _columnMap: { [key: string]: string; };

    constructor(
        public readonly tableSettings: TableSettings<any>,
        private readonly _tableStateService: TableStateService,
        private readonly _advancedFilterService: AdvancedFilterService,
        private readonly _dialogService: DialogService,
        private readonly _tableLocalize: TableLocalizingService,
        private readonly _odataService: ODataService,
        @Inject(TABLE_CAPTION_DATA) public readonly data: TableCaptionData) {

        data.canAcceptRowsOrder$
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => {

                this._orderingInProcess = false;

                this.canAcceptRowsOrder = x;
            });

        this._tableStateService.selectionSource$
            .pipe(takeUntil(this._destroy$))
            .subscribe(selection => this.selection = this.buttonsContext.$implicit = selection);

        this._tableStateService.pageUpdate$
            .pipe(takeUntil(this._destroy$))
            .subscribe(pageInfo => this.buttonsContext.pageInfo = pageInfo);

        if (this.tableSettings.toggleColumns?.canToggleColumns || this.tableSettings.export) {

            this._tableStateService.columnsSource$
                .pipe(filter(x => x !== null), takeUntil(this._destroy$))
                .subscribe(info => {

                    this._columnMap = {};
                    this.settingsColumns.splice(0);

                    info.columns
                        .map(c => c.field)
                        .concat(info.hidden)
                        .map(c => ({ field: c, header: this._tableLocalize.columns[c] ?? c }))
                        .forEach(c => {
                            this._columnMap[c.field] = c.header;

                            if (!info.columns.some(x => x.isKey && x.field === c.field)) {
                                this.settingsColumns.push(c);
                            }
                        });

                    this.selectedColumns = this.settingsColumns
                        .filter(sc => info.columns.some(c => c.field === sc.field));
                });
        }

        this.toggleColumnsSettings = this._getToggleColumnsSettings();
    }

    public ngOnDestroy(): void {

        this._destroy$.next();

        this._destroy$.complete();
    }

    public showAdvancedFilter(): void {

        this._dialogService.open(AdvancedFilterComponent, {
            data: {
                tableSettings: this.tableSettings,
                advancedFilterService: this._advancedFilterService
            },
            dismissableMask: false,
            header: $localize`Filter`,
            width: '50vw'
        });
    }

    public setExportButtonOptions(): void {

        const selected = this.selection.length;

        const filtered = this.buttonsContext.pageInfo?.filteredCount;

        const options: MenuItem[] = [
            {
                label: $localize`All (${ this.buttonsContext.pageInfo?.count })`,
                command: () => this._saveFile('')
            }
        ];

        if (selected) {

            const queryParams = {
                filters: [{
                    field: this.tableSettings.keyFieldName,
                    matchMode: MatchMode.in,
                    value: this.selection.map(row => row[this.tableSettings.keyFieldName])
                }]
            };

            options.push({
                label: $localize`Selected (${ selected })`,
                command: () => this._saveFile(this._odataService.buildDataQuery(queryParams))
            });
        }

        if (filtered) {

            options.push(
                {
                    label: $localize`Filtered (${ filtered })`,
                    command: () => this._saveFile(this._odataService.buildDataQuery(this.buttonsContext.pageInfo.queryParams))
                });
        }

        this.exportButtonOptions = options;
    }

    public clearAll(): void {

        this._advancedFilterService.clearFilter(this.tableSettings);

        this._tableStateService.reset();
    }

    public onSelectedColumnsChanged({ value }: { value: ColumnMultiselectItem[]; }): void {

        this._tableStateService.toggleColumnsByNames(value.map(x => x.field));
    }

    public onHideColumnMultiselect(): void {

        this.settingsColumns = [...this.settingsColumns];
    }

    public onSubmitRowsOrder(state: boolean): void {

        this._orderingInProcess = true;

        this._tableStateService.submitRowsOrder(state);
    }

    private _saveFile(oDataQuery: string): void {

        this.exportInProcess = true;

        this.tableSettings.export.exportDataSource(oDataQuery, this._columnMap)
            .pipe(takeUntil(this._destroy$))
            .subscribe(result => {

                this.exportInProcess = false;

                FileUtils.save(result);
            });
    }

    private _getToggleColumnsSettings(): ToggleColumnsSettings {
        const defaultMaxSelectedLabels = 3;
        const toggleDropdownAsLink = this.tableSettings.toggleColumns?.toggleDropdownAsLink;

        return {
            toggleDropdownAsLink: toggleDropdownAsLink,
            dropdownIcon: toggleDropdownAsLink
                ? 'fas fa-cog'
                : 'pi pi-chevron-down',
            multiselectStyleClass: toggleDropdownAsLink
                ? 'sc-multiselect-link'
                : '',
            selectedItemsLabel: toggleDropdownAsLink
                ? $localize`Customize table`
                : $localize`Selected {0} from ${ this.settingsColumns.length }`,
            maxSelectedLabels: toggleDropdownAsLink
                ? 0
                : defaultMaxSelectedLabels,
            defaultLabel: toggleDropdownAsLink
                ? $localize`Customize table`
                : $localize`Select Columns`
        };
    }
}
