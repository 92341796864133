import { Component } from '@angular/core';

import { RendererBaseComponent } from 'sc-common';

@Component({
    templateUrl: './enum-tag-renderer.component.html'
})
export class EnumTagRendererComponent extends RendererBaseComponent {

    public enumType: any;

    public get columnEnumType(): any {

        if (this.enumType) {
            return this.enumType;
        }

        if (this.tableColumn.enumMap) {
            return this.tableColumn.dataType;
        }

        return null;
    }

    constructor() {
        super();
    }
}
