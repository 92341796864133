import { ActivatedRoute, Params } from '@angular/router';

import { cloneDeep, mergeWith } from 'lodash-es';

import {
    BehaviorSubject,
    firstValueFrom,
    Observable,
    Subject,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap,
    take,
    takeUntil,
    first
} from 'rxjs';

export abstract class BaseStateService<TModel extends Partial<new (...args: any[]) => any>> {

    protected readonly _modelSubject$ = new BehaviorSubject<TModel>(null);

    protected readonly _destroy$ = new Subject<void>();

    private readonly _refreshSubject$ = new Subject<void>();

    // Observable which tracks the model state
    public readonly model$: Observable<TModel> = this._modelSubject$.pipe(filter(m => !!m), distinctUntilChanged());

    constructor(
        protected readonly activatedRoute: ActivatedRoute,
        predicate: (id: any) => Observable<TModel>,
        idFunc: (params: Params) => any = null
    ) {

        idFunc = idFunc ?? (params => +(params.id ?? 0));
        activatedRoute.params
            .pipe(
                map(idFunc),
                distinctUntilChanged(),
                switchMap(x => this._refreshSubject$.pipe(map(() => x), startWith(x))),
                switchMap(predicate),
                takeUntil(this._destroy$))
            .subscribe(x => this._modelSubject$.next(x));
    }

    // Gets promise with specific model field value. Use with applying async/await pattern.
    // Use destroy parameter in order to cancel await block continuation.
    public getValue<TKeyType>(fieldExpr: (m: TModel) => TKeyType, destroy$: Observable<any>): Promise<TKeyType> {

        return firstValueFrom(this.getObservableValue(fieldExpr, destroy$));
    }

    public getObservableValue<TKeyType>(fieldExpr: (m: TModel) => TKeyType, destroy$: Observable<any>): Observable<TKeyType> {
        return this.model$
            .pipe(
                filter(m => !!m),
                take(1),
                map(fieldExpr),
                takeUntil(destroy$ ?? this._destroy$));
    }

    // Performs refreshing the state from source
    public refreshState(): void {
        this._refreshSubject$.next();
    }

    public updateState(entity: Partial<{ [item in keyof TModel]: TModel[keyof TModel] }>): void {

        // using lodash 'merge' here in order to be able to update state of nested objects
        // eslint-disable-next-line rxjs/no-subject-value
        const merged = mergeWith({}, this._modelSubject$.value, entity,
            (objValue, srcValue) => Array.isArray(objValue) ? srcValue : undefined);

        this._modelSubject$.next(cloneDeep(merged));
    }

    public hasReadonlyQueryParameter(): boolean {
        return this.activatedRoute.snapshot?.queryParams?.readonly;
    }

    public switchMap<TResult>(expression: (model: TModel) => Observable<TResult>): Observable<TResult> {
        return this.model$.pipe(switchMap(expression));
    }

    public firstSwitchMap<TResult>(expression: (model: TModel) => Observable<TResult>): Observable<TResult> {
        return this.model$.pipe(first(), switchMap(expression));
    }

    protected _cleanup(): void {

        this._modelSubject$.next(null);
    }
}
