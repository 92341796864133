import { Pipe, PipeTransform } from '@angular/core';

import { EnumLocalizingService } from 'sc-common';

@Pipe({
    name: 'enumStyle',
    standalone: true
})
export class EnumStylePipe implements PipeTransform {
    constructor(private readonly _localizeService: EnumLocalizingService) { }

    public transform(value: number, enumType: any): any {

        if (value === null || value === undefined) {
            return value;
        }

        if (!enumType) {
            throw new Error('Please provide enum type.');
        }

        return this._localizeService.getStyleClass(enumType, value);
    }
}
