import { Component } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'footer[scFooter]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class ExternalFooterComponent {

    public readonly currentYear;
    constructor() {
        this.currentYear = new Date().getFullYear();
    }
}
