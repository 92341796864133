import { Pipe, PipeTransform } from '@angular/core';

import { FileUtils } from 'sc-common/core/utils/file-utils';

@Pipe({
    name: 'fileSize',
    standalone: true
})
export class FileSizePipe implements PipeTransform {
    constructor() { }

    public transform(value: number): any {

        if (value === null || value === undefined) {
            return value;
        }

        return FileUtils.formatSize(value);
    }
}
