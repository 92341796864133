import { Component, Inject, TemplateRef } from '@angular/core';

import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';
import { LIST_ITEM_TEMPLATE } from 'sc-common/shared/table/models/list-item-template-token';

@Component({
    templateUrl: 'list-renderer.component.html',
    styleUrls: ['list-renderer.component.scss']
})
export class ListRendererComponent extends RendererBaseComponent {

    public renderAsCommaSeparatedList: boolean;

    constructor(
        @Inject(LIST_ITEM_TEMPLATE) public readonly itemTemplate: TemplateRef<any>) {

        super();
    }

    public getDistinctItems(cellData: any[]): any[] {
        // probably will not work for complex objects
        return cellData.filter((item, index) => cellData.indexOf(item) === index);
    }
}
