import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Log, User, UserManager } from 'oidc-client-ts';

import { distinctUntilChanged, from, map, merge, mergeMap, Observable, of, shareReplay, Subject } from 'rxjs';

import { AuthenticatedUser } from 'sc-common/core/models/authenticated-user';
import { ENV_TOKEN, IEnvironment } from 'sc-common/core/models/environment';
import { ClientStorageService } from 'sc-common/core/services/client-storage/client-storage.service';
import { IdentityLoggerService } from 'sc-common/core/services/identity/identity-logger.service';
import { WindowRefService } from 'sc-common/core/services/window-ref/window-ref.service';

const ImpersonationEmailKey = 'impersonationEmail';

const AuthRequestedRouteKey = 'auth_requested_route';

const ImpersonatedByClaim = 'ImpersonatedBy';

const UserDataClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata';

@Injectable()
export class IdentityService {

    private readonly _isServerPlatform: boolean;

    private _userManager: UserManager;

    private readonly _userSubject$ = new Subject<User>();

    public readonly isAuthenticated$: Observable<boolean>;

    public readonly accessToken$: Observable<string>;

    public readonly authenticatedUser$: Observable<AuthenticatedUser>;

    constructor(
        private readonly _identityLoggerService: IdentityLoggerService,
        private readonly _router: Router,
        private readonly _windowRefService: WindowRefService,
        private readonly _clientStorage: ClientStorageService,
        @Inject(ENV_TOKEN) private readonly _env: IEnvironment,
        @Inject(PLATFORM_ID) platformId: object) {

        this._isServerPlatform = isPlatformServer(platformId);

        const user$ = merge(from(this._init()), this._userSubject$)
            .pipe(shareReplay({ refCount: false, bufferSize: 1 }));

        this.isAuthenticated$ = user$.pipe(
            map(u => !!u),
            distinctUntilChanged());

        this.accessToken$ = user$.pipe(map(u => u?.access_token));

        this.authenticatedUser$ = user$.pipe(
            map(u => u
                ? new AuthenticatedUser(u.profile.email, +u.profile[UserDataClaim], !!u.profile[ImpersonatedByClaim])
                : null));
    }

    public routeAuthCheck(route: string): Observable<boolean> {

        if (this._isServerPlatform) {

            return of(false);
        }

        return this.isAuthenticated$.pipe(
            mergeMap((isAuthenticated => {

                if (!isAuthenticated) {

                    if (route) {

                        this._clientStorage.saveToSessionStorage(AuthRequestedRouteKey, route);
                    }

                    return from(this._userManager.signinRedirect())
                        .pipe(map(() => isAuthenticated));
                }

                return of(isAuthenticated);
            }))
        );
    }

    public async login(): Promise<void> {

        await this._userManager.signinRedirect();
    }

    public async logout(): Promise<void> {

        const user = await this._userManager.getUser();

        if (user.profile[ImpersonatedByClaim]) {

            await this._logoutLocal();

        } else {

            await this._userManager.signoutRedirect();
        }

        this._userSubject$.next(null);
    }

    public getImpersonationRedirectUrl(destUrl: string, email: string): string {

        const url = new URL(destUrl);

        url.searchParams.append(ImpersonationEmailKey, email);

        return url.toString();
    }

    private async _init(): Promise<User> {

        if (this._isServerPlatform) {

            return null;
        }

        Log.setLogger(this._identityLoggerService);

        Log.setLevel(Log.NONE);

        const impersonationEmail = this._extractImpersonationEmail();

        this._userManager = new UserManager({
            authority: this._env.identityProviderUrl,
            client_id: this._env.project,
            redirect_uri: this._windowRefService.nativeWindow.origin,
            scope: 'openid profile email',
            post_logout_redirect_uri: this._env.publicBaseUrl,
            silent_redirect_uri: this._windowRefService.nativeWindow.origin + '/silent-renew-frame.html',
            automaticSilentRenew: true,
            monitorSession: true,
            extraQueryParams: !!impersonationEmail && { [ImpersonationEmailKey]: impersonationEmail }
        });

        this._initEventHandlers();

        try {

            let user: User;

            if (impersonationEmail) {

                await this._userManager.removeUser();

                user = await this._userManager.signinSilent();

            } else {

                user = await this._userManager.signinRedirectCallback()
            }

            const requestedRoute = this._clientStorage.extractFromSessionStorage(AuthRequestedRouteKey)
                ?? this._windowRefService.nativeWindow.location.pathname;

            // Cleanup query string after IDP redirect and set requested route
            this._router.navigate(requestedRoute ? [requestedRoute] : [], { queryParams: {}, replaceUrl: true });

            return user;

        } catch (error) {

            return await this._getAuthenticatedUser();
        }
    }

    private async _getAuthenticatedUser(): Promise<User> {

        try {

            return await this._userManager.getUser() || await this._userManager.signinSilent();

        } catch {

            return null;
        }
    }

    private _initEventHandlers(): void {

        const userEventHandler: () => Promise<void> | void = async () => {

            const user = await this._userManager.getUser();

            this._userSubject$.next(user);
        };

        this._userManager.events.addUserSignedIn(userEventHandler);

        this._userManager.events.addUserLoaded(userEventHandler);

        this._userManager.events.addUserSignedOut(() => this._logoutLocal());
    }

    private async _logoutLocal(): Promise<void> {

        await this._userManager.removeUser();

        this._windowRefService.nativeWindow.location.assign(this._env.publicBaseUrl);
    }

    private _extractImpersonationEmail(): string {

        const url = new URL(this._windowRefService.nativeWindow.location.href);

        const impersonationEmail = url.searchParams.get(ImpersonationEmailKey);

        if (impersonationEmail) {

            url.searchParams.delete(ImpersonationEmailKey);

            this._windowRefService.nativeWindow.history.replaceState(null, '', url);
        }

        return impersonationEmail;
    }
}
