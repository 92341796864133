import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { map } from 'rxjs';

import { MemberSettingsClient } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { BaseStateService } from 'sc-common';

@Injectable()
export class MemberStateService extends BaseStateService<{ welcomeBlockHidden: boolean; }> {

    constructor(activatedRoute: ActivatedRoute,
        private readonly _membersSettingsClient: MemberSettingsClient) {
        super(activatedRoute, _ => _membersSettingsClient.haveLogged().pipe(map(result => ({ welcomeBlockHidden: result }))));
    }

    public closeWelcomeBlock(): void {
        this._membersSettingsClient.hideWelcomeBlock().subscribe(() => this.updateState({ welcomeBlockHidden: true }));
    }
}
