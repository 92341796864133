import { Component } from '@angular/core';

import { BaseConfirmationDialogComponent } from 'sc-common/shared/confirmation/base-confirmation-dialog.component';

@Component({
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent extends BaseConfirmationDialogComponent {
}
