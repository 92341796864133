<ng-template #templateRef>
    <div class="p-inputgroup">
        <p-inputMask mask="9999-999a"
                     characterPattern="[0-9xX]"
                     type="text"
                     [(ngModel)]="filterValue"
                     [placeholder]="placeholder"
                     (keyup.enter)="filter()"></p-inputMask>
        <button *ngIf="isFilterApplied"
                pButton
                type="button"
                icon="pi pi-times"
                (click)="cleanFilter()"></button>
    </div>
</ng-template>