<h2 i18n
    class="mb-5">Projects</h2>
<cm-welcome-block *ngIf="welcomeBlockVisible$ | async"
                  (welcomeBlockHide)="onWelcomeBlockHide()">
</cm-welcome-block>
<p-table *ngIf="tablePermission$ | async as tablePermission"
         @animateDisplay
         [scTableSettings]="tableSettings"
         class="cm-project-list-table">
    <ng-container *ngIf="tablePermission.isManager">
        <ng-template pTemplate="tools-panel-right">
            <p-button label="Create new Project"
                      i18n-label
                      (onClick)="newProjectDialog()"
                      styleClass="p-button-outlined">
            </p-button>
        </ng-template>
        <ng-template pTemplate="row-actions"
                     let-project>
            <p-button [disabled]="project.status !== inWorkStatus"
                      icon="fas fa-pen"
                      styleClass="p-button-text p-button-rounded"
                      pTooltip="Rename Project"
                      (onClick)="updateProjectDialog(project)"
                      i18n-pTooltip
                      tooltipPosition="top">
            </p-button>
            <p-button [disabled]="project.status !== inWorkStatus"
                      icon="fas fa-trash"
                      styleClass="p-button-text p-button-rounded"
                      pTooltip="Remove Project"
                      (onClick)="remove(project.id)"
                      i18n-pTooltip
                      tooltipPosition="top">
            </p-button>
        </ng-template>
    </ng-container>
</p-table>
