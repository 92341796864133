import { Injectable, Injector } from '@angular/core';

import { SelectItem } from 'primeng/api';

import { EnumMap, enumToken } from 'sc-common/core/utils/enum-map';

@Injectable({
    // 'any' is needed for every lazy loaded module to receive its own instance
    providedIn: 'any'
})
export class EnumLocalizingService {

    constructor(private readonly _injector: Injector) {
    }

    public getName(enumObj: any, value: any): string {
        return this._getEnumInfo(enumObj, value).label;
    }

    public getNameAndStyleClass(enumObj: any, value: any): { label: string; styleClass?: string; } {
        return this._getEnumInfo(enumObj, value);
    }

    public getStyleClass(enumObj: any, value: any): string {
        return this._getEnumInfo(enumObj, value).styleClass;
    }

    public getSelectItems(enumObj: any): SelectItem[] {

        const enumMap = this._injector.get<any>(enumToken(enumObj)) as EnumMap;

        return Array.from(enumMap.entries())
            .map(([k, v]) => ({ label: v.label, value: enumObj[k] }));
    }

    private _getEnumInfo(enumObj: any, value: any): { label: string; styleClass?: string; } {

        const rawMap = this._injector.get<any>(enumToken(enumObj), null);

        if (rawMap === null) {
            throw new Error(`Can't find injection token for enum: ${ JSON.stringify(enumObj) }; value: ${ value }`);
        }

        const enumMap = rawMap as EnumMap;

        const info = enumMap.get(enumObj[value]);

        if (!info) {
            throw new Error(`Can't find info for enum: ${ JSON.stringify(enumObj) }; value: ${ value }`);
        }

        return info;
    }
}
