import { Injectable } from '@angular/core';
import buildQuery, { PlainObject, QueryOptions } from 'odata-query';

import { ScFilter, ScFilterCollection } from 'sc-common/core/models/filter';
import { FilterUnionType } from 'sc-common/core/models/filter-union-enum';
import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { ScQueryParams } from 'sc-common/core/models/query-params';
import { checkFilterBlankValue } from 'sc-common/core/models/select-filter-item';

@Injectable()
export class ODataService {

    public readonly arrayFieldSeparator = '[]';

    private static _replaceFieldPathDots(path: string): string {

        return path.replace('.', '/');
    }

    public buildAggregateLimitsQuery(field: string): string {

        const transform = {
            aggregate: [
                {
                    [field]: {
                        with: 'max',
                        as: 'max'
                    }

                }, {
                    [field]: {
                        with: 'min',
                        as: 'min'
                    }
                }
            ]
        };

        return buildQuery({ transform });
    }

    public buildDataQuery<T>(queryParams: ScQueryParams): string {

        // because of some weird issues with TS 4.3 compiler we have to use 'any' here instead of QueryOptions<T>
        // TODO: switch back to QueryOptions<T> after TS update and check if it works
        let queryOptions: any = { count: true };

        if (queryParams.first >= 0) {

            queryOptions.skip = queryParams.first;
        }

        if (queryParams.rows > 0) {

            queryOptions.top = queryParams.rows;
        }

        if (queryParams.sortMeta) {

            queryOptions.orderBy = queryParams.sortMeta.map(x =>
                `${ ODataService._replaceFieldPathDots(ODataService._ensureString(x.field)) } ${ (x.order > 0 ? 'asc' : 'desc') }`);
        }

        queryOptions = this._applyFilters(queryOptions, queryParams);

        return buildQuery(queryOptions);
    }

    private static _ensureString(input: string | string[]): string {
        if (Array.isArray(input)) {
            if (input.length > 0) {
                return input[0];
            } else {
                throw new Error('Input was an empty array');
            }
        } else {
            return input;
        }
    }

    private _applyFilters<T>(queryOptions: QueryOptions<T>, queryParams: ScQueryParams): QueryOptions<T> {

        if (!queryParams.advancedFilters || !queryParams.advancedFilters.filters.length) {

            queryOptions.filter = this._processFilters(queryParams.filters);
        }
        else if (queryParams.advancedFilters.filters.length && (!queryParams.filters || !Object.entries(queryParams.filters).length)) {

            queryOptions.filter = this._processAdvancedFilters(queryParams.advancedFilters);

        } else {

            queryOptions.filter = {
                and: [
                    this._processAdvancedFilters(queryParams.advancedFilters),
                    this._processFilters(queryParams.filters)
                ]
            };
        }

        return queryOptions;
    }

    private _processAdvancedFilters(advancedFilters: ScFilterCollection): PlainObject {
        if (!advancedFilters?.filters.length) {
            return null;
        }

        const filters: ScFilter[] = advancedFilters.filters
            .map(f => ({
                field: f.field,
                matchMode: f.matchMode,
                value: f.value,
                isArray: f.isArray
            }));

        return filters.length === 1
            ? this._processFiltersBase(filters)
            : {
                [advancedFilters.unionType]: this._processFiltersBase(filters)
            };
    }

    private _processFilters(filters: ScFilter[]): PlainObject | string[] {

        if (!filters) {
            return [];
        }

        const orFilters = filters.filter(x => x.unionType === FilterUnionType.or);

        if (orFilters.length) {
            return {
                or: [
                    this._processFiltersBase(orFilters),
                    this._processFiltersBase(filters.filter(x => x.unionType !== FilterUnionType.or))
                ]
            };
        }

        return this._processFiltersBase(filters);
    }

    private _processFiltersBase(filters: ScFilter[]): PlainObject | string[] {

        return filters
            .map((f: ScFilter) => {

                let field = ODataService._replaceFieldPathDots(f.field);

                let result: PlainObject | string;

                let filterValue = f.value;

                if (f.isArray) {

                    const itemName = 'xItem';

                    let itemField = itemName;

                    if (field.indexOf(this.arrayFieldSeparator) > 0) {

                        [field, itemField] = field.split(this.arrayFieldSeparator);

                        itemField = itemName + itemField;
                    }

                    const hasBlankItem = checkFilterBlankValue(filterValue) || (Array.isArray(filterValue) && filterValue.some(checkFilterBlankValue));

                    if (Array.isArray(f.value)) {

                        const filterValueArray = f.value.filter(v => !checkFilterBlankValue(v));

                        filterValue = filterValueArray;

                        if (filterValueArray.length > 0) {

                            result = this._getCollectionLambdaFilter(field, itemName, itemField, f.matchMode, filterValue);
                        }
                    }

                    if (hasBlankItem) {

                        const emptyCondition = { [field]: [`not ${ field }/any()`] };

                        result = result
                            ? {
                                or: [
                                    emptyCondition,
                                    result
                                ]
                            }
                            : emptyCondition;
                    } else {

                        result = this._getCollectionLambdaFilter(field, itemName, itemField, f.matchMode, filterValue);
                    }

                } else if (checkFilterBlankValue(filterValue)) {

                    result = filterValue.isText
                        ? {
                            or: [
                                this._getFilterItemResult(field, MatchMode.equals, null),
                                this._getFilterItemResult(field, MatchMode.equals, '')
                            ]
                        }
                        : this._getFilterItemResult(field, MatchMode.equals, null);

                } else if (Array.isArray(filterValue) && filterValue.some(checkFilterBlankValue)) {

                    const filterValueArray = filterValue.filter(x => !checkFilterBlankValue(x));

                    result = filterValueArray.length
                        ? {
                            or: [
                                this._getFilterItemResult(field, MatchMode.equals, null),
                                this._getFilterItemResult(field, f.matchMode, filterValueArray)
                            ]
                        }
                        : this._getFilterItemResult(field, MatchMode.equals, null);

                } else {

                    result = this._getFilterItemResult(field, f.matchMode, filterValue);
                }

                return result;
            });
    }

    private _getCollectionLambdaFilter(field: string, itemName: string, itemFieldName: string, matchMode: MatchMode, value: any): PlainObject {

        const nestedCollectionFilter = buildQuery({
            filter: this._getFilterItemResult(itemFieldName, matchMode, value)
        }).split('=')[1];

        return {
            [field]: [`${ field }/any(${ itemName }:${ nestedCollectionFilter })`]
        };
    }

    private _getFilterItemResult(field: string, matchMode: MatchMode, value: any): PlainObject | string {

        value = this._getFormattedValue(value);

        switch (matchMode) {

            case MatchMode.equals:
                return { [field]: { eq: value } };

            case MatchMode.notEquals:
                return { [field]: { ne: value } };

            case MatchMode.less:
                return { [field]: { lt: value } };

            case MatchMode.lessOrEquals:
                return { [field]: { le: value } };

            case MatchMode.greater:
                return { [field]: { gt: value } };

            case MatchMode.greaterOrEquals:
                return { [field]: { ge: value } };

            case MatchMode.startsWith:
                return { [field]: { startswith: value } };

            case MatchMode.endsWith:
                return { [field]: { endswith: value } };

            case MatchMode.contains:
                return { [field]: { contains: value } };

            case MatchMode.notContains:
                return { not: { [field]: { contains: value } } };

            case MatchMode.in:
                return { [field]: { in: (Array.isArray(value) ? value.map(x => x.toString()) : [value.toString()]) } };

            case MatchMode.notIn:
                return { [field]: { not: { in: (Array.isArray(value) ? value.map(x => x.toString()) : [value.toString()]) } } };

            case MatchMode.between:

                const [start, end] = value as any[];

                const result: any = {};

                if (start) {
                    result.ge = start;
                }

                if (end) {
                    result.le = end;
                }

                return { [field]: result };

            case MatchMode.empty:
                return { [field]: { eq: null } };

            case MatchMode.notEmpty:
                return { [field]: { ne: null } };

            case MatchMode.isTrue:
                return { [field]: { eq: true } };

            case MatchMode.isFalse:
                return { [field]: { eq: false } };

            default:
                throw new Error(`Unknown match mode '${ matchMode }'`);
        }
    }

    private _getFormattedValue(value: any): any {

        // TODO add Guid support here or other custom types
        if (Array.isArray(value)) {

            return value.map(x => this._getFormattedValue(x));
        }
        else if (value instanceof Date) {

            return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
        }

        return value;
    }
}
