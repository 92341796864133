import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ENV_TOKEN, IEnvironment } from 'sc-common/core/models/environment';

@Injectable(
    {
        providedIn: 'root'
    })
export class LoggingService {

    private readonly _appInsights: ApplicationInsights;

    constructor(
        @Inject(PLATFORM_ID) private readonly _platformId: object,
        @Inject(ENV_TOKEN) private readonly _env: IEnvironment) {

        if (this._canLog()) {
            this._appInsights = new ApplicationInsights(
                {
                    config: {
                        instrumentationKey: _env.appInsightsInstrumentationKey,
                        // option to log all route changes
                        enableAutoRouteTracking: true
                    }
                });
        }
    }

    public initLogger(): void {
        if (!this._canLog()) {
            return;
        }

        this._appInsights.loadAppInsights();
    }

    public logException(exception: Error, severityLevel?: number): void {
        if (!this._canLog()) {
            return;
        }

        this._appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    private _canLog(): boolean {
        return this._env.production && isPlatformBrowser(this._platformId);
    }
}
