<div class="sc-header flex align-items-center">

    <a [attr.href]="publicBaseUrl">
        <img class="sc-logo"
             src="/assets/logo.svg">
    </a>

    <nav class="align-self-stretch">
        <ul class="flex subtitle1Font">
            <li *ngIf="mainMenu"
                routerLinkActive="active-item"
                [routerLinkActiveOptions]="{exact: true}">
                <a [routerLink]="mainMenu.routerLink"
                   class="no-underline">
                    {{ mainMenu.label }}
                </a>
            </li>

            <li *ngIf="helpfulMaterialsMenu"
                (click)="helpfulMaterials.toggle($event)"
                i18n>helpful materials
                <i [ngClass]="{'rotate-180': helpfulMaterials.visible}"
                   class="ml-2 fas fa-chevron-down"></i>
            </li>
        </ul>
    </nav>

    <div class="ml-auto">
        <ng-content></ng-content>
    </div>

    <div class="profile subtitle3Font align-self-stretch"
         (click)="profile.toggle($event)"
         i18n>
        <i class="mr-2 fas fa-user"></i>
        Profile
        <i [ngClass]="{'rotate-180': profile.visible}"
           class="ml-2 fas fa-chevron-down"></i>
    </div>
</div>

<p-tieredMenu #helpfulMaterials
              styleClass="sc-tired-menu-width-19"
              [model]="helpfulMaterialsMenu"
              [popup]="true">
</p-tieredMenu>

<p-tieredMenu #profile
              [model]="profileMenu$ | async"
              [popup]="true">
</p-tieredMenu>

<p-toast key="bgProcessToast">
    <ng-template pTemplate="message"
                 let-message>
        <div class="flex flex-column w-full">
            <div class="flex align-items-center mb-3">
                <span class="body2Font mr-2"> {{ message.data.process | enumLocalize: processEnumType }}: </span>
                <sc-external-enum-tag [enumType]="processStatusEnumType"
                                      [enumValue]="message.data.status">
                </sc-external-enum-tag>
            </div>
            <div class="mb-3">
                <p-progressBar [value]="message.data.progressValue"
                               [showValue]="true">
                </p-progressBar>
            </div>
        </div>
    </ng-template>
</p-toast>