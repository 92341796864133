import { InjectionToken } from '@angular/core';

export const ENV_TOKEN = new InjectionToken<IEnvironment>('Environment');

export const environmentBase = <T extends Partial<IEnvironment>>(t: T): T => t;

export type AngularProject = 'public' | 'editing-tool' | 'internal' | 'identity-provider' | 'content-manager' | 'promotion';

export interface IEnvironment {
    project: AngularProject;
    apiUrl: string;
    appInsightsInstrumentationKey?: string;
    production?: boolean;
    publicBaseUrl: string;
    internalBaseUrl: string;
    editingToolBaseUrl: string;
    contentManagerBaseUrl: string;
    promotionBaseUrl: string;
    identityProviderUrl: string;
}
