import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';

import { OverlayPanel } from 'primeng/overlaypanel';

import { Subject, takeUntil } from 'rxjs';

import { ValidationService } from 'sc-common/core/services/input-validation/validation.service';

@Component({
    templateUrl: 'validation-errors-tooltip.component.html'
})
export class ValidationErrorsTooltipComponent implements OnInit, OnDestroy {

    public label: string;

    @ViewChild('validationOverlayPanel')
    public validationOverlayPanel: OverlayPanel;

    public get errorMessage(): string {
        return ValidationService.getMessage(this._control.control, this.label);
    }

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _control: NgControl,
        private readonly _elRef: ElementRef) {
    }

    public ngOnInit(): void {
        this._control.control.statusChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(status => {
                if (status !== 'VALID') {
                    this.validationOverlayPanel.show(null, this._elRef.nativeElement);
                } else {
                    this.validationOverlayPanel.hide();
                }
            });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
