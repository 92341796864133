import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { filter, Subject, takeUntil } from 'rxjs';

import { ProgressMenuItem } from 'sc-external/shared/components/progress-menu/progress-menu-item';

@Component({
    selector: 'sc-progress-menu',
    templateUrl: './progress-menu.component.html',
    styleUrls: ['./progress-menu.component.scss']
})
export class ProgressMenuComponent implements OnDestroy {

    @Input()
    public showCheckmarks = true;

    @Input()
    public set items(value: ProgressMenuItem[]) {
        this.menuItems = value;
        this._checkActiveItem();
    }

    public menuItems: ProgressMenuItem[];

    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _route: ActivatedRoute, private readonly _router: Router) {

        _router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            takeUntil(this._destroy$))
            .subscribe(() => this._checkActiveItem());
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private _checkActiveItem(): void {

        if (!this.menuItems) {
            return;
        }

        this.menuItems.forEach(i => {
            i.active = this._router.isActive(
                this._router.createUrlTree(i.routerLink, { relativeTo: this._route }),
                { fragment: 'ignored', paths: 'subset', matrixParams: 'ignored', queryParams: 'ignored' });
        });
    }
}
