import { LowerCasePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { Component, Injector, PipeTransform } from '@angular/core';

import { JoinPipe } from 'sc-common/shared/pipes/join.pipe';
import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';

export type TextRendererPipe = 'titlecase' | 'lowercase' | 'uppercase' | 'join';

@Component({
    templateUrl: 'text-renderer.component.html',
    providers: [TitleCasePipe, LowerCasePipe, TitleCasePipe]
})
export class TextRendererComponent extends RendererBaseComponent {

    private _pipeTransform: PipeTransform;

    private _pipeArgs: any[];

    public multilineEdit = false;

    public set pipeInfo(value: { name: TextRendererPipe; args?: any[]; }) {

        switch (value.name) {

            case (TitleCasePipe.ɵpipe as any).name:
                this._pipeTransform = this._injector.get(TitleCasePipe);
                break;

            case (LowerCasePipe.ɵpipe as any).name:
                this._pipeTransform = this._injector.get(LowerCasePipe);
                break;

            case (UpperCasePipe.ɵpipe as any).name:
                this._pipeTransform = this._injector.get(UpperCasePipe);
                break;

            case 'join':
                this._pipeTransform = this._injector.get(JoinPipe);
                break;
        }

        this._pipeArgs = value.args;
    }

    constructor(private readonly _injector: Injector) {
        super();
    }

    public getCellValue(value: any): any {

        const formattedValues = this._pipeTransform?.transform(value, ...this._pipeArgs ?? []) ?? value;

        return super.getCellValue(formattedValues);
    }
}
