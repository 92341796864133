import { SelectItem } from 'primeng/api';

import { Observable } from 'rxjs';

import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { SelectFilterItem } from 'sc-common/core/models/select-filter-item';

export class AdvancedFilterDefinition {
    public field: string;

    public label: string;

    public operators: FilterOperator[];

    public isArray?: boolean;

    protected constructor(field: string, label: string, isArray = false) {
        this.field = field;
        this.label = label;
        this.isArray = isArray;
    }
}

export enum FilterRenderAsEnum {
    empty,

    // eslint-disable-next-line id-blacklist
    string,

    // eslint-disable-next-line id-blacklist
    number,

    dateRange,

    select,

    multiselect
}

export class FilterOperator {
    public name: string;

    public matchMode: MatchMode;

    public renderAs: FilterRenderAsEnum;

    public values?: Observable<SelectItem[]>;
}

export const stringOperators = [
    { name: $localize`Is...`, matchMode: MatchMode.equals, renderAs: FilterRenderAsEnum.string },
    { name: $localize`Is not...`, matchMode: MatchMode.notEquals, renderAs: FilterRenderAsEnum.string },
    { name: $localize`Contains...`, matchMode: MatchMode.contains, renderAs: FilterRenderAsEnum.string },
    { name: $localize`Does not contain...`, matchMode: MatchMode.notContains, renderAs: FilterRenderAsEnum.string },
    { name: $localize`Is empty`, matchMode: MatchMode.empty, renderAs: FilterRenderAsEnum.empty },
    { name: $localize`Is not empty`, matchMode: MatchMode.notEmpty, renderAs: FilterRenderAsEnum.empty }
];

export class AdvancedStringFilter extends AdvancedFilterDefinition {

    constructor(field: string, label: string) {
        super(field, label);

        this.operators = stringOperators;
    }
}

export class AdvancedNamedListFilter extends AdvancedFilterDefinition {

    constructor(field: string, label: string) {
        super(field, label, true);

        this.operators = stringOperators;
    }
}

export class AdvancedNumberFilter extends AdvancedFilterDefinition {

    constructor(field: string, label: string) {
        super(field, label);

        this.operators = [
            { name: $localize`Is...`, matchMode: MatchMode.equals, renderAs: FilterRenderAsEnum.number },
            { name: $localize`Is not...`, matchMode: MatchMode.notEquals, renderAs: FilterRenderAsEnum.number },
            { name: $localize`Greater than...`, matchMode: MatchMode.greater, renderAs: FilterRenderAsEnum.number },
            { name: $localize`Greater than or equal to...`, matchMode: MatchMode.greaterOrEquals, renderAs: FilterRenderAsEnum.number },
            { name: $localize`Lesser than...`, matchMode: MatchMode.less, renderAs: FilterRenderAsEnum.number },
            { name: $localize`Lesser than or equal to...`, matchMode: MatchMode.lessOrEquals, renderAs: FilterRenderAsEnum.number }
        ];
    }
}

export class AdvancedSelectFilter extends AdvancedFilterDefinition {

    constructor(field: string, label: string, values: Observable<SelectItem[]>) {
        super(field, label);

        this.operators = [
            { name: $localize`Is...`, matchMode: MatchMode.equals, renderAs: FilterRenderAsEnum.select, values: values },
            { name: $localize`Is not...`, matchMode: MatchMode.notEquals, renderAs: FilterRenderAsEnum.select, values: values }
        ];
    }
}

export class AdvancedBooleanFilter extends AdvancedFilterDefinition {

    constructor(field: string, label: string) {
        super(field, label);

        this.operators = [
            { name: $localize`Is true`, matchMode: MatchMode.isTrue, renderAs: FilterRenderAsEnum.empty },
            { name: $localize`Is false`, matchMode: MatchMode.isFalse, renderAs: FilterRenderAsEnum.empty }
        ];
    }
}

export class AdvancedMultiSelectFilter extends AdvancedFilterDefinition {
    constructor(field: string, label: string, values: Observable<SelectItem[]>) {
        super(field, label);

        this.operators = [
            { name: $localize`Is...`, matchMode: MatchMode.in, renderAs: FilterRenderAsEnum.multiselect, values: values },
            { name: $localize`Is not...`, matchMode: MatchMode.notIn, renderAs: FilterRenderAsEnum.multiselect, values: values }
        ];
    }
}

export class AdvancedDateRangeFilter extends AdvancedFilterDefinition {

    constructor(field: string, label: string) {
        super(field, label);

        this.operators = [
            { name: $localize`Between`, matchMode: MatchMode.between, renderAs: FilterRenderAsEnum.dateRange },
            { name: $localize`Is empty`, matchMode: MatchMode.empty, renderAs: FilterRenderAsEnum.empty }
        ];
    }
}

export class AdvancedFilter {
    public definition: AdvancedFilterDefinition;

    public operator: FilterOperator;

    public value: any;
}

export enum AdvancedFilterWithSourceEnum {
    select,

    multiselect
}

export type AdvancedFilterWithSource = { type: AdvancedFilterWithSourceEnum; source$: Observable<SelectFilterItem[]>; };

export class AdvancedFilters {

    public static select(source$: Observable<SelectFilterItem[]>): AdvancedFilterWithSource {
        return { type: AdvancedFilterWithSourceEnum.select, source$: source$ };
    }

    public static multiselect(source$: Observable<SelectFilterItem[]>): AdvancedFilterWithSource {
        return { type: AdvancedFilterWithSourceEnum.multiselect, source$: source$ };
    }
}
