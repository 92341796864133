import { Component, OnDestroy, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

import { Observable, Subject } from "rxjs";

import { IAddFileDialogData } from "content-manager/src/app/projects/project/files/add-file-dialog/add-file-dialog-data";
import { ValidationConstants, ValidationService, FileValidationModel } from "sc-common";
import { FileUploadComponent } from "sc-external/shared/components/file-upload/file-upload.component";

@Component({
    templateUrl: './add-file-dialog.component.html'
})
export class AddFileDialogComponent implements OnDestroy {

    public requestInProcess: boolean;

    public readonly validationRules$: Observable<FileValidationModel>;

    public readonly form: UntypedFormGroup;

    @ViewChild('fileUpload')
    private readonly _fileUpload: FileUploadComponent;

    private readonly _dialogData: IAddFileDialogData;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        dialogConfig: DynamicDialogConfig,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _dialogRef: DynamicDialogRef) {

        this._dialogData = dialogConfig.data as IAddFileDialogData;
        this.form = this._buildForm();
        this.validationRules$ = this._dialogData.validationRules$;
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public onUploadStarted(): void {
        this.requestInProcess = true;
    }

    public onUploadCompleted(): void {
        this.requestInProcess = false;

        this.form.reset();
        this._fileUpload.clearFile();
        this._dialogRef.close(true);
    }

    public uploadDocument(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const url = this._dialogData.getAddFileUrl(this.form.get('name').value, null);

        this._fileUpload.startUpload(url);

    }

    private _buildForm(): UntypedFormGroup {
        return this._formBuilder.group({
            name: [null, [Validators.required, Validators.maxLength(ValidationConstants.Max200Length), ValidationService.alphaNumSymbolValidator]]
        });
    }
}
