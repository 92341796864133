<ng-template #displayTemplateRef
             let-cellData
             let-rowData="rowData">
    <ng-container *ngIf="cellData?.length && getDistinctItems(cellData) as listItems">
        <ng-container *ngIf="renderAsCommaSeparatedList; else listWithPopup">
            <div class="flex flex-wrap">
                <ng-container *ngFor="let data of cellData; last as isLast">
                    <div class="max-w-full inline-block white-space-nowrap">
                        <div class="max-w-full inline-block">
                            <ng-container *ngTemplateOutlet="itemTemplate;context: {$implicit: data, rowData: rowData}">
                            </ng-container>
                        </div>
                        <div class="sc-list-separator">
                            <span *ngIf="!isLast">,</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>

        <ng-template #listWithPopup>
            <div class="flex align-items-center justify-content-start sc-overflow-ellipsis">
                <ng-container *ngTemplateOutlet="itemTemplate;context: {$implicit: listItems[0], rowData: rowData}">
                </ng-container>

                <ng-container *ngIf="listItems.length > 1">
                    <p-button styleClass="p-button-rounded p-button-text p-button-sm ml-1 p-2"
                              (onClick)="listItems.length <= 10 ? listPanel.toggle($event) : listPanelFull.toggle($event)"
                              [label]="'+' + (listItems.length - 1)">
                    </p-button>
                    <p-overlayPanel #listPanel
                                    appendTo="body">
                        <div class="flex flex-column"
                             *ngFor="let listItem of listItems.slice(1); last as isLast">
                            <ng-container *ngTemplateOutlet="itemTemplate;context: {$implicit: listItem, rowData: rowData}">
                            </ng-container>
                            <hr *ngIf="!isLast">
                        </div>
                    </p-overlayPanel>

                    <p-overlayPanel #listPanelFull
                                    appendTo="body">
                        <div class="grid">
                            <div class="col-2 flex"
                                 *ngFor="let listItem of listItems.slice(1); last as isLast">
                                <ng-container *ngTemplateOutlet="itemTemplate;context: {$implicit: listItem, rowData: rowData}">
                                </ng-container>
                            </div>
                        </div>
                    </p-overlayPanel>
                </ng-container>
            </div>
        </ng-template>

    </ng-container>
</ng-template>