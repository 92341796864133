import { Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { finalize, first, Subject, takeUntil } from 'rxjs';

import { INewChapterDialogData } from 'content-manager/src/app/projects/project/chapters/new-chapter-dialog/new-chapter-dialog-data';
import { ValidationConstants, ValidationService } from 'sc-common';

@Component({
    templateUrl: './new-chapter-dialog.component.html',
    styleUrls: ['./new-chapter-dialog.component.scss']
})
export class NewChapterDialogComponent implements OnDestroy {

    public form: UntypedFormGroup;

    public requestInProcess = false;

    private readonly _dialogData: INewChapterDialogData;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _dialogRef: DynamicDialogRef,
        dialogConfig: DynamicDialogConfig) {

        this._buildForm();
        this._dialogData = dialogConfig.data as INewChapterDialogData;
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public createNewChapter(): void {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.requestInProcess = true;

        this._dialogData.createChapterExpr(this.form.value)
            .pipe(
                first(),
                finalize(() => this.requestInProcess = false),
                takeUntil(this._destroy$)
            )
            .subscribe(() => this._dialogRef.close(true));
    }

    private _buildForm(): void {

        this.form = this._formBuilder.group({
            name: [null, [Validators.required,
            Validators.maxLength(ValidationConstants.Max250Length),
            ValidationService.alphaNumSymbolValidator]]
        });
    }
}
