import { BackgroundProcessStatusEnum, buildEnumMapProvider, EnumSeverity } from 'sc-common';

export const backgroundProcessStatusEnumMapProvider = buildEnumMapProvider(BackgroundProcessStatusEnum, getEnumText, getEnumSeverity);

function getEnumText(value: BackgroundProcessStatusEnum): string {
    switch (value) {
        case BackgroundProcessStatusEnum.Awaiting:
            return $localize`Awaiting`;
        case BackgroundProcessStatusEnum.Deleted:
            return $localize`Deleted`;
        case BackgroundProcessStatusEnum.Enqueued:
            return $localize`Enqueued`;
        case BackgroundProcessStatusEnum.Failed:
            return $localize`Failed`;
        case BackgroundProcessStatusEnum.Processing:
            return $localize`Processing`;
        case BackgroundProcessStatusEnum.Scheduled:
            return $localize`Scheduled`;
        case BackgroundProcessStatusEnum.Succeeded:
            return $localize`Succeeded`;
    }
}

function getEnumSeverity(value: BackgroundProcessStatusEnum): EnumSeverity {
    switch (value) {
        case BackgroundProcessStatusEnum.Failed:
            return EnumSeverity.error;
        case BackgroundProcessStatusEnum.Processing:
            return EnumSeverity.warning;
        case BackgroundProcessStatusEnum.Succeeded:
            return EnumSeverity.success;
        default:
            return EnumSeverity.default;
    }
}

