import { Component, Input } from '@angular/core';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { DialogMessageData } from 'sc-common/shared/dialog-message/dialog-message-data';

@Component(
    {
        selector: 'sc-dialog-message',
        templateUrl: './dialog-message.component.html'
    })
export class DialogMessageComponent {

    @Input()
    public message: string;

    constructor(dialogConfig: DynamicDialogConfig) {
        this.message = (dialogConfig.data as DialogMessageData).message;
    }
}
