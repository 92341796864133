import { Permission } from "sc-common";

export interface RouteData {
    header?: string;
    breadcrumbsDisabled?: boolean;
    hasPermission?: Permission | Permission[];
    hasAnyChildModulePermission?: boolean;
}

export function initRouteData(routeData: Readonly<RouteData>): RouteData {
    return routeData;
}
