import { Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

import { GlobalNotification } from 'sc-common/core/services/notification/global-notification';

@Injectable(
    {
        providedIn: 'root'
    })
export class NotificationService implements OnDestroy {

    private _notificationSubject$ = new Subject<GlobalNotification>();

    public showNotification$ = this._notificationSubject$.asObservable();

    public showError(description: string): void {
        this._notificationSubject$.next(
            {
                title: $localize`Error`,
                description: $localize`Error occurred: ${ description }`
            });
    }

    public ngOnDestroy(): void {
        this._notificationSubject$.complete();
    }
}
