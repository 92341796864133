<p-table *ngIf="(tableModel$ | async) as tableModel"
         [scTableSettings]="tableModel.tableSettings"
         class="mt-3">
    <ng-template pTemplate="tools-panel-left">
        <div *ngIf="checkStatus"
             class="flex align-items-center">
            <p-button *ngIf="!tableModel.isReadonly"
                      (onClick)="checkForPlagiarism()"
                      [disabled]="plagiarismCheckInProgress"
                      label="{{ checkStatus.status === statusEnumType.Unchecked ? 'Check for Plagiarism' : 'Restart check' }}"
                      i18n-label>
            </p-button>

            <sc-external-enum-tag class="ml-5"
                                  [enumType]="statusEnumType"
                                  [enumValue]="checkStatus.status">
            </sc-external-enum-tag>

            <span *ngIf="checkStatus.folderPath"
                  class="ml-5">
                <i class="fa-regular fa-folder-open mr-1"></i>
                <a [href]="checkStatus.folderPath"
                   target="_blank"
                   i18n>Open folder in IThenticate</a>
            </span>
        </div>
    </ng-template>
</p-table>