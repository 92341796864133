import { saveAs } from 'file-saver-es';

import { FileResponse } from 'sc-common';

export class FileUtils {
    private static readonly FileIconClasses: { [fileExtension: string]: string; } = {
        ['doc']: 'far fa-file-word',
        ['docx']: 'far fa-file-word',
        ['xls']: 'far fa-file-excel',
        ['xlsx']: 'far fa-file-excel',
        ['jpeg']: 'far fa-file-image',
        ['jpg']: 'far fa-file-image',
        ['tiff']: 'far fa-file-image',
        ['tif']: 'far fa-file-image',
        ['png']: 'far fa-file-image',
        ['pdf']: 'far fa-file-pdf',
        ['zip']: 'far fa-file-zipper',
        ['rtf']: 'far fa-file'
    };

    private static DefaultIconSize = 2;

    public static getPdfIconClass(increaseBy?: number): string {

        return this._getIconClassByExtension('pdf', increaseBy);
    }

    public static getFileIconClass(fileName: string, increaseBy?: number): string {

        const extension = this.getFileExtension(fileName);

        return this._getIconClassByExtension(extension, increaseBy);
    }

    public static getFileExtension(fileName: string): string {
        return fileName.split('.').pop();
    }

    public static formatSize(bytes: number): string {
        if (bytes === 0) {
            return '0 B';
        }

        const k = 1000;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i];
    }

    public static save(file: FileResponse): void {
        saveAs(file.data, file.fileName);
    }

    private static _getIconClassByExtension(extension: string, increaseBy?: number): string {
        const fileIconClass = this.FileIconClasses[extension];

        increaseBy = increaseBy ? this.DefaultIconSize * increaseBy : this.DefaultIconSize;

        return `${ fileIconClass } fa-${ increaseBy }x`;
    }
}
