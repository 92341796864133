import { Component } from '@angular/core';

import { DateTimeFormat } from 'sc-common/shared/pipes/date.pipe';
import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';

@Component({
    templateUrl: 'date-renderer.component.html'
})
export class DateRendererComponent extends RendererBaseComponent {

    public format?: DateTimeFormat;

    constructor() {
        super();
    }
}
