import { Component, TemplateRef, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Component({
    templateUrl: 'paginator.component.html'
})
export class PaginatorComponent {

    @ViewChild('recordsReportTemplateRef')
    public readonly recordsReportTemplateRef: TemplateRef<any>;

    public readonly page$: Observable<{ filteredCount?: number; count: number; }>;

    constructor(tableStateService: TableStateService) {

        this.page$ = tableStateService.pageUpdate$
            .pipe(
                filter(x => !!x),
                map(x => ({
                    filteredCount: x.filteredCount,
                    count: x.count
                })));
    }
}
