import { isPlatformServer } from '@angular/common';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';

import { mergeMap, catchError, first, Observable, switchMap, throwError } from 'rxjs';

import { ENV_TOKEN, IEnvironment } from 'sc-common/core/models/environment';
import { PermissionConstants } from 'sc-common/core/models/generated-constants';
import { PermissionService } from 'sc-common/core/services/identity/permission.service';

@Injectable()
export class PermissionsInterceptor implements HttpInterceptor {

    private _permissionService: PermissionService;

    private readonly _isServer: boolean;

    constructor(
        private readonly _injector: Injector,
        @Inject(ENV_TOKEN) private readonly _env: IEnvironment,
        @Inject(PLATFORM_ID) platformId: object) {
        this._isServer = isPlatformServer(platformId);
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this._isServer
            || req.url.endsWith('permissions/token')
            || req.url.startsWith(this._env.identityProviderUrl)) {

            return next.handle(req);
        }

        // Explicit service assignment required in order to eliminate circular dependency with IdentityService
        return (this._permissionService ??= this._injector.get(PermissionService)).permissionToken$
            .pipe(
                first(),
                switchMap(token => this._getHandler(req, next, token)));
    }

    private _getHandler(req: HttpRequest<any>, next: HttpHandler, permissionsToken: string): Observable<any> {
        return next.handle(this._getRequest(req, permissionsToken))
            .pipe(
                catchError(response => {

                    if (response instanceof HttpErrorResponse
                        && response.status === 401
                        && response.headers.get(PermissionConstants.ErrorHeader)?.includes(PermissionConstants.TokenExpirationError)) {

                        return this._permissionService.requestNewToken$()
                            .pipe(
                                first(),
                                mergeMap(innerToken => next.handle(this._getRequest(req, innerToken))));
                    }

                    return throwError(() => response);
                }));
    }

    private _getRequest(request: HttpRequest<any>, permissionsToken: string): HttpRequest<any> {

        return permissionsToken
            ? request.clone({
                setHeaders: {
                    [PermissionConstants.TokenHeaderName]: permissionsToken
                }
            })
            : request;
    }
}
