import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from 'sc-common/core/services/notification/notification.service';

@Component(
    {
        selector: 'sc-global-notification',
        templateUrl: './global-notification.component.html'
    })
export class GlobalNotificationComponent implements OnInit, OnDestroy {

    public title: string;

    public description: string;

    public display = false;

    // TODO remove after primeNG adds SSR support
    public isBrowser: boolean;

    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _notificationService: NotificationService, @Inject(PLATFORM_ID) platformId: object) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    public ngOnInit(): void {
        this._notificationService.showNotification$
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                notification => {
                    this.display = true;
                    this.title = notification.title;
                    this.description = notification.description;
                });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
