<ng-template #editableTemplateRef
             let-cellData
             let-rowData="rowData">
    <ng-container *ngIf="dataSource$ | async as dataSource">
        <p-dropdown *ngIf="dataSource.length; else itemTpl"
                    class="sc-table-dropdown"
                    appendTo="body"
                    [dataKey]="dataKey"
                    [optionLabel]="optionLabel"
                    [optionValue]="optionValue"
                    scrollHeight="auto"
                    [placeholder]="placeholder"
                    [showClear]="showClear"
                    [autoDisplayFirst]="false"
                    [ngModel]="cellData"
                    [options]="dataSource"
                    (onChange)="onSelectionChange($event, rowData, cellData)">
            <ng-template pTemplate="selectedItem">
                <ng-template *ngTemplateOutlet="itemTpl"></ng-template>
            </ng-template>
        </p-dropdown>
        <ng-template #itemTpl>
            <div scOverflowTooltip>
                {{ displayExpr(cellData) }}
            </div>
        </ng-template>
    </ng-container>
</ng-template>