import { Component } from '@angular/core';
import { NgControl } from '@angular/forms';

import { ValidationService } from 'sc-common/core/services/input-validation/validation.service';

@Component({
    templateUrl: 'validation-errors-label.component.html',
    host: { '[class.sc-validation-errors]': 'true' }
})
export class ValidationErrorsLabelComponent {

    public label: string;

    public get errorMessage(): string {
        return ValidationService.getMessage(this._control.control, this.label);
    }

    constructor(private readonly _control: NgControl) {
    }
}
