import { NgModule } from '@angular/core';

import { DialogService } from 'primeng/dynamicdialog';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { CommonSharedModule, ScDialogService } from 'sc-common';
import { CONFIRMATION_DIALOG_TYPE } from 'sc-common/shared/confirmation/confirmation-service';
import { ExternalEnumTagComponent } from 'sc-external/shared/components/enum-tag/enum-tag.component';
import { ErrorPagesModule } from 'sc-external/shared/components/error/error-pages.module';
import { FileDownloadComponent } from 'sc-external/shared/components/file-download/file-download.component';
import { FileUploadComponent } from 'sc-external/shared/components/file-upload/file-upload.component';
import { ExternalFooterModule } from 'sc-external/shared/components/footer/external-footer.module';
import { ExternalHeaderComponent } from 'sc-external/shared/components/header/header.component';
import { ProgressCircleComponent } from 'sc-external/shared/components/progress-circle/progress-circle.component';
import { ProgressMenuComponent } from 'sc-external/shared/components/progress-menu/progress-menu.component';
import { ConfirmationDialogComponent } from 'sc-external/shared/confirmation/dialog/confirmation-dialog.component';
import { DropdownRendererComponent } from 'sc-external/shared/table/renderers/dropdown/dropdown-renderer.component';
import { EnumTagRendererComponent } from 'sc-external/shared/table/renderers/enum-tag/enum-tag-renderer.component';
import { FileDownloadRendererComponent } from 'sc-external/shared/table/renderers/file-download/file-download-renderer.component';

@NgModule({
    imports: [
        CommonSharedModule,
        ProgressSpinnerModule,
        MessageModule,
        ErrorPagesModule,
        ExternalFooterModule,
        ProgressCircleComponent
    ],
    declarations: [
        ConfirmationDialogComponent,
        ExternalEnumTagComponent,
        ExternalHeaderComponent,
        EnumTagRendererComponent,
        DropdownRendererComponent,
        FileDownloadRendererComponent,
        FileDownloadComponent,
        FileUploadComponent,
        ProgressMenuComponent
    ],
    exports: [
        CommonSharedModule,
        ExternalEnumTagComponent,
        ExternalHeaderComponent,
        FileDownloadRendererComponent,
        FileDownloadComponent,
        FileUploadComponent,
        ProgressSpinnerModule,
        ProgressMenuComponent,
        MessageModule,
        ErrorPagesModule,
        ExternalFooterModule
    ],
    providers: [
        DialogService,
        ScDialogService,
        { provide: DialogService, useExisting: ScDialogService },
        { provide: CONFIRMATION_DIALOG_TYPE, useValue: ConfirmationDialogComponent }
    ]
})
export class ExternalSharedModule {
}
