import { animate, style, transition, trigger } from '@angular/animations';

export class FormAnimations {
    public static readonly ShowButtons =
        trigger('showButtons', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(300, style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate(300, style({ opacity: 0 }))
            ])
        ]);

    public static readonly AnimateDisplay =
        trigger('animateDisplay', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(500, style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate(200, style({ opacity: 0 }))
            ])
        ]);
}
