<ng-template #displayTemplateRef
             let-cellData>
    <ng-container *ngIf="checkHasValue(cellData)">
        <p-tag *ngIf="columnEnumType; else notEnum"
               [value]="cellData | enumLocalize: columnEnumType"
               [rounded]="true"
               [severity]="cellData | enumStyle: columnEnumType">
        </p-tag>

        <ng-template #notEnum>
            <p-tag *ngIf="!tableColumn.enumMap"
                   [rounded]="true"
                   [value]="getCellValue(cellData)">
            </p-tag>
        </ng-template>
    </ng-container>
</ng-template>