import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive(
    {
        // eslint-disable-next-line @angular-eslint/directive-selector
        selector: 'input[type=text],textarea',
        providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: InputTrimDirective, multi: true }]
    })
export class InputTrimDirective implements ControlValueAccessor {

    public registerOnChange(fn: (_: any) => void): void { this._onChange = fn; }

    public registerOnTouched(fn: () => void): void { this._onTouched = fn; }

    private _onChange = (_: any): void => { };

    private _onTouched = (): void => { };

    private _value: string;

    @HostListener('blur', ['$event.target.value'])
    public onBlur(value: string): void {
        this._updateValue(value.trim());
        this._onTouched();
    }

    @HostListener('input', ['$event.target.value'])
    public onInput(value: string): void {
        this._updateValue(value);
    }

    constructor(private readonly _renderer: Renderer2, private readonly _elementRef: ElementRef) { }

    public writeValue(value: any): void {
        this._value = !value ? '' : value || null;
        this._renderer.setProperty(this._elementRef.nativeElement, 'value', this._value);
    }

    public setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
    }

    private _updateValue(value: string): void {
        const previous = this._value;

        this.writeValue(value);

        if ((this._value || previous) && this._value.trim() !== previous) {
            this._onChange(this._value);
        }
    }
}
