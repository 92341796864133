import { Component, OnInit } from '@angular/core';

import { SelectItem } from 'primeng/api';
import { Calendar } from 'primeng/calendar';

import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { FilterBaseComponent } from 'sc-common/shared/table/header/filters/filter-base.component';
import { DateFilterType } from 'sc-common/shared/table/models/date-filter-enum';
import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Component({
    templateUrl: 'date-filter.component.html'
})
export class DateFilterComponent extends FilterBaseComponent implements OnInit {

    public readonly placeholder = $localize`Select`;

    private _dateFilterType: DateFilterType;

    public get dateFilterType(): DateFilterType {

        return this._dateFilterType;
    }

    public set dateFilterType(value: DateFilterType) {

        this._dateFilterType = value;

        switch (this._dateFilterType) {

            case DateFilterType.single:
                this.matchMode = MatchMode.equals;
                break;

            case DateFilterType.range:
                this.matchMode = MatchMode.between;
                break;
        }
    }

    public dateSelectionModeList: SelectItem[];

    constructor(tableStateService: TableStateService, tableColumn: TableColumn) {

        super(tableStateService, tableColumn);

        this.dateSelectionModeList = Object.values(DateFilterType).map(x => ({ label: this._dateFilterTypeLabel(x), value: x }));
    }

    public ngOnInit(): void {

        if (this.filterValue) {
            switch (this.matchMode) {

                case MatchMode.equals:
                    this._dateFilterType = DateFilterType.single;
                    break;

                case MatchMode.between:
                    this._dateFilterType = DateFilterType.range;
                    break;
            }
        }
    }

    public onSelectModeChange(event: { originalEvent: Event; value: string; }): void {

        this.filterValue = null;

        event.originalEvent.stopPropagation();
    }

    public onTodayClick(event: any, calendarRef: Calendar): void {

        calendarRef.selectionMode = this.dateFilterType = DateFilterType.single;

        calendarRef.onTodayButtonClick(event);
    }

    private _dateFilterTypeLabel(values: DateFilterType): string {

        switch (values) {

            case DateFilterType.single:
                return $localize`Single`;

            case DateFilterType.range:
                return $localize`Range`;
        }
    }
}
