//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export abstract class ValidationConstants
{
	public static PasswordStrengthRegex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
	public static KurzelRegex: RegExp = /^[A-Za-z]{2,6}$/i;
	public static VolumeIdRegex: RegExp = /^[A-Za-z]{2,6}\.\d{1,4}(\-\d{1,4})?$/i;
	public static PublicPaperIdRegex: RegExp = /^[A-Za-z]{2,6}\.\d{1,4}(\-\d{1,4})?\.\-?\d{1,5}$/i;
	public static IsbnRegex: RegExp = /^(?=(?:\D*\d){10}(?:(?:\D*\d){3})?$)[\d-]+$/;
	public static SlugRegex: RegExp = /^[\p{L}\p{N}-]+$/iu;
	public static PageRegex: RegExp = /^\d{1,5}$/;
	public static Zero: number = 0;
	public static One: number = 1;
	public static CountryCodeLength: number = 2;
	public static MaxDoiLength: number = 100;
	public static MaxOwnDoiLength: number = 50;
	public static MaxReferenceLength: number = 1024;
	public static MaxEmailLength: number = 60;
	public static MaxKurzelLength: number = 6;
	public static MaxIssnLength: number = 9;
	public static MaxSlugLength: number = 255;
	public static Max2Length: number = 2;
	public static Max3Length: number = 3;
	public static Max10Length: number = 10;
	public static Max17Length: number = 17;
	public static Max19Length: number = 19;
	public static Max20Length: number = 20;
	public static Max50Length: number = 50;
	public static Max100Length: number = 100;
	public static Max200Length: number = 200;
	public static Max225Length: number = 225;
	public static Max250Length: number = 250;
	public static Max500Length: number = 500;
	public static Max900Length: number = 900;
	public static Max1024Length: number = 1024;
	public static Max99999: number = 99999;
	public static Min10: number = 10;
	public static Min6: number = 6;
	public static Min8: number = 8;
	public static Min99999: number = -99999;
	public static Max1000000000: number = 1000000000;
}
export abstract class SystemRoles
{
	public static SuperAdmin: string = `Super Admin`;
	public static Editor: string = `Editor`;
	public static Reviewer: string = `Reviewer`;
	public static Author: string = `Author`;
	public static Checker: string = `Checker`;
	public static CorrespondingAuthor: string = `Corresponding Author`;
	public static ContentManager: string = `Content Manager`;
	public static ContentEditor: string = `Content Editor`;
}
export abstract class PermissionConstants
{
	public static ErrorHeader: string = `X-Permissions-Error`;
	public static TokenHeaderName: string = `X-Module-Permission-List`;
	public static TokenExpirationError: string = `permission_token_expired`;
}
