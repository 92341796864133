import { buildEnumMapProvider } from 'sc-common';
import { ApiBulkMemberErrorEnum } from 'sc-common/core/services/open-api/main-api-client';

export const bulkMemberErrorEnumMapProvider = buildEnumMapProvider(ApiBulkMemberErrorEnum, getEnumText);

function getEnumText(value: ApiBulkMemberErrorEnum): string {

    switch (value) {

        case ApiBulkMemberErrorEnum.EmailInvalid:
            return $localize`Email is invalid`;

        case ApiBulkMemberErrorEnum.EmailMissing:
            return $localize`Email is missing`;

        case ApiBulkMemberErrorEnum.EmailNotUnique:
            return $localize`Email is not unique`;

        case ApiBulkMemberErrorEnum.FirstNameInvalid:
            return $localize`First name is invalid`;

        case ApiBulkMemberErrorEnum.FirstNameMissing:
            return $localize`First name is missing`;

        case ApiBulkMemberErrorEnum.LastNameInvalid:
            return $localize`Last name is invalid`;

        case ApiBulkMemberErrorEnum.CodeInvalid:
            return $localize`Code is invalid`;

        case ApiBulkMemberErrorEnum.LastNameMissing:
            return $localize`Last name is missing`;

        case ApiBulkMemberErrorEnum.FirstNameLength:
            return $localize`First Name has maximum length of 50 characters`;

        case ApiBulkMemberErrorEnum.LastNameLength:
            return $localize`Last Name has maximum length of 50 characters`;

        case ApiBulkMemberErrorEnum.EmailLength:
            return $localize`Email has maximum length of 60 characters`;

        case ApiBulkMemberErrorEnum.CodeLength:
            return $localize`Code has maximum length of 500 characters`;
    }
}
