/* eslint-disable  @typescript-eslint/naming-convention */
import { Directive, Input, OnDestroy, OnInit } from '@angular/core';

import { merge } from 'lodash-es';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EditorComponent } from '@tinymce/tinymce-angular';

import { ApiText } from 'sc-common';
import { TextClient } from 'sc-common/core/services/open-api/shared-api-client';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'editor'
})
export class TinyMceEditorDirective implements OnInit, OnDestroy {

    @Input()
    public placeholder: string;

    @Input()
    public richEditor = false;

    @Input()
    public formattingTagsOnly = false;

    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _editorComponent: EditorComponent, private readonly _textClient: TextClient) {
    }

    public ngOnInit(): void {

        this._editorComponent.apiKey = '5bde4yr74zs0m8ue32h39qaco0f5s1nqj26uus08hxsjx6ak';

        const defaultOptions: Record<string, any> = {
            forced_root_block: false,
            menubar: false,
            statusbar: false,
            resize: false,
            branding: false,
            placeholder: this.placeholder,
            plugins: 'code charmap',
            toolbar: 'code source italic subscript superscript capitalize charmap',
            formats: {
                bold: { inline: 'b' },
                italic: { inline: 'i' }
            },
            extended_valid_elements: 'b/strong,i/em',
            entity_encoding: 'raw',
            setup: (editor: any) => {
                editor.ui.registry.addButton('capitalize', {
                    icon: 'change-case',
                    tooltip: $localize`Clean & Capitalize`,
                    onAction: () => {
                        this._textClient.cleanAndCapitalize(new ApiText({ text: editor.getContent() }))
                            .pipe(takeUntil(this._destroy$))
                            .subscribe(capitalized => {
                                editor.setContent(capitalized);
                                editor.fire('change');
                            });
                    }
                });
            }
        };

        let options;

        if (this.formattingTagsOnly) {
            const minTagOptions: Record<string, any> =
            {
                valid_elements: 'sup,i,sub',
                invalid_elements: "strong,b"
            }

            options = merge({}, defaultOptions, minTagOptions);
        }

        if (this.richEditor) {

            const richOptions: Record<string, any> = {
                menubar: 'file edit view insert format tools table help',
                statusbar: true,
                resize: true,
                placeholder: this.placeholder,
                plugins: 'print preview paste importcss searchreplace autolink autosave save code visualblocks visualchars fullscreen image link media codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                imagetools_cors_hosts: ['picsum.photos'],
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample',
                toolbar_sticky: true,
                fontsize_formats: '6pt 8pt 10pt 12pt 14pt 18pt 24pt 36pt',
                image_advtab: true,
                importcss_append: true,
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_noneditable_class: 'mceNonEditable',
                ncu: 'sliding',
                contextmenu: 'link image imagetools table'
            };

            options = merge({}, defaultOptions, richOptions);
        }

        if (!this.formattingTagsOnly && !this.richEditor) {
            options = defaultOptions;
        }

        this._editorComponent.init = options;
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
