const mtKey = Symbol('sc:tableKey');

export function keyMap(): (target: any, propertyKey: string) => void {
    return (target: any, propertyKey: string) => {

        Reflect.defineMetadata(mtKey, null, target, propertyKey);

        if (!Object.getOwnPropertyDescriptor(target, propertyKey)) {

            Object.defineProperty(target, propertyKey, {
                enumerable: false,
                configurable: true,
                writable: true
            });
        }
    };
}

export function hasKeyMap(target: any, propertyKey: string): boolean {

    return Reflect.hasMetadata(mtKey, target, propertyKey);
}
