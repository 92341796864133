import { LocationStrategy } from '@angular/common';
import { Directive, HostBinding, HostListener, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';

import { ENV_TOKEN, IEnvironment } from 'sc-common';

@Directive({
    selector: 'a[scPublicRouterLink]',
    standalone: true
})
export class PublicRouterLinkDirective {

    @HostBinding('attr.href')
    @Input()
    public href?: string;

    @Input()
    public set scPublicRouterLink(commands: any) {
        if (!Array.isArray(commands)) {
            commands = [commands];
        }

        if (this._isPublic) {
            this._urlTree = this._router.createUrlTree(commands, { relativeTo: this._route });
            this.href = this._locationStrategy.prepareExternalUrl(this._router.serializeUrl(this._urlTree));
        } else {

            this.href = [this._env.publicBaseUrl, ...commands].join('/');
        }
    }

    private _urlTree: UrlTree;

    private _isPublic: boolean;

    constructor(
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _locationStrategy: LocationStrategy,
        @Inject(ENV_TOKEN) private readonly _env: IEnvironment) {

        this._isPublic = _env.project === 'public';
    }

    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        if (this._isPublic) {
            event.preventDefault();
            this._router.navigateByUrl(this._urlTree);
        }
    }
}