/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PermissionService } from 'sc-common/core/services/identity/permission.service';

@Directive({
    selector: 'form[hasPermissionToEditForm]'
})
export class HasPermissionToEditFormDirective implements OnInit, OnDestroy {

    @Input()
    public set hasPermissionToEditForm(permission: number) {
        this._permission = permission;
    }

    private _permission: number;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _formGroupDirective: FormGroupDirective,
        private readonly _permissionService: PermissionService) {
    }

    public ngOnInit(): void {
        this._permissionService.hasPermission(this._permission)
            .pipe(takeUntil(this._destroy$))
            .subscribe(hasPermission => {
                // please note that this directive only disables form, to avoid problems with restoring individual control state
                if (!hasPermission) {
                    this._formGroupDirective.form.disable();
                }
            });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}