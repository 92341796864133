<form [formGroup]="form"
      (ngSubmit)="createUpdate()">

    <div class="p-fluid formgrid grid">
        <div class="field col-12  mt-3">
            <label for="name"
                   class="font-bold sc-required-input"
                   i18n>Project Name</label>
            <input scValidatable
                   id="name"
                   type="text"
                   pInputText
                   formControlName="name"
                   placeholder="Type project name"
                   i18n-placeholder>
        </div>
        <div class="field col-12 mt-2"
             *ngIf="isCreate">
            <label for="title"
                   class="font-bold sc-required-input"
                   i18n>ISBN</label>
            <p-autoComplete scValidatable
                            appendTo="body"
                            formControlName="title"
                            [multiple]="false"
                            [suggestions]="filteredTitles$ | async"
                            [forceSelection]="true"
                            [required]="true"
                            [minLength]="1"
                            [autoZIndex]="true"
                            [showEmptyMessage]="true"
                            (completeMethod)="filterTitles($event)"
                            dataKey="id"
                            field="name"
                            placeholder="ISBN or Title"
                            i18n-placeholder>
            </p-autoComplete>
        </div>
    </div>
    <p-button scSubmitButton
              [requestInProcess]="requestInProcess"
              class="flex justify-content-center mt-3"
              [label]="isCreate ? 'Create' : 'Save'"
              i18n-label>
    </p-button>
</form>
