import { Component, OnDestroy } from '@angular/core';

import { first, map, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { ApiContentEditor, ApiContentEditorListItem, ApiMember, ContentEditorsClient, MembersClient } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { ProjectStateService } from 'content-manager/src/app/projects/project/project-state.service';
import { ScConfirmationService, TableSettings } from 'sc-common';

@Component({
    templateUrl: './project-content-editors.component.html'
})
export class ProjectContentEditorsComponent implements OnDestroy {

    public readonly tableSettings: TableSettings<ApiContentEditor>;

    public readonly isReadonlyWrapper$: Observable<{ isReadonly: boolean; }>;

    public filteredMembers$: Observable<ApiMember[]>;

    private _memberIds: number[] = [];

    private readonly _query$ = new Subject<string>();

    private readonly _refreshTable$ = new Subject<void>();

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _confirmationService: ScConfirmationService,
        private readonly _projectStateService: ProjectStateService,
        private readonly _contentEditorsClient: ContentEditorsClient,
        private readonly _membersClient: MembersClient) {

        this.isReadonlyWrapper$ = _projectStateService.model$.pipe(map(project => ({ isReadonly: project.isReadonly })));

        this.tableSettings = this._initTable();

        this.filteredMembers$ = this._query$
            .pipe(switchMap(query => this._membersClient.findMembers(query).pipe(map(foundMembers => foundMembers.filter(m => !this._memberIds.includes(m.id))))));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._refreshTable$.complete();
    }

    public filterMembers(query: string): void {
        if (!query) {
            return;
        }

        this._query$.next(query);
    }

    public addContentEditor(member: ApiMember): void {
        this._projectStateService.model$
            .pipe(
                first(),
                switchMap(project => {
                    const model = new ApiContentEditor({
                        memberId: member.id,
                        firstName: member.firstName,
                        lastName: member.lastName,
                        email: member.email
                    });
                    return this._contentEditorsClient.add(project.id, model);
                }),
                takeUntil(this._destroy$))
            .subscribe(() => this._refreshTable$.next());
    }

    public deleteContentEditor(memberId: number): void {
        this._confirmationService.confirm({
            width: '30vw',
            title: $localize`Delete Content Editor`,
            message: $localize`Are you sure to delete this content editor?`,
            acceptLabel: $localize`Delete`,
            accept$: this._projectStateService.firstSwitchMap(project => this._contentEditorsClient.delete(project.id, memberId)),
            acceptCallback: () => {
                this._refreshTable$.next();
            }
        });
    }

    private _initTable(): TableSettings<ApiContentEditorListItem> {
        return TableSettings.create(ApiContentEditorListItem, {
            dataSource: oDataQuery => this._projectStateService.switchMap(project => this._contentEditorsClient.getList(project.id, oDataQuery))
                .pipe(tap(x => this._memberIds = x.items.map(item => item.memberId))),
            actionsCount: 1,
            columnResizeMode: 'fit',
            paginator: false,
            hasFilters: false,
            emptyMessageText: $localize`No content editors`,
            reload$: this._refreshTable$.asObservable(),
            columns: {
                memberId: {
                    hidden: true
                }
            },
            defaultSort: {
                field: x => x.memberId,
                order: 'asc'
            }
        });
    }
}
