import { Component } from '@angular/core';

import { ServerResponseService } from 'sc-external/shared/components/error/server-response.service';

@Component({
    templateUrl: './403-forbidden.component.html'
})
export class ForbiddenComponent {

    constructor(serverResponseService: ServerResponseService) {
        serverResponseService.setStatus(403, 'Access denied');
    }
}
