<ng-template #displayTemplateRef
             let-cellData
             let-rowData="rowData">
    <ng-container *ngIf="checkHasValue(cellData)">

        <span *ngIf="!isClickable(rowData) else clickable"
              [innerHTML]="getCellValue(cellData) | safeMessage:'html'">
        </span>

        <ng-template #clickable>
            <ng-container *ngIf="external; else internal">

                <a *ngIf="hasIcon"
                   [href]="linkExpr(rowData, cellData)"
                   target="_blank">
                    <i class="fas fa-link mr-2"></i>
                </a>

                <a scOverflowTooltip
                   [href]="linkExpr(rowData, cellData)"
                   target="_blank"
                   [innerHTML]="getCellValue(cellData) | safeMessage:'html'">
                </a>
            </ng-container>

            <ng-template #internal>

                <a *ngIf="hasIcon"
                   [routerLink]="linkExpr(rowData, cellData)">
                    <i class="fas fa-link mr-2"></i>
                </a>

                <a scOverflowTooltip
                   [routerLink]="linkExpr(rowData, cellData)"
                   [state]="getFilterParams(rowData)"
                   [innerHTML]="getCellValue(cellData) | safeMessage:'html'">
                </a>
            </ng-template>
        </ng-template>
    </ng-container>
</ng-template>