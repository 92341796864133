import { buildEnumMapProvider } from 'sc-common';
import { PaperStatusEnum } from 'sc-common/core/services/open-api/main-api-client';

export const paperStatusEnumMapProvider = buildEnumMapProvider(PaperStatusEnum, getEnumText, getStatusStyle);

export function getEnumText(value: PaperStatusEnum): string {

    switch (value) {

        case PaperStatusEnum.Accepted:
            return $localize`Accepted`;

        case PaperStatusEnum.Editorial:
            return $localize`Editorial`;

        case PaperStatusEnum.InReview:
            return $localize`In Review`;

        case PaperStatusEnum.Rejected:
            return $localize`Rejected`;

        case PaperStatusEnum.Retracted:
            return $localize`Retracted`;

        case PaperStatusEnum.Revised:
            return $localize`Revised`;

        case PaperStatusEnum.Revisions:
            return $localize`Revisions`;

        case PaperStatusEnum.Submitted:
            return $localize`Submitted`;

        case PaperStatusEnum.Uploaded:
            return $localize`Uploaded`;

        case PaperStatusEnum.Retracted:
            return $localize`Retracted`;
    }
}

export function getStatusStyle(status: PaperStatusEnum): string {
    switch (status) {
        case PaperStatusEnum.Accepted:
            return 'et-paper-status-accepted';

        case PaperStatusEnum.Editorial:
            return 'et-paper-status-editorial';

        case PaperStatusEnum.InReview:
            return 'et-paper-status-in-review';

        case PaperStatusEnum.Rejected:
            return 'et-paper-status-rejected';

        case PaperStatusEnum.Revisions:
            return 'et-paper-status-revisions';

        case PaperStatusEnum.Submitted:
            return 'et-paper-status-submitted';

        case PaperStatusEnum.Uploaded:
            return 'et-paper-status-uploaded';

        case PaperStatusEnum.Revised:
            return 'et-paper-status-revised';

        case PaperStatusEnum.Retracted:
            return 'et-paper-status-retracted';

        default: return '';
    }
}
