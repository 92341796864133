import { Component } from '@angular/core';

import { RendererBaseComponent } from 'sc-common';

@Component({
    templateUrl: 'authors-renderer.component.html'
})
export class AuthorsRendererComponent extends RendererBaseComponent {

    public action: (memberId: number) => void;

    constructor() {
        super();
    }

    public filterByAuthor(event: Event, memberId: number): void {
        event.preventDefault();

        this.action(memberId);
    }
}
