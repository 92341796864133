import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ENV_TOKEN, IEnvironment } from 'sc-common/core/models/environment';

@Injectable()
export class SetBaseUrlInterceptor implements HttpInterceptor {

    constructor(@Inject(ENV_TOKEN) private readonly _env: IEnvironment) {
    }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        if (request.url.startsWith('/api')) {

            const modifiedRequest = request.clone({ url: `${ this._env.apiUrl }${ request.url }` });

            return next.handle(modifiedRequest);

        } else {
            return next.handle(request);
        }
    }
}
