export enum MatchMode {
    equals = 'equals',
    contains = 'contains',
    notContains = 'notContains',
    startsWith = 'startsWith',
    endsWith = 'endsWith',
    greater = 'gt',
    less = 'lt',
    greaterOrEquals = 'gte',
    lessOrEquals = 'lte',
    in = 'in',
    notIn = 'notIn',
    notEquals = 'notEquals',
    between = 'between',
    empty = 'empty',
    notEmpty = 'notEmpty',
    isTrue = 'isTrue',
    isFalse = 'isFalse'
}
