<div class="flex justify-content-between sc-comment">
    <div class="flex flex-column">
        <span class="title4Font mb-1">{{commentData.comment.createdBy.name}}</span>
        <span *ngIf="!isEditMode"
              class="body2Font sc-comment-description">{{commentData.comment.description}}</span>
    </div>
    <div class="flex flex-column align-items-end body2Font sc-comment-date">
        <span>{{commentData.comment.createdDateTime | date: 'y-m-d'}}</span>
        <span>{{commentData.comment.createdDateTime | date: 'time'}}</span>
        <div *ngIf="isActionsButtonsVisible"
             class="flex justify-content-end mt-2">
            <i class="fas fa-pen"
               (click)="showEditForm()"></i>
            <i class="fas fa-trash ml-3"
               (click)="deleteComment()"></i>
        </div>
    </div>
</div>

<div *ngIf="isEditMode"
     class="mt-2">
    <form [formGroup]="form"
          (ngSubmit)="editComment()">

        <div class="flex flex-column">
            <div class="field p-fluid">
                <textarea inputId="textarea"
                          [autoResize]="true"
                          scValidatable
                          pInputTextarea
                          placeholder="Write your comments..."
                          [rows]="3"
                          formControlName="description"
                          i18n-placeholder></textarea>
            </div>
            <div class="align-self-end mt-1">
                <p-button icon="pi pi-times"
                          class="mr-2"
                          styleClass="p-button-rounded p-button-outlined"
                          (onClick)="hideEditForm()">
                </p-button>

                <p-button scSubmitButton
                          [requestInProcess]="requestInProcess"
                          icon="pi pi-check"
                          styleClass="p-button-rounded p-button-outlined p-button-success">
                </p-button>
            </div>
        </div>
    </form>
</div>