import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { AutoComplete } from 'primeng/autocomplete';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiGridOrganization, GridClient } from 'sc-common/core/services/open-api/shared-api-client';

@Component({
    selector: 'sc-grid-autocomplete',
    templateUrl: './grid-autocomplete.component.html'
})
export class GridAutocompleteComponent implements ControlValueAccessor, OnDestroy {

    @Input()
    public label: string;

    @Input()
    public required = false;

    @Input()
    public placeholder: string;

    @Input()
    public floatLabel = true;

    @Output()
    public readonly organizationSelected = new EventEmitter<ApiGridOrganization>();

    public filteredGridOrganizations: ApiGridOrganization[];

    private readonly _destroy$ = new Subject<void>();

    @ViewChild('gridAutocomplete', { static: true })
    private readonly _autocomplete: AutoComplete;

    constructor(public control: NgControl, private readonly _gridClient: GridClient) {
        control.valueAccessor = this;
    }

    public ngOnDestroy(): void {

        this._destroy$.next();
        this._destroy$.complete();
    }

    public writeValue(value: any): void {
        this._autocomplete.writeValue({ name: value });
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public registerOnChange(fn: Function): void {
        this._autocomplete.registerOnChange((value: any) => {

            if (value instanceof ApiGridOrganization) {
                fn(value?.name);
            } else {
                fn(value);
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public registerOnTouched(fn: Function): void {
        this._autocomplete.registerOnTouched(fn);
    }

    public setDisabledState(val: boolean): void {
        this._autocomplete.setDisabledState(val);
    }

    public filterGridOrganizations(event: { query: string; originalEvent: any; }): void {

        if (!event.query) {
            return;
        }

        this._gridClient.findGridOrganizations(event.query)
            .pipe(takeUntil(this._destroy$))
            .subscribe(data => {
                this.filteredGridOrganizations = data;
            });
    }

    public onOrganizationSelect(value: ApiGridOrganization): void {
        this.organizationSelected.emit(value);
    }
}
