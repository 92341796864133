import { ComponentRef, Directive, ElementRef, Injector, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';

import { ValidationErrorsLabelComponent } from 'sc-common/shared/validatable/validation-errors-label.component';
import { ValidationErrorsTooltipComponent } from 'sc-common/shared/validatable/validation-errors-tooltip.component';

@Directive({
    selector: '[scValidatable]'
})
export class ValidatableControlDirective implements OnInit, OnDestroy {

    @Input()
    public set scLabelText(value: string) {

        if (this._errorsComponent?.instance && this._elRef) {

            this._errorsComponent.instance.label = value ??
                (this._elRef.nativeElement?.labels ?
                    this._elRef.nativeElement?.labels.length
                        ? (Array.from(this._elRef.nativeElement?.labels as HTMLLabelElement[]).map(x => x)[0].innerText)
                        : null
                    : null);
        }
    }

    @Input()
    public scValidatableTooltip = false;

    private _errorsComponent: ComponentRef<ValidationErrorsLabelComponent | ValidationErrorsTooltipComponent>;

    constructor(
        private readonly _elRef: ElementRef,
        private readonly _vcr: ViewContainerRef,
        private readonly _injector: Injector) {
    }

    public ngOnInit(): void {

        if (this.scValidatableTooltip) {
            this._errorsComponent = this._vcr.createComponent(ValidationErrorsTooltipComponent, { injector: this._injector });
        } else {
            this._errorsComponent = this._vcr.createComponent(ValidationErrorsLabelComponent, { injector: this._injector });
        }
    }

    public ngOnDestroy(): void {

        this._errorsComponent?.destroy();
    }
}
