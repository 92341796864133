<ng-template #templateRef
             let-columns>
    <tr *ngIf="tableSettings.hasHeaders">
        <th *ngIf="tableSettings.selectionMode"
            class="sc-selectable-column">
            <p-checkbox *ngIf="canSelect"
                        [binary]="true"
                        [ngModel]="allCheckBoxState$ | async"
                        (onChange)="stateService.togglePageSelection($event)">
            </p-checkbox>
        </th>

        <th *ngIf="tableSettings.reordering?.separateColumn"
            class="sc-reordering-column">
        </th>

        <ng-container *ngFor="let column of columns; trackBy: trackByFunc">

            <th pReorderableColumn
                [ngClass]="{ 'sc-row-reorder-column': column.isReorderable, 'sc-reorder-table-header': canReorderRows, 'justify-content-center': column.settings.centered }"
                [pReorderableColumnDisabled]="!tableSettings.reorderableColumns"
                pResizableColumn
                [pResizableColumnDisabled]="!tableSettings.resizableColumns"
                [pSortableColumn]="column.sortableFieldPath"
                [pSortableColumnDisabled]="!column.sortable || canReorderRows"
                [ngStyle]="column.headerStyle">

                <p-sortIcon *ngIf="column.sortable && !canReorderRows"
                            [field]="column.sortableFieldPath"></p-sortIcon>

                <span scOverflowTooltip>{{ column.header }}</span>

                <a *ngIf="column.settings.helpTemplateRef as helpTemplateRef"
                   class="ml-2">
                    <p-overlayPanel #overlayPanel
                                    styleClass="sc-overlay-panel"
                                    appendTo="body">
                        <ng-template pTemplate>
                            <ng-container *ngTemplateOutlet="helpTemplateRef()"></ng-container>
                        </ng-template>
                    </p-overlayPanel>

                    <i class="fas fa-question-circle"
                       (click)="overlayPanel.show($event)"></i>
                </a>
            </th>
        </ng-container>
        <th *ngIf="hasActionsColumn()"
            class="sc-actions-column sc-actions-column-{{tableSettings.actionsCount}}">
        </th>
    </tr>
    <tr *ngIf="tableSettings.hasFilters && !canReorderRows"
        class="sc-table-filter-row">
        <th *ngIf="tableSettings.selectionMode"
            class="sc-selectable-column">
        </th>
        <th *ngFor="let column of columns; trackBy: trackByFunc"
            [ngStyle]="column.headerStyle">
            <ng-container *ngIf="column.filterTemplate">
                <ng-container *ngTemplateOutlet="column.filterTemplate; context: {$implicit: column}"></ng-container>
            </ng-container>
        </th>
        <th *ngIf="hasActionsColumn()"
            class="sc-actions-column sc-actions-column-{{tableSettings.actionsCount}}">
        </th>
    </tr>
</ng-template>