import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { SelectFilterItem } from 'sc-common/core/models/select-filter-item';
import { FilterBaseComponent } from 'sc-common/shared/table/header/filters/filter-base.component';
import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Component({
    templateUrl: 'multiselect-filter.component.html'
})
export class MultiselectFilterComponent extends FilterBaseComponent implements OnInit, OnDestroy {

    public readonly placeholder = $localize`Select`;

    public multiselectFilterValues: SelectFilterItem[] = [];

    public itemsSource$: Observable<SelectFilterItem[]>;

    public searchEnabled = false;

    public itemsSource: SelectFilterItem[] = [];

    protected readonly _destroy$ = new Subject<void>();

    constructor(
        protected readonly tableStateService: TableStateService,
        protected readonly _tableColumn: TableColumn) {

        super(tableStateService, _tableColumn);

        this.matchMode = MatchMode.in;
    }

    public ngOnInit(): void {

        const filterValueArray = Array.isArray(this.filterValue) ? this.filterValue : [this.filterValue];

        if (this.itemsSource$ && this._tableColumn.settings.useBlankFilterItem) {

            this.itemsSource$ = this.itemsSource$
                .pipe(map(values => [this.filterBlankItem, ...values]));
        }

        if (filterValueArray && this.itemsSource$) {

            this.itemsSource$ = this.itemsSource$
                .pipe(
                    tap(values =>
                        this.multiselectFilterValues = values.filter(v => filterValueArray.includes(v.id))
                    ));
        }

        this.itemsSource$
            .pipe(takeUntil(this._destroy$))
            .subscribe(source => this.itemsSource = source);
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public filter(): void {

        this.filterValue = this.multiselectFilterValues.map(x => x.id);

        super.filter();
    }

    public cleanFilter(): void {

        this.multiselectFilterValues = [];

        super.cleanFilter();
    }
}
