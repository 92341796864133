import { Component } from '@angular/core';

import { ApiPaperPlagiarismReportListItem, PaperPlagiarismCheckStatusEnum } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { RendererBaseComponent } from 'sc-common';

@Component({
    templateUrl: './plagiarism-check-result-renderer.component.html'
})
export class PlagiarismCheckResultRendererComponent extends RendererBaseComponent {

    public folderPath: () => string;

    constructor() {
        super();
    }

    public getLink(item: ApiPaperPlagiarismReportListItem): string {

        switch (item.status) {
            case PaperPlagiarismCheckStatusEnum.Checked:
                return item.iThenticateDocumentPath;

            case PaperPlagiarismCheckStatusEnum.Failed:
                return this.folderPath();

            case PaperPlagiarismCheckStatusEnum.Unchecked:
                return '';
        }
    }

    public getLinkText(item: ApiPaperPlagiarismReportListItem): string {

        switch (item.status) {
            case PaperPlagiarismCheckStatusEnum.Checked:
                return `${ item.checkResult }%`;

            case PaperPlagiarismCheckStatusEnum.Failed:
                return $localize`Error while uploading`;

            case PaperPlagiarismCheckStatusEnum.Unchecked:
                return '';
        }
    }
}
