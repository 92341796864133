import { Directive, Host, HostListener, Self } from '@angular/core';

import { Calendar } from 'primeng/calendar';

@Directive({
    selector: '[scCalendarUtc]'
})
export class CalendarUtcDirective {

    constructor(@Host() @Self() private readonly _calendar: Calendar) { }

    @HostListener('onSelect', ['$event']) public onSelect(): void {
        this._toUtc();
    }

    @HostListener('onInput', ['$event']) public onInput(): void {
        this._toUtc();
    }

    private _toUtc(): void {

        if (!this._calendar.value) {
            return;
        }

        this._calendar.value = new Date(Date.UTC(this._calendar.value.getFullYear()
            , this._calendar.value.getMonth()
            , this._calendar.value.getDate()
            , 0, 0, 0));
        this._calendar.updateModel(this._calendar.value);
    }
}
