<ng-template #templateRef>
    <div class="p-inputgroup">
        <p-dropdown #dropdownRef
                    [options]="itemsSource$ | async"
                    [(ngModel)]="dropdownFilterValue"
                    (onChange)="filter()"
                    [filter]="dropdownRef.options?.length > 5"
                    dataKey="id"
                    optionLabel="name"
                    appendTo="body"
                    [placeholder]="placeholder">
        </p-dropdown>
        <button *ngIf="isFilterApplied"
                pButton
                type="button"
                icon="pi pi-times"
                (click)="cleanFilter()">
        </button>
    </div>
</ng-template>