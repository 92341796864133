import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';

import { PermissionsInterceptor } from 'sc-common/core/interceptors/permissions/permissions.interceptor';
import { IdentityInterceptor } from 'sc-common/core/services/identity/identity-interceptor';
import { IdentityLoggerService } from 'sc-common/core/services/identity/identity-logger.service';
import { IdentityService } from 'sc-common/core/services/identity/identity.service';
import { PermissionService } from 'sc-common/core/services/identity/permission.service';

const ALLOWED_URLS = new InjectionToken<string>('ALLOWED_URLS');

@NgModule()
export class IdentityModule {
    public static forRoot(allowedUrls: string[]): ModuleWithProviders<IdentityModule> {

        return {
            ngModule: IdentityModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: IdentityInterceptor,
                    multi: true
                },

                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: PermissionsInterceptor,
                    multi: true
                },
                {
                    provide: ALLOWED_URLS,
                    useValue: allowedUrls
                },
                IdentityLoggerService,
                IdentityService,
                PermissionService
            ]
        };
    }
}
