import { Component, OnDestroy } from '@angular/core';

import { DialogService } from 'primeng/dynamicdialog';

import { combineLatest, filter, map, Observable, of, Subject, takeUntil } from 'rxjs';

import { ApiChapterTopicListItem, ApiProjectTopicListItem, ChaptersClient, ProjectDetailsClient, TopicsClient } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { ProjectStateService } from 'content-manager/src/app/projects/project/project-state.service';
import { IProjectUpdateTopicsDialogData } from 'content-manager/src/app/projects/project/topics/update-topics-dialog/project-update-topics-dialog-data';
import { ProjectUpdateTopicsDialogComponent } from 'content-manager/src/app/projects/project/topics/update-topics-dialog/project-update-topics-dialog.component';
import { Filters, TableSettings } from 'sc-common';

@Component({
    templateUrl: './project-topics.component.html'
})
export class ProjectTopicsComponent implements OnDestroy {

    public readonly tableModel$: Observable<{ isReadonly: boolean; tableSettings: TableSettings<any>; hasChapters: boolean; }>;

    private readonly _refreshTable$ = new Subject<void>();

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _projectStateService: ProjectStateService,
        private readonly _topicsClient: TopicsClient,
        private readonly _chaptersClient: ChaptersClient,
        private readonly _projectsClient: ProjectDetailsClient,
        private readonly _dialogService: DialogService) {

        this.tableModel$ = combineLatest([
            _projectStateService.model$,
            this._projectStateService.switchMap(project => this._chaptersClient.hasChapters(project.id))])
            .pipe(map(([project, hasChapters]) => ({
                isReadonly: project.isReadonly,
                hasChapters: hasChapters,
                tableSettings: hasChapters ? this._initChapterTopicsTable() : this._initProjectTopicsTable()
            })));

    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._refreshTable$.complete();
    }

    public openProjectTopicsDialog(): void {
        const dialogData: IProjectUpdateTopicsDialogData = {
            topicRoots$: this._topicsClient.getTopics(),
            existingTopicsIds$: this._projectStateService.firstSwitchMap(project => this._projectsClient.getTopicIdList(project.id)),
            updateAction: topicIds => this._projectStateService.firstSwitchMap(project => this._projectsClient.updateTopics(project.id, topicIds))
        };

        this._openTopicsDialog(dialogData);
    }

    public openChapterTopicsDialog(chapter: ApiChapterTopicListItem): void {

        const dialogData: IProjectUpdateTopicsDialogData = {
            topicRoots$: this._topicsClient.getTopics(),
            existingTopicsIds$: of(chapter.topics.map(t => t.id)),
            updateAction: topicIds => this._projectStateService.firstSwitchMap(project => this._chaptersClient.updateTopics(project.id, chapter.id, topicIds))
        };

        this._openTopicsDialog(dialogData);
    }

    private _openTopicsDialog(dialogData: IProjectUpdateTopicsDialogData): void {

        const dialogRef = this._dialogService.open(ProjectUpdateTopicsDialogComponent, {
            width: '30vw',
            header: $localize`Update Topics`,
            data: dialogData
        });

        dialogRef.onClose
            .pipe(filter(r => r), takeUntil(this._destroy$))
            .subscribe(() => this._refreshTable$.next());
    }

    private _initChapterTopicsTable(): TableSettings<ApiChapterTopicListItem> {
        const chapterList$ = this._projectStateService.switchMap(project => this._chaptersClient.getAll(project.id));

        return TableSettings.create(ApiChapterTopicListItem, {
            dataSource: oDataQuery => this._projectStateService.switchMap(project => this._chaptersClient.getTopicList(project.id, oDataQuery)),
            columnResizeMode: 'fit',
            actionsCount: 1,
            emptyMessageText: $localize`No chapters`,
            reload$: this._refreshTable$.asObservable(),
            columns: {
                pos: {
                    width: '100px',
                    centered: true
                },
                chapter: {
                    filter: Filters.multiselect(chapterList$.pipe(map(x => x.map(k => ({ id: k.name, name: k.name })))))
                },
                topics: {
                    displayExpr: item => item.name,
                    filterExpr: item => item.name,
                    renderAsCommaSeparatedList: true
                }
            },
            defaultSort: {
                field: x => x.pos
            }
        });
    }

    private _initProjectTopicsTable(): TableSettings<ApiProjectTopicListItem> {
        return TableSettings.create(ApiProjectTopicListItem, {
            dataSource: oDataQuery => this._projectStateService.switchMap(project => this._projectsClient.getTopicList(project.id, oDataQuery)),
            columnResizeMode: 'fit',
            emptyMessageText: $localize`No topics`,
            reload$: this._refreshTable$.asObservable()
        });
    }
}
