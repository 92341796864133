import { Observable } from 'rxjs';

export class ScConfirmationData {

    public header?: string;

    public title?: string;

    public message: string;

    public acceptLabel: string;

    public rejectLabel?: string = $localize`Cancel`;

    public rejectVisible?= true;

    public width?= '20vw';

    public accept$?: Observable<any>;

    public acceptCallback?: (r?: any) => void;

    public rejectCallback?: () => void;
}
