<div class="flex align-items-center sc-download-box cursor-pointer"
     (click)="download()">
    <div class="w-3rem">
        <i *ngIf="!requestInProcess"
           [ngClass]="[fileIconClass]">
        </i>

        <p-progressSpinner *ngIf="requestInProcess"
                           styleClass="sc-spinner-small">
        </p-progressSpinner>
    </div>
    <div *ngIf="fileInfo.createdDateTime && fileInfo.size"
         class="flex flex-column white-space-nowrap">
        <div>
            <span class="subtitle3Font">{{ fileInfo.createdDateTime | date:'y-m-d' }}</span>
            <span class="body2Font ml-1 sc-time">{{ fileInfo.createdDateTime | date:'time' }}</span>
        </div>
        <div class="body2Font sc-size">
            <span>{{ fileInfo.size | fileSize }}</span>
        </div>
    </div>
</div>