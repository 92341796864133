function getNewProxy(pathObj: { path: string; }): any {

    return new Proxy({},
        {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            get(_, prop): any {

                pathObj.path += `.${ prop as string }`;

                return getNewProxy(pathObj);
            }
        }
    );
}

export function getExpressionPath<T>(func: (x: T) => any): string {

    if (!func) { return null; }

    const pathObj = { path: '' };

    const proxy = getNewProxy(pathObj);

    func(proxy);

    return pathObj.path;
}

export function getExpressionField<T>(func: (x: T) => any): string {

    return getExpressionPath(func)?.substring(1);
}

export function getExpressionFieldPath<T>(func: (x: T) => any): string[] {

    return getExpressionPath(func)?.split('.');
}
