<div *ngIf="filteredByAuthor$ | async as author"
     class="mt-3"
     i18n>
    Filtered by Author:
    <i>"{{ author }}"</i>&nbsp;
    <a href="#"
       (click)="clearFilterByAuthor($event)">Cancel</a>
</div>
<p-table *ngIf="(tableSettings$ | async) as tableSettings"
         [scTableSettings]="tableSettings"
         class="mt-3">
</p-table>