<div class="mt-3"
     *ngIf="apiModel$ | async as detail">
    <p-card>
        <div class="grid">
            <div class="title4Font mb-2 pl-4 col-9"
                 i18n>{{detail.titleName}}</div>
            <div class="subtitle3Font col-3 pl-8">
                <div class="mb-2 flex justify-content-start"
                     i18n>ISBN: {{detail.titleIsbn}}</div>
                <div i18n
                     class="flex justify-content-start">Journal: {{detail.kurzel}}</div>
            </div>
            <ng-container *ngIf="(isReadonly$ | async) === false">
                <div class="col-12 pl-3"
                     *ngIf="actionPermission$ | async as actionPermission">
                    <p-button *ngIf="actionPermission.isManager"
                              (onClick)="applyChanges()"
                              label="Apply Changes"
                              i18n-label>
                    </p-button>
                    <p-button class="ml-3"
                              label="Generate Report"
                              i18n-label
                              styleClass="p-button-outlined">
                    </p-button>
                    <p-button *ngIf="actionPermission.isManager"
                              (onClick)="return()"
                              class="ml-3"
                              label="Return to Editors"
                              i18n-label
                              styleClass="p-button-outlined">
                    </p-button>
                    <p-button *ngIf="actionPermission.isManager"
                              (onClick)="cancel()"
                              class="ml-3"
                              label="Cancel Project"
                              i18n-label
                              styleClass="p-button-outlined">
                    </p-button>
                </div>
            </ng-container>
        </div>
    </p-card>
</div>