<p-card>
    <ng-template pTemplate="header">
        <div class="flex justify-content-end p-3">
            <p-button icon="pi pi-times"
                      styleClass="p-button-rounded p-button-text sc-grey"
                      (onClick)="hide()">
            </p-button>
        </div>
    </ng-template>
    <div class="flex flex-column align-items-center py-5">
        <div class="title3Font"
             i18n>
            Welcome to Content Manager!
        </div>
    </div>
</p-card>
