import { Type } from '@angular/core';

import 'reflect-metadata';

const mtKey = Symbol('sc:columnInfo');

export type ColumnMetadataValue = { typeRef: () => Type<any> | object; isArray: boolean; order: number; };

export function columnMap(modelTypeRef: () => (Type<any> | object), isArray = false): (target: any, propertyKey: string) => void {
    return (target: any, propertyKey: string) => {

        const mtVal: ColumnMetadataValue = { typeRef: modelTypeRef, isArray, order: Object.getOwnPropertyNames(target).length };

        Reflect.defineMetadata(mtKey, mtVal, target, propertyKey);

        if (!Object.getOwnPropertyDescriptor(target, propertyKey)) {
            Object.defineProperty(target, propertyKey, {
                enumerable: isArray,
                configurable: true,
                writable: true
            });
        }
    };
}

export function hasColumnMap(target: any, propertyKey: string): boolean {

    return Reflect.hasMetadata(mtKey, target, propertyKey);
}

export function getColumnMap(target: any, propertyKey: string): ColumnMetadataValue {

    return Reflect.getMetadata(mtKey, target, propertyKey);
}
