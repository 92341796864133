<p-table *ngIf="(tableModel$ | async) as tableModel"
         [scTableSettings]="tableModel.tableSettings"
         class="mt-3">
    <ng-template *ngIf="!tableModel.isReadonly"
                 pTemplate="tools-panel-right">
        <p-button label="Create new chapter"
                  i18n-label
                  (onClick)="newChapterDialog()"
                  styleClass="p-button-outlined">
        </p-button>
    </ng-template>
    <ng-template *ngIf="!tableModel.isReadonly"
                 pTemplate="row-actions"
                 let-rowData>
        <i class="fas fa-trash"
           (click)="deleteChapter(rowData.id)"
           pTooltip="Click to delete chapter"
           tooltipPosition="top"
           i18n-pTooltip></i>
    </ng-template>
</p-table>