import { Component, TemplateRef } from '@angular/core';

import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';

@Component({
    templateUrl: 'actions-renderer.component.html'
})
export class ActionsRendererComponent extends RendererBaseComponent {

    public actionsTemplate: TemplateRef<any>;

    constructor() {
        super();
    }
}
