import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';

import { LoggingService } from 'sc-common/core/services/logging/logging.service';
@Injectable(
    {
        providedIn: 'root'
    })
export class GlobalErrorHandler implements ErrorHandler {
    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor(@Inject(PLATFORM_ID) private readonly _platformId: object, private readonly _injector: Injector) { }

    public handleError(error: Error): void {
        console.error(error);

        if (!isPlatformBrowser(this._platformId)) {
            return;
        }

        const logger = this._injector.get(LoggingService);

        logger.logException(error);
    }
}
