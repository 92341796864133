import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileHref'
})
export class FileHrefPipe implements PipeTransform {

    public transform(value: string): string {
        return 'file:///\\' + value;
    }
}
