import { Component, OnDestroy } from '@angular/core';

import { BehaviorSubject, combineLatest, map, Observable, of, Subject, switchMap } from 'rxjs';

import { ApiChapter, ApiColor, ApiPaperListItem, ChaptersClient, ColorsClient, EditorChaptersClient, PaperNotesClient, PapersClient } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { ProjectPapersRenderers } from 'content-manager/src/app/projects/project/papers/project-papers-renderers';
import { ProjectStateService } from 'content-manager/src/app/projects/project/project-state.service';
import { ApiNote, Filters, NoteTypeEnum, PageResult, Renderers, TableSettings } from 'sc-common';
import { ICommentsData } from 'sc-common/shared/components/comments-dialog/comments-dialog-data';
import { ExternalRenderers } from 'sc-external/shared/table/renderers/renderers';

@Component({
    templateUrl: './project-papers.component.html'
})
export class ProjectPapersComponent implements OnDestroy {

    public readonly tableSettings$: Observable<TableSettings<ApiPaperListItem>>;

    public unassignedPapersCount: number;

    public filteredByAuthor$: Observable<string>;

    private readonly _memberId$ = new BehaviorSubject<number | null>(null);

    private readonly _refreshTable$ = new Subject<any>();

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _projectStateService: ProjectStateService,
        private readonly _papersClient: PapersClient,
        private readonly _chaptersClient: ChaptersClient,
        private readonly _editorChaptersClient: EditorChaptersClient,
        private readonly _paperNotesClient: PaperNotesClient,
        private readonly _colorsClient: ColorsClient) {

        this.tableSettings$ = _projectStateService.model$.pipe(map(project => this._initTable(project.isReadonly)));
        this.filteredByAuthor$ = combineLatest([this._projectStateService.model$, this._memberId$])
            .pipe(
                switchMap(([project, memberId]) => memberId ? this._papersClient.getAuthor(project.id, memberId) : of(null)),
                map(author => author?.fullName));
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._refreshTable$.complete();
        this._memberId$.complete();
    }

    public clearFilterByAuthor(event: Event): void {
        event.preventDefault();
        this._memberId$.next(null);
    }

    private _initTable(isReadonly: boolean): TableSettings<ApiPaperListItem> {
        const chapterList$ = this._projectStateService.switchMap(project => this._chaptersClient.getAll(project.id));
        const editorChapterList$ = this._projectStateService.switchMap(project => this._editorChaptersClient.getAll(project.id));
        const colorList$ = this._colorsClient.list();
        const yesNoList$ = of([
            { id: true, name: $localize`Yes` },
            { id: false, name: $localize`No` }
        ]);

        return TableSettings.create(ApiPaperListItem, {
            dataSource: oDataQuery => this._getDataSource(oDataQuery),
            columnResizeMode: 'fit',
            paginator: false,
            hasFilters: true,
            emptyMessageText: $localize`No papers`,
            reload$: this._refreshTable$.asObservable(),
            rowStyler: item => item.color != null ? { 'background-color': item.color.value } : null,
            columns: {
                rowNumber: {
                    header: $localize`#`,
                    sortable: false,
                    filterable: false,
                    width: '40px'
                },
                name: {
                    header: $localize`Paper Name`,
                    renderer: Renderers.rawHtml(),
                    sortable: false,
                    wrap: true
                },
                pdf: {
                    header: $localize`PDF`,
                    renderer: ExternalRenderers.fileDownload((model: ApiPaperListItem) =>
                        this._projectStateService.firstSwitchMap((project => this._papersClient.getPdf(project.id, model.id)))),
                    width: '60px',
                    sortable: false,
                    filterable: false
                },
                editorChapter: {
                    header: $localize`Editor's Chapters`,
                    sortable: false,
                    displayExpr: x => x?.name,
                    useBlankFilterItem: true,
                    filter: Filters.multiselect(editorChapterList$.pipe(map(x => x.map(k => ({ id: k.id, name: k.name }))))),
                    filterExpr: x => x.id
                },
                chapter: {
                    header: $localize`Chapters`,
                    renderer: isReadonly
                        ? null
                        : ExternalRenderers.dropdown(chapterList$, (x: ApiChapter) => x?.name, 'id', 'name', null, true, $localize`Choose chapter`),
                    editable: isReadonly
                        ? null
                        : {
                            callback: (_, rowData) => this._projectStateService.switchMap(project =>
                                rowData.chapter
                                    ? this._papersClient.assignChapter(project.id, rowData.id, rowData.chapter.id)
                                    : this._papersClient.unassignChapter(project.id, rowData.id)),
                            reloadRequired: true
                        },
                    sortable: false,
                    useBlankFilterItem: true,
                    filter: Filters.multiselect(chapterList$.pipe(map(x => x.map(k => ({ id: k.id, name: k.name }))))),
                    filterExpr: x => x.id,
                    displayExpr: x => x.name
                },
                isRejected: {
                    renderer: isReadonly
                        ? Renderers.boolean(true)
                        : ExternalRenderers.dropdown(yesNoList$, (x: boolean) => x ? $localize`Yes` : $localize`No`, 'id', 'name', 'id'),
                    editable: isReadonly
                        ? null
                        : {
                            callback: (cellData, rowData) => this._projectStateService.switchMap(project =>
                                cellData
                                    ? this._papersClient.reject(project.id, rowData.id)
                                    : this._papersClient.accept(project.id, rowData.id)
                            ),
                            reloadRequired: true
                        },
                    width: '100px',
                    filter: Filters.dropdown(yesNoList$),
                    sortable: false,
                    centered: true
                },
                authorCounts: {
                    header: $localize`Authors`,
                    renderer: ProjectPapersRenderers.authors(memberId => this._memberId$.next(memberId)),
                    filterExpr: authorCount => authorCount.fullName,
                    width: '100px',
                    sortable: false,
                    doNotRenderAsArray: true
                },
                comments: {
                    renderer: ProjectPapersRenderers.comments(rowData => this._getCommentsDialogData(rowData), isReadonly),
                    filterExpr: paperNote => paperNote.description,
                    sortable: false,
                    doNotRenderAsArray: true
                },
                color: {
                    header: $localize`Color`,
                    width: '120px',
                    renderer: isReadonly
                        ? null
                        : ExternalRenderers.dropdown(colorList$, (x: ApiColor) => x.name, 'value', 'name', null, true, $localize`No color`),
                    editable: isReadonly
                        ? null
                        : {
                            callback: (_, rowData) => this._projectStateService.switchMap(project =>
                                rowData.color
                                    ? this._papersClient.assignColor(project.id, rowData.id, rowData.color.name)
                                    : this._papersClient.unassignColor(project.id, rowData.id)),
                            reloadRequired: true
                        },
                    sortable: false,
                    useBlankFilterItem: true,
                    filter: Filters.multiselect(colorList$.pipe(map(x => x.map(k => ({ id: k.name, name: k.name }))))),
                    filterExpr: x => x.name,
                    displayExpr: x => x.name
                }
            },
            defaultSort: [
                { field: x => x.isRejected, order: 'desc' },
                { field: x => x.chapter.pos, order: 'asc' },
                { field: x => x.color.name, order: 'asc' }
            ]
        });
    }

    private _getDataSource(oDataQuery: string): Observable<PageResult<ApiPaperListItem>> {
        return combineLatest([this._projectStateService.model$, this._memberId$])
            .pipe(
                switchMap(([project, memberId]) => this._papersClient.getList(project.id, memberId, oDataQuery)),
                map(pageResult => {
                    let rowNumber = 1;
                    pageResult.items = pageResult.items.map(item => {
                        item.rowNumber = rowNumber++;
                        return item;
                    });
                    return pageResult;
                }));
    }

    private _getCommentsDialogData(rowData: ApiPaperListItem): ICommentsData {

        const paperId = rowData.id;

        return {
            noteType: NoteTypeEnum.Comment,
            notesCountChanged$: this._refreshTable$,
            addCommentAction: model => this._projectStateService.firstSwitchMap(project => this._paperNotesClient.create(project.id, paperId, model)),
            comments$: this._projectStateService.switchMap(
                project => this._paperNotesClient.list(project.id, paperId)
                    .pipe(
                        map(comments => comments.map(comment =>
                        ({
                            comment: comment,
                            updateAction: (commentId: number, model: ApiNote) => this._paperNotesClient.update(project.id, paperId, commentId, model),
                            deleteAction: (commentId: number) => this._paperNotesClient.delete(project.id, paperId, commentId)
                        })))
                    ))
        };
    }
}
