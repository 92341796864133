import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanMatch, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { IdentityService } from 'sc-common/core/services/identity/identity.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanMatch {

    constructor(
        private readonly _router: Router,
        private readonly _identityService: IdentityService) {
    }

    public canMatch(): Observable<boolean> {

        const currentRoute = this._router.getCurrentNavigation()?.extractedUrl?.toString().substring(1) ?? '';

        return this._identityService.routeAuthCheck(currentRoute);
    }

    public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this._identityService.routeAuthCheck(state.url);
    }

    public canActivateChild(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this._identityService.routeAuthCheck(state.url);
    }
}
