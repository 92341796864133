import { Component } from '@angular/core';

import { ITableFilterPassedParams, ILinkRendererFilterData } from 'sc-common';
import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';

@Component({
    templateUrl: 'link-renderer.component.html'
})
export class LinkRendererComponent extends RendererBaseComponent {

    public linkExpr: (rowModel: any, cellModel?: any) => string | any[];

    public isClickableExpr?: (rowModel: any) => boolean;

    public filterData?: ILinkRendererFilterData;

    public external?: boolean;

    public hasIcon?: boolean;

    constructor() {
        super();
    }

    public isClickable(rowModel: any): boolean {

        if (this.isClickableExpr) {
            return this.isClickableExpr(rowModel);
        }

        return true;
    }

    public getFilterParams(rowModel: any): ITableFilterPassedParams {

        if (this.filterData) {

            const filterValue = this.filterData.filterValueColumns.length > 1
                ? this.filterData.filterValueColumns.reduce((previous, current) => rowModel[previous] + ' ' + rowModel[current])
                : rowModel[this.filterData.filterValueColumns[0]];

            return {
                filterColumn: this.filterData.filterColumnName,
                filterValue: filterValue
            };
        }

        return null;
    }
}
