import { Component, Inject, Input, OnDestroy } from '@angular/core';

import { intersection } from 'lodash-es';

import { MenuItem, MessageService } from 'primeng/api';

import { map, Observable, Subject, takeUntil } from 'rxjs';

import {
    ApiBackgroundProcessMessage,
    BackgroundProcessEnum,
    BackgroundProcessService,
    BackgroundProcessStatusEnum,
    ENV_TOKEN,
    IdentityService,
    IEnvironment,
    Permission,
    PermissionService,
    WindowRefService
} from 'sc-common';
import { AuthorsClient } from 'sc-common/core/services/open-api/main-api-client';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'header[scHeader]',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [MessageService]
})
export class ExternalHeaderComponent implements OnDestroy {

    @Input()
    public helpfulMaterialsMenu?: MenuItem[];

    @Input()
    public mainMenu: MenuItem;

    public readonly profileMenu$: Observable<MenuItem[]>;

    public readonly publicBaseUrl: string;

    public processStatusEnumType = BackgroundProcessStatusEnum;

    public processEnumType = BackgroundProcessEnum;

    public readonly progressDigitsFormat = '1.0-1';

    public backroundProcessMessages: ApiBackgroundProcessMessage[] = [];

    private readonly _destroy$ = new Subject<void>();

    constructor(
        private readonly _identityService: IdentityService,
        private readonly _authorsController: AuthorsClient,
        private readonly _permissionService: PermissionService,
        @Inject(ENV_TOKEN) private readonly _env: IEnvironment,
        private readonly _window: WindowRefService,
        bgProcessService: BackgroundProcessService,
        messageService: MessageService) {

        this.publicBaseUrl = this._env.publicBaseUrl;

        bgProcessService.progress$
            .pipe(takeUntil(this._destroy$))
            .subscribe(message => {

                const existingMessage = this.backroundProcessMessages.find(m => m.jobId === message.jobId);

                if (!existingMessage) {
                    this.backroundProcessMessages.push(message);

                    messageService.add({
                        key: 'bgProcessToast',
                        sticky: true,
                        data: this.backroundProcessMessages[this.backroundProcessMessages.length - 1]
                    });
                } else {
                    existingMessage.progressValue = message.progressValue;
                    existingMessage.status = message.status;

                    if (message.completed) {
                        setTimeout(() => messageService.clear('bgProcessToast'), 3000);
                    }
                }
            });

        this.profileMenu$ = this._buildMenu();
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private _navigateToEditor(): void {
        this._window.nativeWindow.location.href = `${ this._env.editingToolBaseUrl }/editor/projects`;
    }

    private _navigateToAuthor(): void {
        this._authorsController.haveLoggedAsAuthor()
            .pipe(takeUntil(this._destroy$))
            .subscribe(haveLoggedAsAuthor => {
                const segment = haveLoggedAsAuthor ? 'papers' : 'welcome';
                this._window.nativeWindow.location.href = `${ this._env.editingToolBaseUrl }/author/${ segment }`;
            });
    }

    private _navigateToReviewer(): void {
        this._window.nativeWindow.location.href = `${ this._env.editingToolBaseUrl }/reviewer/projects`;
    }

    private _navigateToContentManager(): void {
        this._window.nativeWindow.location.href = `${ this._env.contentManagerBaseUrl }/projects`;
    }

    private _navigateToInternal(): void {
        this._window.nativeWindow.location.href = `${ this._env.internalBaseUrl }`;
    }

    private _buildMenu(): Observable<MenuItem[]> {

        return this._permissionService.memberPermissions$
            .pipe(
                map(permissions => {

                    const menuItems: MenuItem[] = [];

                    if (permissions.includes(Permission.EditingToolEditorView)) {
                        menuItems.push({
                            label: $localize`Editor`,
                            command: () => this._navigateToEditor()
                        });
                    }

                    if (permissions.includes(Permission.EditingToolAuthorView)) {
                        menuItems.push({
                            label: $localize`Author`,
                            command: () => this._navigateToAuthor()
                        });
                    }

                    if (permissions.includes(Permission.EditingToolReviewerView)) {
                        menuItems.push({
                            label: $localize`Reviewer`,
                            command: () => this._navigateToReviewer()
                        });
                    }

                    if (permissions.includes(Permission.ContentManagerView)) {
                        menuItems.push({
                            label: $localize`Content Manager`,
                            command: () => this._navigateToContentManager()
                        });
                    }

                    if (permissions.includes(Permission.ContentEditorView)) {
                        menuItems.push({
                            label: $localize`Content Editor`,
                            command: () => this._navigateToContentManager()
                        });
                    }

                    if (intersection(permissions, PermissionService.InternalViewPermissions).length > 0) {
                        menuItems.push({
                            label: $localize`Admin`,
                            command: () => this._navigateToInternal()
                        });
                    }

                    menuItems.push(
                        {
                            label: $localize`Logout`,
                            command: () => this._identityService.logout()
                        });

                    return menuItems;
                }));
    }
}
