import { Directive } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';

import { MenuItem } from 'primeng/api';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Directive()
export abstract class DetailsLayoutBaseComponent {

    public readonly activeItem$: Observable<MenuItem>;

    // For future purpose in order to highlight selected menu items
    private readonly _routeActiveItems$: Observable<MenuItem[]>;

    constructor(
        protected readonly _route: ActivatedRoute,
        protected readonly _router: Router,
        public navigationTabs: MenuItem[],
        private readonly _exactRouteCheck: boolean = false) {

        const childRouteItem$ = new BehaviorSubject<ActivatedRouteSnapshot>(null);

        // As long as router 'events' are hot observables and provide non multicasted value,
        // it is vital to re-trigger source through single subject with preserving last value.
        this._router.events
            .pipe(
                filter(x => x instanceof ActivationEnd),
                map((x: ActivationEnd) => this._getMostNestedRoute(x.snapshot)),
                distinctUntilChanged())
            .subscribe(x => childRouteItem$.next(x));

        this._routeActiveItems$ = childRouteItem$.pipe(filter(x => !!x), map(x => this._getActiveMenuItems(x)));

        this.activeItem$ = this._routeActiveItems$.pipe(map(x => x?.length ? x[x.length - 1] : null));
    }

    private _getActiveMenuItems(routeSnapshot: ActivatedRouteSnapshot): MenuItem[] {

        const indexOfCurrentRoute = routeSnapshot.pathFromRoot.findIndex(x => x.routeConfig === this._route.snapshot.routeConfig);

        // Get path segments list starting from current component route.
        // Ignore empty paths.
        const pathList = routeSnapshot.pathFromRoot
            .filter((x, i) => i > indexOfCurrentRoute && x.routeConfig.path)
            .reduce<string[]>((p, n) => [...p, ...n.url.map(u => u.path)], []);

        // Calculate the active item according to path which starts from current route and ends with most nested route.
        return this._findActiveMenuItems(this.navigationTabs, pathList);
    }

    private _findActiveMenuItems(menuItems: MenuItem[], pathList: string[]): MenuItem[] {

        for (const menuItem of menuItems) {

            const found = Array.isArray(menuItem.routerLink)
                ? (!this._exactRouteCheck || menuItem.routerLink.length === pathList.length)
                && menuItem.routerLink.every((r: any, rIndex: number) => pathList.some((p, pIndex) => r === p && rIndex === pIndex))
                : menuItem.routerLink === pathList[0];

            if (found) {

                return [menuItem];

            } else if (menuItem.items?.length) {

                const foundItem = this._findActiveMenuItems(menuItem.items, pathList);

                if (foundItem?.length) {

                    return [menuItem, ...foundItem];
                }
            }
        }

        return [];
    }

    private _getMostNestedRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {

        let result = route;

        while (result.firstChild) {

            result = result.firstChild;
        }

        return result;
    }
}
