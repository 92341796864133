import { Inject, Injectable } from '@angular/core';

import { Observable, filter } from 'rxjs';

import { IdentityService } from 'sc-common';
import { ENV_TOKEN, IEnvironment } from 'sc-common/core/models/environment';
import { GlobalErrorHandler } from 'sc-common/core/services/error-handler/error-handler.service';
import { ApiBackgroundProcessMessage, BackgroundProcessEnum } from 'sc-common/core/services/open-api/open-api-clients';
import { SignalRBaseClientService } from 'sc-common/core/services/signalr/signalr-base-client.service';

@Injectable({ providedIn: 'root' })
export class BackgroundProcessService extends SignalRBaseClientService {

    public progress$: Observable<ApiBackgroundProcessMessage>;

    constructor(
        identityService: IdentityService,
        protected readonly _errorHandler: GlobalErrorHandler,
        @Inject(ENV_TOKEN) env: IEnvironment) {

        super(identityService, _errorHandler, `${ env.apiUrl }/api/signalrhub/backgroundprocesses`);

        this.progress$ = this.getCallbackSource$('backgroundProcessProgress');
    }

    public onComplete(...processes: BackgroundProcessEnum[]): Observable<ApiBackgroundProcessMessage> {
        return this.progress$.pipe(filter(m => processes.includes(m.process) && m.completed));
    }
}
