import { Pipe, PipeTransform } from '@angular/core';

import { EnumLocalizingService } from 'sc-common/core/services/enum-localizing.service';

@Pipe({
    standalone: true,
    name: 'enumLocalize'
})
export class EnumLocalizePipe implements PipeTransform {
    constructor(private readonly _localizeService: EnumLocalizingService) { }

    public transform(value: number, enumType: any): any {

        if (value === null || value === undefined) {
            return value;
        }

        if (!enumType) {
            throw new Error('Please provide enum type.');
        }

        return this._localizeService.getName(enumType, value);
    }
}
