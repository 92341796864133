<ng-template #displayTemplateRef
             let-cellData>

    <p-tag *ngIf="!renderAsText; else booleanAsText"
           [value]="cellData ? 'Yes' : 'No'"
           [severity]="cellData ? 'success' : 'warning'"
           [rounded]="true"
           i18n-value>
    </p-tag>

    <ng-template #booleanAsText>
        {{ cellData ? 'Yes' : 'No' }}
    </ng-template>

</ng-template>