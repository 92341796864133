import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutoCompleteModule } from 'primeng/autocomplete';

import { GridAutocompleteComponent } from 'sc-common/shared/components/grid-autocomplete/grid-autocomplete.component';

@NgModule({
    imports: [AutoCompleteModule, CommonModule],
    declarations: [GridAutocompleteComponent],
    exports: [GridAutocompleteComponent]
})
export class GridAutocompleteModule { }
