<ng-template #displayTemplateRef
             let-cellData
             let-rowData="rowData">
    <div class="white-space-normal flex flex-wrap gap-1">
        <ng-container *ngFor="let author of cellData">
            <span *ngIf="author.count === 1">{{ author.count }}</span>
            <a *ngIf="author.count > 1"
               href="#"
               (click)="filterByAuthor($event, author.memberId)">{{ author.count }}</a>
        </ng-container>
    </div>
</ng-template>