import { Component, OnDestroy } from '@angular/core';

import { intersection } from 'lodash-es';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { Subject, takeUntil } from 'rxjs';

import { ApiTopicRoot } from 'content-manager/src/app/core/open-api/content-manager-api-client';
import { IProjectUpdateTopicsDialogData } from 'content-manager/src/app/projects/project/topics/update-topics-dialog/project-update-topics-dialog-data';

@Component({
    templateUrl: './project-update-topics-dialog.component.html'
})
export class ProjectUpdateTopicsDialogComponent implements OnDestroy {

    public readonly dialogData: IProjectUpdateTopicsDialogData;

    public selectedTopicIds: number[] = [];

    public requestInProcess: boolean;

    private readonly _destroy$ = new Subject<void>();

    constructor(
        dialogConfig: DynamicDialogConfig,
        private readonly _dialogRef: DynamicDialogRef) {

        this.dialogData = dialogConfig.data;

        this.dialogData.existingTopicsIds$
            .pipe(takeUntil(this._destroy$))
            .subscribe(topicIds => this.selectedTopicIds = topicIds);
    }

    public isRootSelected(root: ApiTopicRoot): boolean {
        return intersection(root.topics.map(t => t.id), this.selectedTopicIds).length > 0;
    }

    public save(): void {
        this.requestInProcess = true;

        this.dialogData.updateAction(this.selectedTopicIds)
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this.requestInProcess = true;
                this._dialogRef.close(true);
            });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
