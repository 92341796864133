export class MemoryStorage implements Storage {

    private _data: { [key: string]: string; } = {};

    public readonly length: number;

    public clear(): void {
        this._data = {};
    }

    public key(index: number): string {
        throw new Error('Method not implemented.');
    }

    public getItem(key: string): string {
        return this._data[key];
    }

    public setItem(key: string, value: string): void {
        this._data[key] = value;
    }

    public removeItem(key: string): void {
        this._data[key] = undefined;
    }
}
