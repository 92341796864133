<ng-template #templateRef>
    <div class="p-inputgroup">
        <p-inputNumber [ngModel]="filterValue"
                       [placeholder]="placeholder"
                       (keyup.enter)="filter()"
                       (onInput)="onNumberInput($event)"></p-inputNumber>

        <button *ngIf="isFilterApplied"
                pButton
                type="button"
                icon="pi pi-times"
                (click)="cleanFilter()"></button>
    </div>
</ng-template>
