import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'join',
    standalone: true
})
export class JoinPipe implements PipeTransform {

    public transform(input: any, sep = ', '): string {

        return Array.isArray(input) ? input.join(sep) : Object.values(input).filter(x => x !== null && x !== undefined).join(sep);
    }
}
