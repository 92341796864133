import { Inject, Injectable, InjectionToken, Type } from '@angular/core';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ScConfirmationData } from 'sc-common/shared/confirmation/confirmation-data';

export const CONFIRMATION_DIALOG_TYPE = new InjectionToken('ConfirmationDialogType');

@Injectable({
    providedIn: 'root'
})
export class ScConfirmationService {

    constructor(
        @Inject(CONFIRMATION_DIALOG_TYPE) private readonly _dialogType: Type<any>,
        private readonly _dialogService: DialogService) {
    }

    public confirm(confirmation: ScConfirmationData): DynamicDialogRef {

        const conf = new ScConfirmationData();

        Object.assign(conf, confirmation);

        return this._dialogService.open(this._dialogType, {
            header: conf.header,
            width: conf.width,
            data: conf,
            styleClass: 'sc-confirm-dialog'
        });
    }
}
