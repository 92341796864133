import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { Subject, takeUntil } from 'rxjs';

import { ApiNote, ValidationService } from 'sc-common';
import { ICommentData } from 'sc-common/shared/components/comments-dialog/comment/comment-data';
import { ICommentsData } from 'sc-common/shared/components/comments-dialog/comments-dialog-data';

@Component({
    templateUrl: 'comments-dialog.component.html'
})
export class CommentsDialogComponent implements OnInit, OnDestroy {

    public readonly commentDialogModel: ICommentsData;

    public readonly form: UntypedFormGroup;

    public commentsData: ICommentData[];

    public requestInProcess = false;

    private readonly _destroy$ = new Subject<void>();

    constructor(config: DynamicDialogConfig, formBuilder: UntypedFormBuilder) {

        this.form = this._buildForm(formBuilder);
        this.commentDialogModel = config.data as ICommentsData;
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public ngOnInit(): void {
        this._getComments(true);
    }

    public addComment(): void {

        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.requestInProcess = true;

        const model: ApiNote = this.form.value;
        model.type = this.commentDialogModel.noteType;

        this.commentDialogModel.addCommentAction(model)
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this._getComments();
                this.form.reset();
                this.requestInProcess = false;
            });
    }

    public onCommentUpdate(): void {
        this._getComments();
    }

    public onCommentDelete(): void {
        this._getComments();
    }

    private _buildForm(formBuilder: UntypedFormBuilder): UntypedFormGroup {
        return formBuilder.group(
            { description: ['', [Validators.required, ValidationService.alphaNumSymbolValidator]] });
    }

    private _getComments(initial = false): void {
        this.commentDialogModel.comments$
            .pipe(takeUntil(this._destroy$))
            .subscribe(result => {
                this.commentsData = result;

                if (!initial) {
                    this.commentDialogModel.notesCountChanged$?.next(this.commentsData.length);
                }
            });
    }
}
