import { Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, NG_ASYNC_VALIDATORS, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

import { from, isObservable, merge, Observable, of } from 'rxjs';

import { TableColumn } from 'sc-common/shared/table/models/table-column';

export const validatorProviderFunc: (v: CellValidatorDirective) => ValidatorFn = v => v.validate.bind(v);

export const asyncValidatorProviderFunc: (v: CellValidatorDirective) => AsyncValidatorFn = v => v.validateAsync.bind(v);

@Directive({
    selector: '[scEditableCellValidation]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useFactory: validatorProviderFunc,
            deps: [CellValidatorDirective],
            multi: true
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useFactory: asyncValidatorProviderFunc,
            deps: [CellValidatorDirective],
            multi: true
        }
    ]
})
export class CellValidatorDirective {

    @Input('scEditableCellValidation')
    public column: TableColumn;

    public validate(control: AbstractControl): ValidationErrors | null {

        return this.column.settings.editable?.validators?.map(v => v(control))
            .filter(e => !!e)
            .reduce((p, n) => ({ ...p, ...n }), {})
            ?? null;
    }

    public validateAsync(control: AbstractControl): Observable<ValidationErrors | null> {

        const validationSourceList = this.column.settings.editable?.asyncValidators
            ?.map(v => {

                const result = v(control);

                return isObservable(result) ? result : from(result);
            });

        return validationSourceList?.length ? merge(validationSourceList) : of(null);
    }
}
