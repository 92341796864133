import { Component, TemplateRef, ViewChild } from '@angular/core';

import { TableSettings } from 'sc-common/shared/table/models/table-settings';

@Component({
    templateUrl: 'table-empty.component.html'
})
export class TableEmptyComponent {

    @ViewChild('templateRef')
    public readonly templateRef: TemplateRef<any>;

    public hasActionsColumn = (): boolean => false;

    public get staticColumnsLength(): number {

        return (this.hasActionsColumn() ? 1 : 0)
            + (this.tableSettings.selectionMode ? 1 : 0)
            + (this.tableSettings.reordering?.separateColumn ? 1 : 0);
    }

    constructor(public readonly tableSettings: TableSettings<any>) {
    }
}
