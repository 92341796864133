import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import * as JSZip from 'jszip';

import { Message } from 'primeng/api';

import { Subject, takeUntil } from 'rxjs';

import { FileUtils, FileValidationModel } from 'sc-common';
import { FileUploaderService } from 'sc-external/services/file-uploader.service';

@Component({
    selector: 'sc-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent implements OnInit, OnDestroy {

    @Input()
    public name = 'file';

    @Input()
    public uploadUrl: string;

    @Input()
    public disabled: boolean;

    @Input()
    public validationRules: FileValidationModel;

    @Input()
    public requestInProcess$ = new Subject<boolean>();

    @Input()
    public progress$ = new Subject<number>();

    @Input()
    public uploadErrors$ = new Subject<Message[]>();

    @Input()
    public uploadResponse$ = new Subject<any>();

    @Output()
    public readonly uploadStarted = new EventEmitter();

    @Output()
    public readonly uploadCompleted = new EventEmitter();

    public uploading: boolean;

    public file: File;

    public errors: Message[];

    @ViewChild('fileInput')
    protected _fileInput: ElementRef;

    private readonly _destroy$ = new Subject<void>();

    public get accept(): string { return this.validationRules?.extensions.join(', '); }

    public get fileIconClass(): string { return FileUtils.getFileIconClass(this.file.name); }

    constructor(private readonly _fileUploaderService: FileUploaderService) {
    }

    public ngOnInit(): void {

        this.requestInProcess$.pipe(takeUntil(this._destroy$)).subscribe(value => {
            this.uploading = value;
            if (value) {
                this.uploadStarted.emit({ file: this.file });
            }
            else if (!value && this.errors.length === 0) {
                this.uploadCompleted.emit({ file: this.file });
            }
        });

        this.uploadErrors$.pipe(takeUntil(this._destroy$)).subscribe(value => this.errors = value);
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public startUpload(uploadUrl?: string): void {
        const formData = new FormData();
        formData.append(this.name, this.file, this.file.name);

        this._fileUploaderService.startUpload(
            uploadUrl ?? this.uploadUrl,
            formData,
            this.requestInProcess$,
            this.progress$,
            this.uploadErrors$,
            this.uploadResponse$);
    }

    public selectFile(): void {

        if (this.disabled || this.file) {
            return;
        }

        this._fileInput.nativeElement.click();
    }

    public onFileSelect(event: any): void {

        if (this.disabled) {
            return;
        }

        const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        this.file = files[0];
        this.validate();
    }

    public onDragOver(event: any): void {

        if (this.disabled) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();
    }

    public onDragDrop(event: any): void {

        if (this.disabled || this.file) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        this.onFileSelect(event);
    }

    public clearFile(event?: any): void {

        if (this.disabled) {
            return;
        }

        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        this.file = null;
        this.errors = [];

        if (this._fileInput) {
            this._fileInput.nativeElement.value = '';
        }
    }

    public validate(): boolean {

        this.errors = [];
        const zipExtension = 'zip';
        const texExtension = 'tex';

        if (!this.file) {
            this.errors.push({
                severity: 'error',
                summary: $localize`File is required`
            });

            return false;
        }

        if (!this.accept.includes(FileUtils.getFileExtension(this.file.name))) {
            this.errors.push({
                severity: 'error',
                summary: $localize`${ this.file.name }\: Invalid file type, allowed file types: ${ this.accept }.`
            });
        }

        if (this.accept.includes(zipExtension) && this.validationRules.latexInZipIsRequired && FileUtils.getFileExtension(this.file.name) === zipExtension) {
            import('jszip').then((module: any) => {
                const zip = module.default() as JSZip;
                zip.loadAsync(this.file).then((z: any) => {

                    const containsTexFile = Object.keys(z.files)
                        .map(fileName => FileUtils.getFileExtension(fileName))
                        .includes(texExtension);

                    if (!containsTexFile) {
                        this.errors.push({
                            severity: 'error',
                            summary: $localize`Zip file does not contain any .tex file`
                        });
                    }
                });
            });
        }

        const maxSizeInBytes = this.validationRules.maxSizeInMb * 1024 * 1024;

        if (this.file.size > maxSizeInBytes) {
            this.errors.push({
                severity: 'error',
                summary: $localize`${ this.file.name }\: Invalid file size, maximum upload size is ${ this.validationRules.maxSizeInMb } MB.`
            });
        }

        return this.errors.length === 0;
    }

    public isValid(): boolean {
        return this.errors && this.errors.length === 0 && !!this.file;
    }
}
