import { Directive, OnDestroy, TemplateRef, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ScFilter } from 'sc-common/core/models/filter';
import { MatchMode } from 'sc-common/core/models/match-mode-enum';
import { checkFilterBlankValue, createBlankFilterItem, SelectFilterItem } from 'sc-common/core/models/select-filter-item';
import { TableColumn } from 'sc-common/shared/table/models/table-column';
import { TableStateService } from 'sc-common/shared/table/table-state.service';

@Directive()
export abstract class FilterBaseComponent implements OnDestroy {

    private readonly _destroyBase$ = new Subject<void>();

    private _filterBlankItem: SelectFilterItem;

    private _filterValue: any = null;

    protected _filters: ScFilter[];

    protected get filterBlankItem(): SelectFilterItem {

        return this._filterBlankItem ??= createBlankFilterItem(this._tableColumn.dataType);
    }

    @ViewChild('templateRef')
    public readonly templateRef: TemplateRef<{ $implicit: TableColumn; }>;

    public readonly placeholder = $localize`Search`;

    public matchMode = MatchMode.equals;

    public appliedFilterValue: any;

    public get filterValue(): any {

        return this._filterValue;
    }

    public set filterValue(v: any) {

        if (checkFilterBlankValue(v)) {

            this._filterValue = this.filterBlankItem.id;

        } else if (Array.isArray(v) && v.some(checkFilterBlankValue)) {

            this._filterValue = v.map(x => checkFilterBlankValue(x) ? this.filterBlankItem.id : x);

        } else {

            this._filterValue = v;
        }
    }

    public get hasFilterValue(): boolean {
        return this._filterValue !== null && this._filterValue !== undefined;
    }

    public columnName: string;

    public filterKeyField: string;

    public get isFilterApplied(): boolean {

        const type = typeof this.appliedFilterValue;

        return type === 'number'
            || type === 'boolean'
            || (this.appliedFilterValue !== null && this.appliedFilterValue !== undefined
                && this.appliedFilterValue !== ''
                && (!Array.isArray(this.appliedFilterValue) || !!this.appliedFilterValue.length));
    }

    protected constructor(
        protected tableStateService: TableStateService,
        protected readonly _tableColumn: TableColumn) {

        if (!tableStateService) {
            throw new Error('Provide table state instance');
        }

        tableStateService.reset$
            .pipe(takeUntil(this._destroyBase$))
            .subscribe(() => this.cleanFilter());
    }

    public ngOnDestroy(): void {

        this._destroyBase$.next();
        this._destroyBase$.complete();
    }

    public filter(): void {

        this.appliedFilterValue = this.filterValue;

        this.tableStateService.filter(this.filterValue, this.filterKeyField, this.matchMode);
    }

    public cleanFilter(): void {

        this.filterValue = null;

        if (this.isFilterApplied) {

            this.appliedFilterValue = null;

            this.tableStateService.filter(null, this.filterKeyField, null);
        }
    }
}
