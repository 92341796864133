import { Component } from '@angular/core';

import { RendererBaseComponent } from 'sc-common/shared/table/body/renderers/renderer-base.component';

@Component({
    templateUrl: 'icon-action-renderer.component.html'
})
export class IconActionRendererComponent extends RendererBaseComponent {

    public action: (rowModel: any, cellModel?: any) => void;

    public iconClass: string;

    constructor() {
        super();
    }

    public invokeAction(rowModel: any, cellModel?: any): void {
        this.action(rowModel, cellModel);
    }
}
